import React from 'react';
import AttributesBox from './components/AttributesBox';

const StockDisplayAttributes = ({val, name, stateAdv, setStateAdv, allSearchTags, exp}) => {
    return (
        <div>
            {exp[val] === false ?
                <a href="#" onClick={()=>{exp[val]=true}} style={allSearchTags[name] ? { display:'initial' } : { display:'none' }}>{name}</a>
                :
                allSearchTags[name]?
                <div>
                    <AttributesBox state={stateAdv} key={name} name={name} list={allSearchTags[name]} onChange={setStateAdv}></AttributesBox>
                </div>
                :
                <sapn style={{display:'none'}}></sapn>
            }

            <br style={allSearchTags[name] ? { display:'initial' } : { display: 'none' }} />
        </div>
    );
};

export default StockDisplayAttributes;