import React from 'react';
import StockDisplayAttributes from './StockDisplayAttributes';

const StockCatalog = ({val, seccion, exp = [], allSearchTags, stateAdv, setStateAdv}) => {
    return (
        <div>
            {seccion.value === val ? 
                <React.Fragment>
                    <div style={{marginLeft:'30px'}}>
                        <StockDisplayAttributes val={0} name='Talla' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={1} name='Cuello' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={2} name='Manga' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={3} name='Variante' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={4} name='Piezas' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={5} name='Piedra' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={6} name='Material' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                        <StockDisplayAttributes val={7} name='Cierre' stateAdv={stateAdv} setStateAdv={setStateAdv} allSearchTags={allSearchTags} exp={exp} />
                    </div>
                </React.Fragment> : ''
            }
        </div>
    );
};

export default StockCatalog;