import React from 'react';

export default function ReporteTarjeta({ report, setReport, title, ...props }) {

    return (
        <React.Fragment>
            <div className='containerx'>
                <br></br>
                <h4>{title}</h4>
                <div className='row'>
                    {
                        Object.entries(report).length === 0 ? 'No se han registrado pagos'
                            :
                            Object.entries(report).map((value, i) => {
                                return (
                                    <div key={i} className="col s12 m4" style={{ marginTop: '3vh', marginBottom: '3vh' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h6 style={{ textTransform: 'capitalize', backgroundColor: '#ececec' }}>
                                                {value[0].includes('@') ?
                                                    JSON.stringify(value[0].split('@')[0]) :
                                                    JSON.stringify(value[0]).substr(1, JSON.stringify(value[0]).length - 2)
                                                }
                                            </h6>
                                        </div>
                                        <div>{Object.entries(value[1]).map((v, ind) => {
                                            return (
                                                <div key={ind}>
                                                    <div className='row'>
                                                        <div className='col s6'>{JSON.stringify(v[0]).substr(1, JSON.stringify(v[0]).length - 2)}: </div>
                                                        <div className='col s6' style={{ textAlign: 'right' }}>${JSON.stringify(v[1])}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}</div>
                                    </div>
                                )
                            })}
                </div>
            </div>
        </React.Fragment>
    )
}