import axios from 'axios';

const backend = 'https://api.grcollection.com.mx';
// const backend = 'http://localhost:2999';


export async function httpGet(path) {
    const response = await axios.get(backend + path);
    return response;
}

export function httpGetOne(path, id) {
    return axios.get(backend + path+'/'+id);
}

export function httpPost(path, item, user) {
    item = {...item, user: user};
    return axios.post(backend + path, item);
}

export function httpPostw(path, item, config) {
    const r = axios.post(backend + path, item, config );
    return r;
}

export function httpPut(path, item, user) {
    item = {...item, user: user};
    return axios.put(backend + path, item);
}

export function httpDelete(path, id, user){
    return axios.post(backend + path, {id: id, user:user});
}

export function httpGetImg(filename){
    return backend+'/storage/'+filename;
}
