import moment from 'moment';
import xlsx from 'node-xlsx';
import React, { useState } from 'react';
import { httpGet } from '../../fetch';
import './ReporteInventario.css';

const ReporteClientes = () => {
    const [clientes, setClientes] = useState([]);

    const fetchData = async () => {
        const result = await httpGet('/api/reports/clients');
        setClientes(result.data);
    }

    useState(() => {
        fetchData();
    }, [])

    const saveReportFile = (reportName, byte) => {

        let blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;

        link.click();
    };

    const getReport = () => {
        let sheetData = {};
        const colsSheetData = ['Calificación', 'Nombre', 'Dirección', 'Teléfono', 'Celular', 'Email', 'Fecha Nacimiento', 'Sexo', 'Facebook', 
        'Instagram', 'Límite-Crédito', 'Adeudo', 'Monto-Pago', 'Periodo-Pago', 'Prox-Fecha-Pago', 'Dia-Pago', 'Nota'];
        let maxSignatory = 0;

        clientes.map(c => {
            const sheetname = "clientes"

            if (!(sheetname in sheetData)) {
                sheetData[sheetname] = [];
            }

            sheetData[sheetname].push([
                c.rating,
                c.name,
                c.address,
                c.phone,
                c.cellPhone,
                c.email,
                c.birth ? moment(c.birth).format('DD/MM/YYYY') : "-",
                c.sex,
                c.facebook,
                c.instagram,
                c.creditLimit,
                c.totalAmount,
                c.payMount,
                c.period,
                c.nextPayDate ? moment(c.nextPayDate).format('DD/MM/YYYY') : "-",
                c.payDay,
                c.nota
            ])
        });

        const options = { '!cols': [{ wch: 13 }, { wch: 15 }, { wch: 14 }, { wch: 15 }, { wch: 6 }, { wch: 6 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }] };

        for (let i = 1; i <= maxSignatory; i++) {
            colsSheetData.push('Variante ' + i);
        }

        const dataset = {}
        Object.keys(sheetData).map(st => {
            dataset[st] = [];
            dataset[st].push(colsSheetData)
            sheetData[st].map(m => {
                dataset[st].push(m)
            })
        })

        const xlsSheets = [];
        Object.keys(sheetData).map(st => {
            xlsSheets.push({ name: st, data: dataset[st] })
        })

        const buildData = xlsx.build(xlsSheets);
        saveReportFile(`Reporte - ${moment().utc().format('YYYYMMDD')}`, buildData);
    }

    return (
        <div className="ribbon">
            <h4>Generar reporte de clientes</h4>
            <div className="left">
                <button className="btn light-goldeneye" onClick={() => getReport()}>Descargar</button>
            </div>
        </div>
    );
};

export default ReporteClientes;