import React, { useState, useEffect } from 'react';
import { httpGet, httpPost, httpDelete, httpPut, httpGetOne } from '../fetch';
import moment from 'moment';
import Select from 'react-select';

export default function Cards({ }) {
    let M;
    const [isEdit, setIsEdit] = useState(false);
    const [cards, setCards] = useState([]);
    const [card, setCard] = useState({type:'', number:'', score: '', team: ''});

    useEffect(() => {
        const userType = localStorage.getItem('user');
        if (userType !== 'Developer') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }

        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchCards();
    }, []);

    const fetchCards = async () => {
        const result = await httpGet('/api/cards');
        window.M.updateTextFields();
        console.log(result.data);
        setCards(result.data);
    }

    const deleteCard = async (id) =>{
        const result = await httpPost(`/api/cards/delete`,{id});
        fetchCards();
    }

    const editCard = (card) => {
        window.M.updateTextFields();
        setIsEdit(true)
        setCard({
            id: card._id,
            display: card.display,
            number: card.number,
            score: card.score,
            team: card.team
        });
        fetchCards();
    }

    const saveCard = async (e) => {

        if(isEdit){
            const result = await httpPut(`/api/cards/${card.id}`, card);
            console.log(result)
        } else {
            const result = await httpPost('/api/cards', card);
            console.log(result)
        }
        fetchCards();
        setIsEdit(false);
    }

    return (
        <div>
            <div className="container">
                <h4>Tarjetas para pago</h4>
                <div className="row">
                    <div className="col m6 s11">
                        <button data-target="modal1" className="btn light-goldeneye modal-trigger">Agregar +</button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Display</th>
                            <th>Número</th>
                            <th>Score</th>
                            <th>Equipo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cards.map(i => (
                            <tr className="Table" key={i._id}>
                                <td>{moment(moment(i.createdAt).utcOffset(+7)).format('DD/MM/YYYY')}</td>
                                <td>{i.display}</td>
                                <td>{i.number}</td>
                                <td>{i.score}</td>
                                <td>{i.team}</td>
                                <td>
                                    <button className="btn light-goldeneye modal-trigger"
                                        value={i._id}
                                        href="#modal1"
                                        onClick={e =>{editCard(i)}}
                                        style={{margin: '4px'}}>
                                        <i className="material-icons">edit</i>
                                    </button>
                                    <button className="btn light-goldeneye"
                                        onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar esta tarjeta?')) deleteCard(i._id) }}
                                        style={{ margin: '4px' }}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div id="modal1" className="modal">
                <div className="modal-content">
                    <h4>Agregar Nueva Tarjeta</h4>
                    <br />
                    <form onSubmit={saveCard}>
                        <div>
                            <div className="input-field col s12">
                                <input id="display" type="text" name="display" value={card.display}
                                    onChange={e => setCard({ ...card, display: e.target.value })}></input>
                                <label htmlFor="display">Display: OXXO 5501</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="number" type="text" name="number" value={card.number}
                                    onChange={e => setCard({ ...card, number: e.target.value })}></input>
                                <label htmlFor="number">Número de tarjeta (4 dígitos)</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="score" type="text" name="score" value={card.score}
                                    onChange={e => setCard({ ...card, score: e.target.value })}></input>
                                <label htmlFor="score">Score (multiplo de 10, de preferencia)</label>
                            </div>
                            <div className="input-field col s12">
                                <Select id="team" options={[{value:'',label:'... Equipo'},{value:'0', label:'Hermosillo'}, {value:'1', label:'Obregón'}]} 
                                    name="team" value={{ value: card.team, label:`${card.team === '0' ? 'Hermosillo': card.team ==='1'? 'Obregón':'...Equipo'}`}} onChange={e => setCard({...card, team: e.value})} className="selecter lvl100 s12" />
                            </div>
                        </div>
                        <br />
                        <div className="" style={{ bottom: '1vh', color: 'white' }}>
                            <input type='submit' className="modal-close waves-effect btn light-goldeneye" 
                                value="Aceptar"></input>
                        </div>
                        <br/>
                    </form>
                </div>
            </div>
        </div>
    )
}