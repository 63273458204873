import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpGetOne, httpDelete, httpPut } from '../fetch';
import moment from 'moment';
import PromocionForm from './promociones_components/PromocionForm';

const PointsBoard = () => {
    let M;

    const initialState = {
        name: null,
        activeItems: 0,
        discountTypeSelect: { label: 'Tipo de descuento', value: null },
        pointsTypeSelect: { label: 'Tipo de puntos', value: null },
        startDate: moment(),
        endDate: moment().add(1, 'month'),
        isGlobal: false,
        points: null
    }

    const [promociones, setCurrentData] = useState([]);

    const [promotion, setPromotion] = useState(initialState);

    const fetchData = async () => {
        const res = await httpGet('/api/promotions');
        setCurrentData(res.data)
    }

    const savePromotion = async () => {
        if (promotion.discountType !== 'percent' && promotion.discountType !== 'amount') {
            window.alert("Verifica el tipo de descuento antes de guardar")
            return
        }

        if (promotion.pointsType !== 'percent' && promotion.pointsType !== 'amount') {
            window.alert("Verifica el tipo de puntos antes de guardar")
            return
        }

        if (promotion._id) {
            await httpPut(`/api/promotions/${promotion._id}`, promotion, localStorage.getItem('user'))
        } else {
            await httpPost('/api/promotions', promotion, localStorage.getItem('user'))
        }
        setPromotion(initialState);

        //Cerrar el Modal Manualmente
        const M = window.M;
        var elem = document.getElementById('modal1');
        var instance = M.Modal.getInstance(elem);
        instance.close();

        await fetchData();
    }

    const fetchPromotion = async id => {
        const res = await httpGetOne('/api/promotions', id);
        setPromotion({...res.data, 
            discountTypeSelect: { 
                label: res.data.discountType === 'amount' ? 'Monto ($)' : 'Porcentaje (%) del valor de la prenda', 
                value: res.data.discountType },
            pointsTypeSelect: { 
                label: res.data.pointsType === 'amount' ? 'Cantidad (Puntos)' : 'Porcentaje (%) del valor de la prenda',
                value: res.data.pointsType }})

        //Ajuste Materialize
        const M = window.M;
        M.updateTextFields();
    }

    const deletePromotion = async id => {
        await httpDelete('/api/promotions/delete', id, localStorage.getItem('user'))
        fetchData();
    }

    useEffect(() => {
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchData();
    }, []);

    return (
        <div>
            <div className='container'>
                <h4>Promociones</h4>
                <a href="#modal1" className="btn light-goldeneye modal-trigger">Agregar +</a>
            </div>
            <div className='container'>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th className="pc-only-table-cell"># Productos</th>
                            <th className="pc-only-table-cell">Descuento</th>
                            <th className="pc-only-table-cell">Puntos</th>
                            <th>Vigencia</th>
                            <th>Es Global</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {promociones && promociones.length > 0 && promociones.map(i => (
                            <tr className="Table"
                                key={i._id}>
                                <td>{i.name}</td>
                                <td className="pc-only-table-cell">{i.activeItems}</td>
                                {i.discountType === 'amount' ?
                                    <td className="pc-only-table-cell">${i.discount ? i.discount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '-'}</td> :
                                    <td className="pc-only-table-cell">{i.discount ? i.discount : '-'} %</td>
                                }
                                {i.pointsType === 'amount' ?
                                    <td className="pc-only-table-cell">{i.points ? i.points : '-'}</td> :
                                    <td className="pc-only-table-cell">{i.points ? i.points : '-'} %</td>
                                }
                                <td>{moment(i.startDate).utc().format("DD/MM/YY")} al {moment(i.endDate).utc().format("DD/MM/YY")}</td>
                                <td>{i.isGlobal ? "Si" : "No"}</td>
                                <td>
                                    <button className="btn light-goldeneye modal-trigger"
                                        value={i._id}
                                        href="#modal1"
                                        onClick={e => fetchPromotion(i._id)}
                                        style={{ margin: '4px', }}>
                                        <i className="material-icons">edit</i>
                                    </button>
                                    <button className="btn light-goldeneye"
                                        onClick={e => { if (window.confirm('¿Estás seguro que deseas eliminar esta promoción?')) deletePromotion(i._id) }}
                                        style={{ margin: '4px' }}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                { promociones && promociones.length === 0 &&
                    <div className='center'>
                        <br />
                        <p>No se encuentran promociones vigentes</p>
                    </div>
                }
            </div>

            <PromocionForm savePromotion={savePromotion} promotion={promotion} setPromotion={setPromotion} />
        </div>
    );
};

export default PointsBoard;