import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { httpPost } from '../../fetch';
import { Line, LineChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const ConsultaAnual = () => {
    moment.locale("es", { months: 'ENE_FEB_MAR_ABR_MAY_JUN_JUL_AGO_SEP_OCT_NOV_DIC'.split('_') });

    let M;
    const [sales, setSales] = useState(
        {
            anteriorinit: moment.utc(moment().subtract(1, 'year').startOf('year')).format('YYYY-MM-DD'),
            yearinit: moment.utc(moment().startOf('year')).format('YYYY-MM-DD'),
            anteriorend: moment(moment().subtract(1, 'year')).endOf('year').format('YYYY-MM-DD'),
            yearend: moment().endOf('year').format('YYYY-MM-DD')
        });
    const [pays, setPays] = useState({});
    const [actualYear, setActualYear] = useState(moment().format('YYYY'));
    const [anteriorYear, setAnteriorYear] = useState(moment(moment().subtract(1, 'year')).format('YYYY'));
    const [working, setWorking] = useState(false);

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        if (userType !== 'Admin') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/'
        }
        M = window.M; //Cargar Materialize desde CDN
        fetchPays();
    }, []);

    const fetchPays = async () => {
        setWorking(true);
        const dayNumbers = 11;

        const newPayData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consultaActual = moment(sales.yearinit).add(i, 'month');
            const consultaAnterior = moment(sales.anteriorinit).add(i, 'month');
            // betweenr: resumido -> solo amount
            const resultActual = await httpPost('/api/pays/betweenr', { fromDate: moment.utc(consultaActual), toDate: moment.utc(consultaActual).endOf('month') });
            const resultAnterior = await httpPost('/api/pays/betweenr', { fromDate: moment.utc(consultaAnterior), toDate: moment.utc(consultaAnterior).endOf('month') });
            let pagosDelMesActual = 0;
            let pagosDelMesAnterior = 0;
            let diferencia = -100

            if (resultActual.data.length > 0) {
                resultActual.data.map(item => {
                    pagosDelMesActual += item.amount;
                });

            }
            if (resultAnterior.data.length > 0) {
                resultAnterior.data.map(item => {
                    pagosDelMesAnterior += item.amount;
                });
                diferencia = (pagosDelMesActual * 100 / pagosDelMesAnterior) - 100
            }

            newPayData.push({ name: consultaActual.format('MMMM'), [actualYear]: pagosDelMesActual, [anteriorYear]: pagosDelMesAnterior, diff: Math.floor(diferencia) });
        }
        setPays({ ...pays, data: newPayData });
        if (newPayData.length > 0)
            setWorking(false);
    }


    return (
        <div>
            <div className="container">

                <div className="col m6 s12"></div>
                <h4>Ingresos Anuales {working ? "... calculando, por favor espere" : ''}</h4>
                <p>
                    Comparativa de ingresos recibidos del año actual contra el año anterior.
                </p>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={300}
                            data={pays.data} //Data
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: "currency", currency: "USD" }).format(value)} />
                            <Legend />
                            <Line dataKey={actualYear} stackId="a" stroke="#6e7dbd" fill="#6e7dbd" />
                            <Line dataKey={anteriorYear} stackId="b" stroke="#8a2323" fill="#8a2323" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div>
                    <h4>Detalle</h4>
                    <p>
                        Porcentaje de aumento en los ingresos por mes.
                    </p>
                    <div>
                        {pays.data && pays.data.map(row => (
                            <div>
                                <span><span className={row.diff > 0 ? "green" : "red"}>&nbsp;</span><b>{row.name}:</b> {row.diff}%</span>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        </div >
    );
};

export default ConsultaAnual;