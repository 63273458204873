import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Paginator from 'react-hooks-paginator';
import "react-image-crop/dist/ReactCrop.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { httpDelete, httpGet, httpGetImg, httpGetOne, httpPost, httpPostw, httpPut } from '../fetch';
import Balance from '../modda_modules/Balance';


export default function Client(props) {
    const payDayList = [{ value: 'lunes', label: 'Lunes' }, { value: 'martes', label: 'Martes' }, { value: 'miercoles', label: 'Miércoles' }, { value: 'jueves', label: 'Jueves' }
        , { value: 'viernes', label: 'Viernes' }, { value: 'sabado', label: 'Sábado' }, { value: 'domingo', label: 'Domingo' },
    { value: 'decenal', label: 'Decenal' }, { value: 'quincenal', label: 'Quincenal' }];

    const sexoList = [{ value: 'hombre', label: 'Hombre' }, { value: 'mujer', label: 'Mujer' }];

    const ocupationList = [
        { value: 'Empleado', label: 'Empleado' },
        { value: 'Ama de Casa', label: 'Ama de casa' },
        { value: 'Profesionista', label: 'Profesionista' },
        { value: 'Estudiante', label: 'Estudiante' },
    ];

    const tallasBlusaList = [
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "1XL", label: "1XL" },
        { value: "2XL", label: "2XL" },
        { value: "3XL", label: "3XL" },
        { value: "4XL", label: "4XL" },
    ];

    const tallasPantalonList = [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "7", label: "7" },
        { value: "9", label: "9" },
        { value: "11", label: "11" },
        { value: "13", label: "13" },
        { value: "15", label: "15" },
        { value: "XL", label: "XL" },
        { value: "XXL", label: "XXL" },
        { value: "XXXL", label: "XXXL" },
        { value: "IVL", label: "IVL" },
    ];

    const houseList = [{ value: 'Casa propia', label: 'Casa propia' }, { value: 'Renta', label: 'Renta' }];

    const periodoList = [{ value: 'semanal', label: 'Semanal' }, { value: 'decenal', label: 'Decenal' }, { value: 'catorcenal', label: 'Catorcenal' }, { value: 'quincenal', label: 'Quincenal' }, { value: 'mensual', label: 'Mensual' }];

    function admin() {
        if (localStorage.userType === 'Admin') return true;
        else return false;
    }

    function pub() {
        if (localStorage.userType === 'Publicidad') return true;
        else return false;
    }

    function cob() {
        if (localStorage.userType === 'Cobranza') return true;
        else return false;
    }

    function vent() {
        if (localStorage.userType === 'Ventas') return true;
        else return false;
    }

    function almacen() {
        if (localStorage.userType === 'Almacen') return true;
        else return false;
    }

    let M;
    const initialState = {
        picUrl: '', pic2Url: '', name: '', address: '', phone: '', cellPhone: '', email: '', birth: '', sex: '', facebook: '', shoeSize: 0, customSize: '', pantsSize: '', curp: '', creditLimit: 0,
        instagram: '', payMount: '', nextPayDay: '', nota: '', payDay: '', totalAmount: '', status: 'En Proceso', rating: 0, periodo: { value: 0, label: 'Seleccione una opción...' }, payDays: { value: 0, label: 'Seleccione una opción...' },
        ocupation: { value: 0, label: 'Seleccione una opción...' }, house: { value: 0, label: 'Seleccione una opción...' }, refName: '', refPhone: '', refFamily: ''
    }

    const [client, setClient] = useState(initialState);

    const [clientList, setClientList] = useState([]);
    const [file, setFile] = useState({ file: null });
    const [edit, setEdit] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [sexo, setSexo] = useState({ value: 0, label: "Seleccione una opción" });
    const [ocupation, setOcupation] = useState({ value: 0, label: "Seleccione una opción" });
    const [pantsSize, setPantsSize] = useState({ value: "", label: "Seleccione una opción" });
    const [customSize, setCustomSize] = useState({ value: "", label: "Seleccione una opción" });
    const [house, setHouse] = useState({ value: 0, label: "Seleccione una opción" });
    const [dia, setDia] = useState({ value: 0, label: "Seleccione una opción" });
    // const [periodo, setPeriodo] = useState({value:0, label:"Seleccione una opción"});
    const [clientesLista, setClientesLista] = useState({ value: 0, label: "... Buscar" });
    const [curpLista, setCurpLista] = useState({ value: 0, label: "... Buscar" });
    const [cellPhoneLista, setCellPhoneLista] = useState({ value: 0, label: "... Buscar" });
    // const [busqueda, setBusqueda] = useState({});
    const busqueda = { value: 0, label: '... Buscar Cliente' };
    const [count, setCount] = useState(0);
    const [typeSelected, setTypeSelected] = useState("0");
    const [filterSelected, setFilterSelected] = useState("0");

    //Paginator
    const pageLimit = 10;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        if (userType !== 'Admin' && userType !== 'Publicidad' && userType !== 'Cobranza' && userType !== 'Ventas' && userType !== 'Almacen') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/preventas'
        }
        setCurrentData(clientList.slice(offset, offset + pageLimit));
    }, [offset, clientList]);

    useEffect(() => {
        M = window.M; //Cargar Materialize desde CDN
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {
            onCloseEnd: () => {
                setEdit(false); setClient(initialState)
            }
        });

        var se = document.querySelectorAll('select');
        var instances = M.FormSelect.init(se, []);

        fetchClients();
    }, []) //<--- en este arreglo se debe hacer referencia a los objetos que se deben refrescar al cambiar el estado de la app.

    async function onFormSubmit(e, space) {
        setUploading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage', file.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const data = await httpPostw("/api/uploads/upload", formData, config)
            .then((response) => {
                console.log(response);
                const filename = response.data.data.filename;

                if (space === "picUrl") {
                    setClient({ ...client, picUrl: httpGetImg(filename) });
                } else {
                    setClient({ ...client, pic2Url: httpGetImg(filename) });

                }
                setUploading(false);
                alert("Archivo cargado correctamente, " + filename);
            }).catch((error) => {
                setUploading(false);
                alert("Ocurrió un problema al subir imágen.");
            });
    }

    function onFileChange(e) {
        setFile({ file: e.target.files[0] });
    }

    function handleChange(e) {
        return function (e) {
            const { name, value } = e.target
            setClient({ ...client, [name]: value })
            // console.log(client);
        }
    }

    function handleSexChange(e) {
        console.log(e);
        setSexo({ value: e.value, label: e.label });
        setClient({ ...client, sex: e.value });
    }

    function handleOcupationChange(e) {
        setOcupation({ value: e.value, label: e.label });
        setClient({ ...client, ocupation: e.value });
    }

    function handleCustomSizeChange(e) {
        setCustomSize({ value: e.value, label: e.label });
        setClient({ ...client, customSize: e.value });
    }

    function handlePantsSizeChange(e) {
        setPantsSize({ value: e.value, label: e.label });
        setClient({ ...client, pantsSize: e.value });
    }

    function handleHouseChange(e) {
        setHouse({ value: e.value, label: e.label });
        setClient({ ...client, house: e.value });
    }

    async function handleBusquedaChange(e) {
        // setBusqueda({value:e.value, label: e.label});
        setLoading(true)
        const result = await httpGetOne('/api/clients', e.value);
        if (result.data) {
            setLoading(false)
        }
        setClientList([result.data]);
    }

    function handlePeriodoChange(e) {
        console.log(e);
        setClient({ ...client, period: e.value, periodo: { value: e.value, label: e.label } });
    }
    function handlePayDayChange(e) {
        console.log(e);
        setClient({ ...client, payDay: e.value, payDays: { value: e.value, label: e.label } });
    }

    const fetchAllClients = async () => {
        setLoading(true)
        const result = await httpGet('/api/clients');
        if (result.data) {
            setLoading(false);
        }
        let activeClients = 0;
        const b = [];
        const curp = [];
        const cellP = [];
        if (result.data) {
            result.data.map(m => {
                b.push({ value: m.id, label: m.name });
                if (m.curp && m.curp !== '') {
                    curp.push({ value: m.id, label: m.curp })
                }
                if (m.cellPhone && m.cellPhone !== '') {
                    cellP.push({ value: m.id, label: m.cellPhone })
                }
                if (m.totalAmount > 0) {
                    activeClients++;
                }
            });
            setClientesLista(b);
            setCurpLista(curp);
            setCellPhoneLista(cellP)
        }
        window.M.updateTextFields();
        //Contar el número de clientes registrados
        setCount(activeClients);
    }

    const fetchClients = async () => {
        const result = await httpGet('/api/clients/actives');
        // console.log(result.data);
        setClientList(result.data);
        // let activeClients = 0;
        // const b = [];
        // if(result.data){
        //     result.data.map(m=>{
        //         b.push({value:m.id, label:m.name});
        //         if(m.totalAmount > 0){
        //             activeClients++;
        //         }
        //     });
        //     setClientesLista(b);
        // }
        window.M.updateTextFields();
        //Contar el número de clientes registrados
        // setCount(activeClients);
        fetchAllClients();
    }

    const addClient = async (e) => {
        e.preventDefault();
        if (!client.name || client.name === '') {
            alert("El nombre del cliente es obligatorio.");
            return;
        }
        if (sexo.value === 0) {
            alert("Seleccione una opción para Sexo antes de continuar.");
            return;
        }
        if (!client.cellPhone || client.cellPhone === 0) {
            alert("El número de celular es obligatorio.");
            return;
        }

        if (edit) {
            const result = await httpPut('/api/clients/' + client._id, client, localStorage.getItem('user'));
            console.log(result);
            setClient(initialState);
            setEdit(false);
        } else {
            const result = await httpPost('/api/clients', client, localStorage.getItem('user'));
            console.log(result.data)
            setClient(initialState);
        }

        fetchClients();
        //Cerrar Modal
        const M = window.M;
        let elem = document.querySelector("#modal1");
        let instance = M.Modal.getInstance(elem);
        instance.close();
    }

    const deleteClient = async (c) => {
        console.log(c._id)
        // e.preventDefault();
        const result = await httpDelete('/api/clients/delete/', c._id, localStorage.getItem('user'));
        console.log(result.data)
        setClient({});
        fetchClients();
    }

    const balanceClient = async (id) => {
        setClient({ id: id });
        console.log(id);
    }

    const editClient = async (id) => {
        const result = await httpGetOne('/api/clients/', id)
        const { _id, picUrl, pic2Url, name, address, phone, cellPhone, email, birth, sex, facebook, instagram,
            payMount, payDay, status, totalAmount, nextPayDate, period, nota, rating, shoeSize, customSize, creditLimit,
            house, ocupation, pantsSize, refName, refPhone, refFamily, curp } = result.data;

        // const birthDate = new Date(bir)

        setClient({
            _id, picUrl, pic2Url, name, address, phone, cellPhone, email, birth, sex, facebook, instagram, rating,
            payMount, status, totalAmount, nextPayDate, period, nota, payDay, shoeSize, customSize, pantsSize, creditLimit,
            house, ocupation, refName, refPhone, refFamily, curp,
            periodo: { label: result.data.period, value: result.data.period },
            payDays: { label: result.data.payDay, value: result.data.payDay }
        });
        // setPeriodo({label: result.data.period, value: result.data.period});
        setSexo({ label: result.data.sex, value: result.data.sex });
        setOcupation({ label: result.data.ocupation, value: result.data.ocupation });
        setCustomSize({ label: result.data.customSize, value: result.data.customSize });
        setPantsSize({ label: result.data.pantsSize, value: result.data.pantsSize });
        setHouse({ label: result.data.house, value: result.data.house });
        setEdit(true);
        console.log('client retrived> ' + result.data.payDay);

        //Ajuste Materialize
        const M = window.M;
        M.updateTextFields();
        var txtArea = document.getElementById('nota');
        M.textareaAutoResize(txtArea);
    }

    function beautify(name) {
        switch (name) {
            case 'completado':
                return 'Completado'
                break;
            case 'en proceso':
                return 'En Proceso'
                break;
            default:
                return name;
        }
    }

    const handleFilterChange = async (e) => {
        setTypeSelected(e.target.value);
        let result = "";
        if (e.target.value === 'all') {
            result = await httpGet('/api/clients');
        } else
            if (e.target.value === 'con' || e.target.value === '0') {
                result = await httpGet('/api/clients/actives');
            } else {
                result = await httpGet('/api/clients/salded');

                M = window.M; //Cargar Materialize desde CDN
                var se = document.querySelectorAll('select');
                var instances = M.FormSelect.init(se, []);
            }
        setClientList(result.data);
    }

    const handleMorososChange = async (e) => {
        const filter = e.target.value;
        setFilterSelected(e.target.value);

        const result = await httpGet('/api/clients/salded');

        const morosos = [];
        const recomendados = [];
        result.data.map(c => {
            if (c.rating > 60) recomendados.push(c);
            else morosos.push(c);
        });


        if (filter == "mor")
            setClientList(morosos);
        else
            setClientList(recomendados);

        // setClientList(e.target.value =="mor"?morosos:recomendados);
    }

    const handleChangex = (newValue: any, actionMeta: any) => {
        console.group('Value Changed');
        console.groupEnd();
        if (newValue)
            setClient({ ...client, name: newValue.value });
        else
            setClient({ ...client, name: '' });
    };
    const handleInputChangex = (inputValue: any, actionMeta: any) => {
        console.group('Input Changed');
        console.groupEnd();
    };

    const handleChangex2 = (newValue: any, actionMeta: any) => {
        console.group('Value Changed');
        console.groupEnd();
        if (newValue)
            setClient({ ...client, curp: newValue.value });
        else
            setClient({ ...client, curp: '' });
    };
    const handleInputChangex2 = (inputValue: any, actionMeta: any) => {
        console.group('Input Changed');
        console.groupEnd();
    };

    const handleChangeCellPhone = (newValue: any, actionMeta: any) => {
        console.group('Value Changed');
        console.groupEnd();
        if (newValue)
            setClient({ ...client, cellPhone: newValue.value });
        else
            setClient({ ...client, cellPhone: '' });
    };
    const handleInputChangeCellPhone = (inputValue: any, actionMeta: any) => {
        console.group('Input Changed');
        console.groupEnd();
    };

    return (
        <div>
            <div className="container">
                <h4>Clientes</h4>
                <div className="row">
                    <div className="col m6 s11">
                        {!cob() && !vent() ? (<button data-target="modal1" className="btn light-goldeneye modal-trigger">Agregar +</button>) : ''}
                    </div>
                    <br className="movil-only" />
                    <br className="movil-only" />
                    <br className="movil-only" />
                    <div className="col m6 s11">
                        <Select id="search" options={clientesLista} name="search" isDisabled={isLoading}
                            value={busqueda} onChange={handleBusquedaChange} className="selecter lvl100 s12" />
                    </div>
                </div>

                <section style={{ zIndex: 30 }}>
                    <div className="row">
                        <div className="input-field col m6 s12">
                            <select value={typeSelected} onChange={handleFilterChange} style={{ zIndex: 40 }}>
                                <option value="0" disabled>Filtrar activos...</option>
                                <option value="con">Con Adeudo</option>
                                <option value="sin">Sin Adeudo</option>
                                <option value="all">Todos</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    {typeSelected == "sin" ? (
                        <div className="row">
                            <div className="input-field col m6 s12">
                                <select value={filterSelected} onChange={handleMorososChange} style={{ zIndex: 35 }}>
                                    <option value="0" disabled>Filtrar Calificación...</option>
                                    {/* <option value="0">Todos</option> */}
                                    <option value="mor">Morosos</option>
                                    <option value="rec">Recomendados</option>
                                </select>
                            </div>
                        </div>
                    ) : ''}
                </section>
                <div>
                    <div className="center" style={{ zIndex: 0 }}>
                        <h4 style={{ color: 'black', marginBottom: '0', marginTop: '0' }}>Clientes Activos: <span className="txtGreen">{count}</span> </h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th className="pc-only-table-cell">Celular</th>
                                <th>Saldo</th>
                                <th>Prox. Pago</th>
                                <th className="pc-only-table-cell">Abono</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map(c => (
                                <tr className="Table"
                                    key={c._id}>
                                    <td><a className="modal-trigger" href="#modal2" target="_blank;" onClick={e => balanceClient(c._id)}>
                                        <img className="tumbnail" src={c.picUrl ? c.picUrl : '../img/nopic.png'} alt="pic generica"></img>
                                    </a>
                                    </td>
                                    <td>{c.name}</td>
                                    <td className="pc-only-table-cell">{c.cellPhone}</td>
                                    <td>${c.totalAmount ? c.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'}</td>
                                    <td>{moment(c.nextPayDate).utc().format('DD/MM/YYYY')}</td>
                                    <td className="pc-only-table-cell">${c.payMount ? c.payMount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                    <td>
                                        {!vent() ? (<button className="btn light-goldeneye modal-trigger"
                                            value={c._id}
                                            href="#modal1"
                                            onClick={e => { editClient(c._id) }}
                                            style={{ margin: '4px' }}>
                                            <i className="material-icons">edit</i>
                                        </button>) : ''}
                                        {admin() ? (<button className="btn light-goldeneye"
                                            onClick={(e) => { if (window.confirm('¿Estás seguro que deseas eliminar este cliente?')) deleteClient(c) }}
                                            style={{ margin: '4px' }}>
                                            <i className="material-icons">delete</i>
                                        </button>) : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Paginator
                        totalRecords={clientList.length}
                        pageLimit={pageLimit}
                        pageNeighbours={2}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageLinkClass="light-goldeneye"
                    />
                </div>
            </div>
            <div id="modal1" className="modal modal2">

                <div className="modal-content">
                    {/* {JSON.stringify(client)} */}
                    <div className="modal-header">
                        {edit ? (
                            <h4>Editar Cliente</h4>
                        ) : (
                            <h4>Nuevo Cliente</h4>
                        )}
                        <div style={{ textAlign: 'right', position: 'absolute', top: '5px', right: '5px' }}>
                            <button className="modal-close btn-floating light-goldeneye">X</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="center">
                            <div>
                                <div >
                                    <a href={client.picUrl} target="_blank" rel="noopener noreferrer">
                                        <img style={{ width: '100px' }} src={client.picUrl} alt=" no se ha subido la foto de perfil"></img>
                                    </a>
                                </div>

                                <form onSubmit={e => onFormSubmit(e, "picUrl")}>
                                    <h4>Foto de Perfil</h4>
                                    <input id="myImage" className="select-file"
                                        type="file" name="myImage" onChange={onFileChange}
                                        accept=".png, .jpg, .jpeg" />
                                    <label className="light-goldeneye btn" for="myImage">Seleccionar Archivo</label>
                                    <br className="movil-only" />
                                    <br className="movil-only" />
                                    <button className="btn light-goldeneye" type="submit"
                                        disabled={uploading}>Subir Archivo</button>
                                </form>
                            </div>
                            {uploading ? (<div>
                                <div className="progress">
                                    <div className="indeterminate"></div>
                                </div>
                                <div className="center">Cargando, por favor espere...</div>
                            </div>) : ''
                            }
                            <br /><br />
                        </div>
                    </div>
                    <form>
                        <div className="row">
                            <div>
                                <div className="input-fields col s12">
                                    <label for="cellPhone">Nombre Completo<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                    <CreatableSelect isClearable options={clientesLista}
                                        onChange={handleChangex}
                                        onInputChange={handleInputChangex}
                                        placeholder={client.name ? client.name : 'Ingresar Nombre Completo (obligatorio)'}
                                        formatCreateLabel={(inputValue) => `Agregar... ${inputValue}`}
                                        value={client.name}
                                        className="selecter lvl100 s12" />
                                </div>
                                <div className="input-fields col s12">
                                    <label for="address">Dirección<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                    <input id="address" type="text" name="address" value={client.address} onChange={handleChange("address")} required></input>
                                </div>
                                <div className="input-fields col s12">
                                    <label for="cellPhone">Teléfono Celular<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                    <CreatableSelect isClearable options={cellPhoneLista}
                                        onChange={handleChangeCellPhone}
                                        onInputChange={handleInputChangeCellPhone}
                                        placeholder={client.cellPhone ? client.cellPhone : 'Teléfono Celular'}
                                        formatCreateLabel={(inputValue) => `Agregar... ${inputValue}`}
                                        value={client.cellPhone}
                                        className="selecter lvl100 s12" />
                                </div>
                                <div className="row">
                                    <div className="input-fields col s12 m6">
                                        <label for="sex">Sexo<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                        <Select id="sex" options={sexoList} name="sex"
                                            value={sexo} onChange={handleSexChange} className="selecter capitalize s12 m6" />
                                    </div>
                                    <div className="input-fields col s12 m6">
                                        <label for="birth">Fecha de Nacimiento</label>
                                        <input id="birth" type='date' name="birth"
                                            value={moment(moment(client.birth).utcOffset(0)).format('YYYY-MM-DD')}
                                            onChange={handleChange("birth")}>
                                        </input>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="input-fields col s12 m6">
                                        <label for="ocupation">Ocupación<span style={{ color: 'red' }}></span></label>
                                        <Select id="ocupation" options={ocupationList} name="ocupation"
                                            value={ocupation} onChange={handleOcupationChange} className="selecter capitalize s12 m6" />
                                    </div>
                                    <div className="input-fields col s12 m6">
                                        <label for="house">Tipo de Casa<span style={{ color: 'red' }}></span></label>
                                        <Select id="house" options={houseList} name="house"
                                            value={house} onChange={handleHouseChange} className="selecter capitalize s12 m6" />
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="input-fields col s12 m6">
                                        <label for="customSize">Talla Blusa/Camisa<span style={{ color: 'red' }}></span></label>
                                        <Select id="customSize" options={tallasBlusaList} name="customSize"
                                            value={customSize} onChange={handleCustomSizeChange} className="selecter capitalize s12 m6" />
                                    </div>
                                    <div className="input-fields col s12 m6">
                                        <label for="pantsSize">Talla Pantalón<span style={{ color: 'red' }}></span></label>
                                        <Select id="pantsSize" options={tallasPantalonList} name="pantsSize"
                                            value={pantsSize} onChange={handlePantsSizeChange} className="selecter capitalize s12 m6" />
                                    </div>
                                </div>

                                <div className="input-fields col s12">
                                    <CreatableSelect isClearable options={curpLista}
                                        onChange={handleChangex2}
                                        onInputChange={handleInputChangex2}
                                        placeholder={client.curp ? client.curp : 'Ingresar CURP'}
                                        formatCreateLabel={(inputValue) => `Agregar... ${inputValue}`}
                                        value={client.curp}
                                        className="selecter capitalize s12" />
                                </div>

                                <div className="row">
                                    <div className="input-fields col s12 m6">
                                        <label for="facebook">Facebook</label>
                                        <input id="facebook" type='text' name="facebook" value={client.facebook} onChange={handleChange("facebook")}></input>
                                    </div>
                                    <div className="input-fields col s12 m6">
                                        <label for="instagram">Instagram</label>
                                        <input id="instagram" type='text' name="instagram" value={client.instagram} onChange={handleChange("instagram")}></input>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="input-fields col s12 m6">
                                        <label for="phone">Teléfono Fijo</label>
                                        <input id="phone" type='number' name="phone" value={client.phone} onChange={handleChange("phone")}></input>
                                    </div>
                                    <div className="input-fields col s12 m6">
                                        <label for="email">Correo Electrónico</label>
                                        <input id="email" type='email' name="email" value={client.email} onChange={handleChange("email")}></input>
                                    </div>
                                </div>

                                <div className="input-fields col s12">
                                    <label for="shoeSize">Talla Calzado</label>
                                    <input id="shoeSize" type='number' name="shoeSize" value={client.shoeSize} onChange={handleChange("shoeSize")}></input>
                                </div>
                            </div>

                            <div className="row">
                                &nbsp;
                            </div>

                            {edit && (admin() || pub() || cob()) ? (
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">attach_money</i>
                                    <input id="creditLimit" type="number" step="0.01" name="creditLimit" value={client.creditLimit}
                                        onChange={handleChange("creditLimit")}></input>
                                    <label htmlFor="creditLimit">Límite de Crédito</label>
                                </div>) : ''}
                            <div className="row">
                                <div className="input-fields col s12 m6 lvl100">
                                    <label for="period">Periodo de Pago</label>
                                    <Select id="period" options={periodoList} name="period"
                                        value={client.periodo} onChange={handlePeriodoChange} className="selecter capitalize s12 m6 lvl100" />
                                </div>
                                <div className="input-fields col s12 m6">
                                    <label for="nextPayDate">Próxima fecha de pago<span style={{ color: 'red' }}></span></label>
                                    <input id="nextPayDate" type='date' name="nextPayDate" value={moment(moment(client.nextPayDate).utcOffset(0)).format('YYYY-MM-DD')} onChange={handleChange("nextPayDate")}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-fields col s12 m6">
                                    <label for="payDays">Día de pago</label>
                                    <Select id="payDays" options={payDayList} name="payDays"
                                        value={client.payDays} onChange={handlePayDayChange} className="selecter capitalize s12 m6" />
                                </div>
                                <div className="input-field col s12 m6">
                                    <i className="material-icons prefix">attach_money</i>
                                    <label for="payMount">Parcialidades</label>
                                    <input id="payMount" type="number" step="0.01" name="payMount" value={client.payMount}
                                        onChange={handleChange("payMount")}></input>
                                </div>
                            </div>
                            {edit ? (
                                <div className="input-field col s12">
                                    <input id="rating" type="number" min="0" max="100" name="rating" value={client.rating}
                                        onChange={handleChange("rating")}></input>
                                    <label htmlFor="rating">Calificación</label>
                                </div>) : ''}

                            {!edit && admin() ? (
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">attach_money</i>
                                    <input id="totalAmount" type="number" step="0.01" name="totalAmount" value={client.totalAmount}
                                        onChange={handleChange("totalAmount")}></input>
                                    <label htmlFor="totalAmount">Adeudo Inicial</label>
                                </div>) : ''}

                            <div className="input-fields col s12">
                                <label for="nota">Notas:</label>
                                <textarea class="materialize-textarea" lines="5" id="nota" name="nota"
                                    value={client.nota} onChange={handleChange("nota")}>
                                </textarea>
                            </div>

                            <div className="input-fields col s12">
                                <label for="refName">Nombre completo (Referencia) </label>
                                <input id="refName" type='text' name="refName" value={client.refName} onChange={handleChange("refName")}></input>
                            </div>

                            <div className="row">
                                <div className="input-fields col s12 m6">
                                    <label for="refPhone">Celular (Referencia)</label>
                                    <input id="refPhone" type='text' name="refPhone" value={client.refPhone} onChange={handleChange("refPhone")}></input>
                                </div>
                                <div className="input-fields col s12 m6">
                                    <label for="refFamily">Parentesco (Referencia)</label>
                                    <input id="refFamily" type='text' name="refFamily" value={client.refFamily} onChange={handleChange("refFamily")}></input>
                                </div>
                            </div>

                            <div className="input-fields col s12">
                                <label for="status">Verificación</label>
                                <input id="status" type='text' name="status" disabled value={beautify(client.status)} onChange={handleChange("status")}></input>
                            </div>

                        </div>

                        <br />
                        <br />

                        <div className="row">
                            <div className="center">
                                <div>
                                    <div >
                                        <a href={client.pic2Url} target="_blank" rel="noopener noreferrer">
                                            <img style={{ width: '100px' }} src={client.pic2Url} alt="no se ha subido la foto de perfil"></img>
                                        </a>
                                    </div>

                                    <form onSubmit={e => onFormSubmit(e, "pic2Url")}>
                                        <h4>Foto Secundaria</h4>
                                        <input id="myImage2" className="select-file"
                                            type="file" name="myImage2" onChange={onFileChange}
                                            accept=".png, .jpg, .jpeg" />
                                        <label className="light-goldeneye btn" for="myImage2">Seleccionar Archivo</label>
                                        <br className="movil-only" />
                                        <br className="movil-only" />
                                        <button className="btn light-goldeneye" type="submit"
                                            disabled={uploading}>Subir Archivo</button>
                                    </form>
                                </div>
                                {uploading ? (<div>
                                    <div className="progress">
                                        <div className="indeterminate"></div>
                                    </div>
                                    <div className="center">Cargando, por favor espere...</div>
                                </div>) : ''
                                }
                                <br /><br />
                            </div>
                        </div>


                        <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                        {edit ? (
                            <button className="waves-effect btn light-goldeneye" style={{ float: 'right' }} onClick={e => addClient(e)}>Guardar</button>
                        ) : (
                            <button className="waves-effect btn light-goldeneye" style={{ float: 'right' }} onClick={e => addClient(e)} >Agregar</button>
                        )}
                    </form>
                    <br />
                </div>
            </div>

            <div id="modal2" className="modal modal2 modal-fixed-footer">
                <div className="modal-content gr_pattern">
                    <Balance value={client.id}></Balance>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect btn light-goldeneye">Cerrar</button>
                </div>
            </div>
        </div>
    )
}