import React,  {useState, useEffect, useMemo} from 'react';
import {httpGet, httpDelete, httpPost, httpGetOne, httpPut, httpGetImg} from '../fetch';
import moment from 'moment'

import Select from 'react-select';
import { realpath } from 'fs';

export default function Sales(props) {

    const [metodosPago, setMetodosPago] = useState([])
    const [sale, setSale] = useState({items:[],client:{}});
    const [presale, setPresale] = useState({});
    const [client, setClient] = useState({label: 'Seleccionar Cliente', value:'0'});
    const [item, setItem] = useState({label:'Seleccionar artículo', value:'0'});
    const [vari, setVari] = useState({label:'Seleccionar variedad', value:'0'});
    const [saleItem, setSaleItem] = useState({});
    const [edit, setEdit] = useState(false);
    const [total, setTotal] = useState({total:0})
    const [payment, setPayment] = useState({payment:{label:'¿Tipo de Pago?', value:0}, newPayday:{label:'¿Cambiar día de pago?',value:0},
            payMethod:{label: 'Seleccione Método de pago', value:'0'}});
    const periodoList = [{value:'semanal', label:'Semanal'},{value:'decenal', label:'Decenal'}, {value:'catorcenal', label:'Catorcenal'}, {value:'quincenal',label:'Quincenal'},{value:'mensual',label:'Mensual'}];
    const [clientLista, setClientLista] = useState([]);
    const [itemsLista, setItemLista] = useState([]);
    const [variantes, setVariantes]= useState([]);
    let count = 0;
    const [presa, setPresa] = useState({});
    const [withDiscount, setWithDiscount] = useState(false);

    useEffect(() => {
        if(props !== '' ){
            fetchPresale();
        } else {
        fetchClients();
        fetchItems();
        fetchPayMethods();

        }
        let M;
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, {onCloseEnd:()=>{setEdit(false);}});
        
    }, []) //<--- en este arreglo se debe hacer referencia a los objetos que se deben refrescar al cambiar el estado de la app.
    
    async function fetchPayMethods() {
        const result = await httpGet('/api/cards');
        let cards = [];
        result.data.map(c => {
            if (c.number !== 'ajuste') {
                cards.push({ value: c.number, label: c.display });
            }
        })
        setMetodosPago(cards)
    }

    async function fetchPresale() {
        const propsx = props.location.pathname.split('/');
        const presaleId = propsx[propsx.length -1].toString();
        // console.log(presaleId[presaleId.length -1]);
        if(count < 1){
        count ++;
        httpGetOne('/api/presales/',presaleId)
            .then((result) =>{
                setPresa(result.data);
                const toadd= [];
                let counter = 0;
                // presa = re}sult.data;
            // result.data.items.map( i => {
            //     toadd.push({item: i, subItem: i.stock[counter], quantity: i.quantitys[counter]});
            //     counter++;
            // });

            for (let i = 0; i < result.data.items.length; i++) {
                const item = result.data.items[i];                
                const subItem = item.stock.find((id) => { return id.id === result.data.subItemIds[i]});                
                const quantity = result.data.quantitys[i];
                toadd.push({item: item, subItem: subItem, quantity: quantity});               
            }
            console.log(toadd)
            setSale({items: toadd, client: result.data.client})
                });
        fetchClients();
        fetchItems();
        }
        // console.log(result.data);
        // const toadd= [];
        // result.data.items.map( i => {
        //     toadd.push({item: i, subItem: i.stock[0], quantity: 1});
        // });
        // console.log(sale.items)
        // setSale({items: toadd, client: result.data.client})
        // // setItem()
        // selectClient(result.data.client.id)
    }
    
    function handleChange(e){
       setClient( e )
       console.log('Client selected: '+ JSON.stringify(e.value));
       selectClient(e.value);
    }

    function setAsPresale(){
        const saleItems = [];
        const subItems = [];
        sale.items.map( i =>{
            const it = i.item;
            const sit = i.subItem;
            saleItems.push(it);
            subItems.push(sit);
        });
        const p = {
            client: sale.client,
            items: saleItems,
            subItemIds: subItems,
            amount: total.total,
        }
        setPresale(p);
        console.log(p)
    }

    function handlePaymentChange(e){
        if (e.target.value === 'contado'){
            if(!withDiscount){
            setTotal({...total, total:total.total*0.85});
            setPayment({...payment, payment:{value:e.target.value}, anticipo: total.total*0.85});
            setWithDiscount(true);
            }
        }
        else {
            if(!withDiscount)
                setPayment({...payment, payment:{value:e.target.value}});
            else{
                setTotal({...total, total:total.total*100/85});
                setPayment({...payment, payment:{value:e.target.value}, anticipo: 0});
                setWithDiscount(false);
            }
        }
        
        window.M.updateTextFields();
        //nextPayday();

    }

    function handlePaymentPeriodChange(e){
        setPayment({...payment, period:{value:e.value, label:e.label}})
        // setSale({...sale, client:{...sale.client, payDay:e.value}});
        // nextPayday();
    }

    function handleItemChange(e){
       setItem( e )
       setVari({value:0,label:'Seleccionar Variedad'});
    //    console.log('Item selected: '+ JSON.stringify(e.value, e.label));
       selectItem(e.value);
    }

    function handleStockChange(e){
       setVari( e )
    //    console.log('Stock selected: '+ JSON.stringify(e.value, e.label));
       //selectItem(e.value);
    }

    function handleAnticipoChange(e){
        console.log(e);
        setPayment({...payment, anticipo: e.target.value})
    }

    function handleAnticipoConfirmacionChange(e){
        console.log(e);
        setPayment({...payment, anticipoConfirmacion: e.target.value})
    }

    
    function handleAmountChange(e){
        console.log(e)
        setSale({...sale, client:{...sale.client, payMount: e.target.value}});
        //setPayment({...payment, payMount: e.target.value})
    }
    
    function handlePaymentMethodChange(e){
        console.log(e);
        setPayment({...payment, payMethod:{value:e.value, label: e.label}})
    }

    function handleItemSaleChange(e) {
        return function(e) {
            const {name, value} = e.target;
            setItem({...item, [name]:value})
        }
    }

    function handleNextPayDate(e){
        // setPayment({...payment, nextPayDate:e.target.value});
        setSale({...sale, client:{...sale.client, nextPayDate:e.target.value}});
    }

    function handlePayDay(e){
        // setPayment({...payment, nextPayDate:e.target.value});
        setSale({...sale, client:{...sale.client, payDay:e.target.value}});
    }

    async function selectClient(cId){
       const result = await httpGetOne('/api/clients/',cId);
       const client = result.data;
       setSale({...sale, client});
    }
    
    async function selectItem(cId){
       const a = [];
       const result = await httpGetOne('/api/items/',cId);
       const item = result.data;
       item.stock.map(s =>{
            const newStock = {label: s.subItem+' - '+s.quantity+' disponible(s)', value: s.id};
            a.push(newStock);
        });
        setVariantes(a);
        setSaleItem({item, picUrl: item.picUrl});
    }

    const fetchClients = async () => {
        const result = await httpGet('/api/clients');
            // console.log(result.data)
            window.M.updateTextFields()
        const a = []
        result.data.map(c =>{
            const newVal = {label: c.name, value: c.id}
            a.push(newVal);
        })
        setClientLista(a);
    }
    

    const fetchItems = async () => {
        const result = await httpGet('/api/items');
        const a = [];
            // console.log(result.data) 
            result.data.map(c =>{
                let thisStock = 0;
                if (c.stock.length > 0){
                    c.stock.map(s =>{
                        thisStock += s.quantity;
                    })
                }
                if (thisStock > 0){
                    const newVal = {label: '['+c.internalCode+'] '+ c.name+' - '+c.description, value: c.id}
                    // console.log(newVal)
                    a.push(newVal);
                }
            });
            setItemLista(a);
    }

    const addItem = async (e) => {
        e.preventDefault();
        if (item.quantity > 0){
            const itemId=item.value;
            const result = await httpGetOne('/api/items/',itemId);

            const a = result.data;
            let errorStock = false;
            a.stock.map(s =>{
                if(s.id === vari.value){
                    if(s.quantity < item.quantity){
                        alert("No hay inventario suficiente.");
                        // errorStock = true;
                    }
                }
            })

            if(errorStock) return;
            const detail= {item:a, subItem:vari.value, quantity:item.quantity}
            
            setSale({...sale, items:[...sale.items, detail]});
            // setItem();
            setSaleItem({});
            setVari({label:'Seleccionar variedad', value:'0'});
            setItem({label:'Seleccionar artículo', value:'0',quantity:''});
            
        }
    }

    const calTot =() =>{
        // Calcular el monto de la venta:
        console.log('calculando total')
        let tot = 0;
        sale.items.forEach(i => {
            tot += (i.item.price * i.quantity);
            //console.log(tot);
        });
        setTotal({total:tot})
    }

    

    const deleteItem = async (e) => {
        const array = sale.items.slice();;
        console.log(e.item._id)
        const getf = array.find(ei => ei.item._id === e.item._id);
        const index = array.indexOf(getf);
        console.log(index)
        if (index > -1) {
            array.splice(index, 1);
        }
        console.log(array)
        setSale({...sale, items: array})

        //console.log(e);
    }

    const toPay = async (e) =>{
        if(!sale.saleNumber){
            setSale({...sale, saleNumber:moment().format('YYMMDDHHMMss')});
        }
    }

    const validateCredit = (e) =>{
        console.log(sale.client.nextPayDate);
        e.preventDefault();
        if(!payment.period){
            alert("Verificar el Periodo de Pago antes de continuar.");
            return;
        }
        if (!sale.client.nextPayDate || moment(sale.client.nextPayDate).isBefore(moment())){
            alert("Verificar la próxima fecha de pago antes de continuar.");
            return;
        } 
        if(!sale.client.payMount || sale.client.payMount <= 0){
            alert("Verificar el monto de las parcialidades antes de continuar.");
            return;
        } 
        if(payment.anticipo && payment.anticipo > 0){
            if(payment.anticipo !== payment.anticipoConfirmacion){
                alert("El monto del anticipo y su confirmación no coinciden.");
                return;
            }
            if(payment.payMethod.value === '0') {
                alert("Seleccione el método de pago para el anticipo, antes de continuar.")
                return;
            }
        } 
        
        //Cerrar el Modal Manualmente
        const M = window.M; 
        var elem = document.getElementById('modal2');
        var instance = M.Modal.getInstance(elem);
        instance.close();
        
    }

    const handleDateChange = (e) =>{
        setPresale({...presale, programedDate: e.target.value});
    }

    const closePresale = async (e) =>{
        e.preventDefault();

        let errorStock = false;
        console.log(presale)
        // a.stock.map(s =>{
        //     if(s.id === vari.value){
        //         if(s.quantity < item.quantity){
        //             alert("No hay inventario suficiente.");
        //             // errorStock = true;
        //         }
        //     }
        // })

        // if(errorStock) return;

        // const result = await httpPost('/api/presales/', presale);
        // console.log(result);
    }
    
    const closeSale = async (e) =>{
        
        const anticipo = {};
        if(payment.anticipo){
            if(payment.anticipo > 0){
                anticipo.amount=payment.anticipo;
                anticipo.status = 'pagado';
                anticipo.payDate = new Date();
                anticipo.active = true;
                //TODO: Agregar el usuario que recibe el pago.
                anticipo.verified = true;
                anticipo.payMethod=payment.payMethod.value;
                anticipo.client= sale.client.id;
            }
        }

        const completeSale={
            presale: presa,
            saleAmount: total.total,
            saleNumber: sale.saleNumber,
            items: sale.items,
            total: total.total,
            status: 'cerrada',
            date: new Date(),
            payment: payment.payment.value,
            //TODO: Agregar usuario que está cerrando la venta.
            client: sale.client,
            anticipo: anticipo
        };
        const result = await httpPost('/api/sales/credit/presale/', {completeSale}, localStorage.getItem('user'));
        console.log(result);

        setSale({items:[],client:{payMount:0}});
        setClient({label: 'Seleccionar Cliente', value:'0'});
        setVari({label:'Seleccionar variedad', value:'0'});
        setItem({label:'Seleccionar artículo', value:'0'});
        setPayment({payment:{label:'¿Tipo de Pago?', value:0}, newPayday:{label:'¿Cambiar día de pago?',value:0},
        payMethod:{label: 'Seleccione Método de pago', value:'0'}});
        fetchItems();
    }

    const closeContado = async () => {
        if(!payment.anticipo || payment.anticipo < 1){
            alert("¿Revise el monto antes de continuar?");
            return;
        }
        if(payment.anticipo != payment.anticipoConfirmacion){
            alert("La confirmación del monto no coincide. ");
            return;
        }
        if(!payment.payMethod || payment.payMethod.value == '0'){
            alert("Especifique el método de pago, antes de continuar.");
            return;
        }
        //Cerrar el Modal Manualmente
        const M = window.M; 
        var elem = document.getElementById('modal3');
        var instance = M.Modal.getInstance(elem);
        instance.close();
    }

    useMemo(calTot, [sale]);

    return(
        <div>
            <div className="container">
                <h4>Punto de Venta</h4>
                    
            {/* {JSON.stringify(presa)} */}
            </div>
            {/* {sale.client ? (<span>Cliente:{sale.client.name}, cId:{sale.client.id}</span>):(<span></span>)} */}
            <br />
            <div className="container">
                <div className="row">
                    <div className="col m6 s12 grupe">
                        <Select id="clientId" placeholder="Seleccionar Cliente" options={ clientLista } name="clientId" 
                            value={client} onChange={handleChange} className="selecter m6 s12"/>
                        <br />
                        {/* <input type='number' name="quantity" value={item.quantity} 
                            onChange={handleItemSaleChange("quantity")} placeholder="Cantidad" style={{width:"40%;"}}></input> */}

                    </div>
                    <div className="col m6 s12 grupe center">
                        <h5>{sale.client?sale.client.name:''}</h5>
                        <img className="perfile" src={sale.client?sale.client.picUrl:''} />
                    </div>
                </div>
                <div className="row">
                    <h4>Artículos</h4>
                </div>
                <div className="row">
                    <div className="col m6 s12 grupe">
                            <Select id="itemId" placeholder="Seleccionar Artículo" options={ itemsLista } name="itemId"
                                value={item} onChange={handleItemChange} className="selecter m6 s12" />
                            <br />
                            
                            <Select id="stock" placeholder="Seleccionar Variante" options={ variantes } name="stock"
                                value={vari} onChange={handleStockChange} className="selecter m6 s12" />
                            <br />
                        <div className="input-field col s12">
                            <input id="quantity" type="number" name="quantity" value={item.quantity} 
                                onChange={handleItemSaleChange("quantity")}></input>
                            <label htmlFor="quantity">Cantidad</label>
                        </div>
                    </div>
                    <div className="col m6 s12 grupe center">
                        <img className="perfile" src={saleItem?saleItem.picUrl:''} />
                    </div>
                </div>
                <br />
                <div className="center">
                    <a href="#table" className="btn light-goldeneye" onClick={addItem} style={{marginLeft:'10px'}}>Agregar</a>&nbsp;    
                    <br /><br />
                </div>
            </div><br /><br />
            <div className="container">
                {sale.items ? (
                <table id="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Artículo</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sale.items.map((i) => (
                            <tr key={i.id} className="Table">
                                <td></td>
                                <td><a href={i.item?i.item.picUrl:''} target="_blank;">{i.item?i.item.name:''}</a></td>
                                <td>{i.quantity}</td>
                                <td>${i.item?i.item.price:''}</td>
                                <td>${i.item?i.item.price * i.quantity :''}</td>
                                <td>
                                    <button className="btn light-goldeneye"
                                    value={i}
                                    onClick={()=> { if (window.confirm('¿Estás seguro que deseas quitar este producto de la venta?')) deleteItem(i,i.id)}}
                                    style={{margin: '4px',}}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>):<span></span>}
            </div>
            <div className="center">
            <br />

                {total.total ? <button className="btn light-goldeneye modal-trigger clientes-btn" href="#modal1"
                    onClick={toPay}><span>Total: $ {total.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;<br/></span>Proceder al Pago</button>: <span></span>}
            
                <br /><br /><br /><br /><br />
            </div>
            <div id="modal1" className="modal modal2">
                
                <div className="modal-content">
                    <h4>Nueva Venta - <span>{sale.saleNumber}</span></h4>
                    <div className="row">                           
                        <div className="col s12 informative">
                            <label>Revise los datos y ajuste la configuración de pagos, antes de concretar la venta.</label>
                        </div>
                    </div>
                    <div className="row">                           
                        <div className="col s12 informative">
                            <label><b>Cliente: </b>{sale.client?sale.client.name:'Debe seleccionar un cliente, antes de continuar.'}</label><br/>
                            <label><b>Dirección: </b>{sale.client?sale.client.address:'Debe seleccionar un cliente, antes de continuar.'}</label><br/>
                            <label><b>Teléfono: </b>{sale.client?sale.client.phone:'Debe seleccionar un cliente, antes de continuar.'}</label><br/>
                            <label><b>Celular: </b>{sale.client?sale.client.cellPhone:'Debe seleccionar un cliente, antes de continuar.'}</label><br/>
                            <label><b>Día de pago: </b>{sale.client?sale.client.payDay:'Debe seleccionar un cliente, antes de continuar.'}</label><br/>
                        </div>
                    </div>

                    <div className="row">                           
                        <div className="col s12 informative">
                            <label><b>Total a pagar: </b><span>$ {total.total?total.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):''}</span></label><br/>
                        </div>
                    </div>

                    <div className="row">                           
                        <div className="col s12"></div>
                        <h4>Configuración del pago </h4>
                        <div className="col m6 s12 grupe">
                            {/* <div className="nmms">
                                <Select id="payment" placeholder="Seleccionar Método de Pago" options={ payforms } name="payment" 
                                    value={payment.payment} onChange={handlePaymentChange} className="selecter m6 s12" />
                            </div> */}
                            <br/>
                            <br/>
                            <div className="center">
                                <button className="waves-effect btn light-goldeneye modal-trigger"  
                                        onClick={handlePaymentChange}
                                        href="#modal2" style={{marginRight:'5px'}} value="credito">Crédito</button>
                                <button className="waves-effect btn light-goldeneye modal-trigger" 
                                        onClick={handlePaymentChange}
                                        href="#modal3" style={{marginRight:'5px'}} value="contado">Contado</button>
                            </div>
                            <br/>
                            {/* {JSON.stringify(payment)} */}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                    <button className="modal-close waves-effect btn light-goldeneye" onClick={e =>
                        window.confirm("¿Desea cerrar la venta con los datos proporcionados?") &&
                        closeSale(e)
                    } style={{float:'right'}}>Aceptar</button>
                </div>
            </div> {/* Fin Modal1 */}

            <div id="modal2" className="modal modal2 modal-fixed-footer">
                <div className="modal-content">
                    <h4>Crédito <span>{sale.saleNumber}</span></h4>
                    <br/>
                    {/* <p><span>Próximo día de pago: {moment(sale.client.nextPayDate).format("DD/MM/YYYY")}</span></p> */}
                    <Select id="period" placeholder="Periodo de Pago" options={ periodoList } name="period" 
                            value={payment.period} onChange={handlePaymentPeriodChange} className="selecter m6 s12"/>
                    <br/>
                    <div className="input-field col s12">
                        <input id="nextPayDate" type="date" min={moment().format('YYYY-MM-DD')} name="nextPayDate" value={sale.client.nextPayDate} 
                            onChange={handleNextPayDate}></input>
                        <label htmlFor="nextPayDate">Próximo Día de Pago <span style={{color:'red'}}>&nbsp;*</span></label>
                    </div>
                    <div className="input-field col s12">
                        <input id="payDay" type="text" name="payDay" value={sale.client.payDay} 
                            onChange={handlePayDay}></input>
                        <label htmlFor="payDay">Día de Pago <span style={{color:'red'}}>&nbsp;*</span></label>
                    </div>
                    
                    <label htmlFor="payMount">Parcialidades <span style={{color:'red'}}>&nbsp;*</span></label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="payMount" name="payMount" type="number" step="0.01" name="payMount" 
                            value={sale.client.payMount} 
                            onChange={handleAmountChange} style={{width:'90%'}}></input>
                    </div>

                    <label htmlFor="anticipo">Anticipo</label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input id="anticipo" type="number" step="0.01" name="anticipo" value={payment.anticipo} 
                            onChange={handleAnticipoChange} style={{width:'90%'}}></input>
                    </div>

                    <label htmlFor="anticipoConfirmacion">Vuelva a intoducir la cantidad</label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input id="anticipoConfirmacion" type="number" step="0.01" name="anticipoConfirmacion" value={payment.anticipoConfirmacion} 
                            onChange={handleAnticipoConfirmacionChange} style={{width:'90%'}}></input>
                    </div>

                    <Select id="payMethod" placeholder="Seleccionar Método de pago" options={ metodosPago } name="payMethod" 
                            value={payment.payMethod} onChange={handlePaymentMethodChange} className="selecter m6 s12" style={{zIndex:'999'}}/>
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                    <button className="waves-effect btn light-goldeneye" style={{float:'right'}} onClick={validateCredit}>Aceptar</button>
                </div>
            </div> {/* Fin Modal2*/}

            <div id="modal3" className="modal modal2 modal-fixed-footer">
                <div className="modal-content">
                    <h4>Contado<span>{sale.saleNumber}</span></h4>
                    <br/>
                    <span>Se aplica 15% de descuento sobre el total de la venta en pagos de contado.</span>
                    <br/>
                    <label for="payMount">Total a Pagar <span style={{color:'red'}}>&nbsp;*</span></label>
                    <div className="input-field col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="payMount" name="payMount" type="number" step="0.01" name="payMount" 
                            value={payment.anticipo} 
                            onChange={handleAnticipoChange}>
                        </input>
                    </div>

                    <label for="payMountConfirmacion">Vuelva a intoducir la cantidad. <span style={{color:'red'}}>&nbsp;*</span></label>
                    <div className="input-field col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="payMountConfirmacion" name="payMountConfirmacion" type="number" step="0.01" name="payMountConfirmacion" 
                            value={payment.anticipoConfirmacion} 
                            onChange={handleAnticipoConfirmacionChange}>
                        </input>
                    </div>

                    <Select id="payMethod" placeholder="Seleccionar Método de pago" options={ metodosPago } name="payMethod" 
                            value={payment.payMethod} onChange={handlePaymentMethodChange} className="selecter m6 s12"/>
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                    <button className="waves-effect btn light-goldeneye" onClick={closeContado} style={{float:'right'}}>Aceptar</button>
                </div>
            </div> {/* Fin Modal3*/}
        </div>
    )
}