import axios from "axios";
import { useState } from "react";

const backend = 'https://api.grcollection.com.mx';
// const backend = 'http://localhost:2999';


export default function usePost(path) {
    const user = localStorage.getItem('user');
    const [working, setWorking] = useState(false);
    const [response, setResponse] = useState(null);

    async function doPost(body) {
        setWorking(true);
        let result = null;
        try {
            result = await axios.post(`${backend}${path}`, {...body, user});
            setResponse(result);
        } catch (e) {
            console.error(e)
        }
        setWorking(false);
        return result;
    }

    return [working, doPost, response];

}