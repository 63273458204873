import React from 'react'

export default function AttributesList({name, list}) {
    return (
        <React.Fragment>
            <div className="attrWrapper">{name}: </div>
        <div>
            {
                list.map(a => (
                    <span className="attrList" key={a}>{a}</span>
                ))
            }
        </div>
        </React.Fragment>
    )
}