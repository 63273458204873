import React, { useState, useEffect, useContext } from 'react';
import { httpGet, httpDelete, httpPost, httpPostw, httpGetOne, httpPut, httpGetImg } from '../fetch';
import moment from 'moment';
import { EPERM } from 'constants';

function momentToString(date) {
    return moment(date).format('YYYY-MM-DD');
}

let sortBy = (function () {
    let toString = Object.prototype.toString,
        // default parser function
        parse = function (x) { return x; },
        // gets the item to be sorted
        getItem = function (x) {
            let isObject = x !== null && typeof x === "object";
            let isProp = isObject && this.prop in x;
            return this.parser(isProp ? x[this.prop] : x);
        };

    /**
     * Sorts an array of elements.
     *
     * @param {Array} array: the collection to sort
     * @param {Object} cfg: the configuration options
     * @property {String}   cfg.prop: property name (if it is an Array of objects)
     * @property {Boolean}  cfg.desc: determines whether the sort is descending
     * @property {Function} cfg.parser: function to parse the items to expected type
     * @return {Array}
     */
    return function sortby(array, cfg) {
        if (!(array instanceof Array && array.length)) return [];
        if (toString.call(cfg) !== "[object Object]") cfg = {};
        if (typeof cfg.parser !== "function") cfg.parser = parse;
        cfg.desc = !!cfg.desc ? -1 : 1;
        return array.sort(function (a, b) {
            a = getItem.call(cfg, a);
            b = getItem.call(cfg, b);
            return cfg.desc * (a < b ? -1 : +(a > b));
        });
    };

}());

export default function Balance(props) {

    let M;
    const [balance, setBalance] = useState([]);
    const [client, setClient] = useState();
    let cid = '';
    let results = 10;
    const prope = useContext(props);

    useEffect(() => {
        M = window.M; //Cargar Materialize desde CDN
        if (props !== '') {
            cid = props.value;
            props.results ? results = props.results : results = results;
        }
        // console.log('b: '+cid);
        if (cid !== undefined)
            fetchClient(cid);
    }, [props])

    // useEffect(()=>{
    //     // fetchClient();
    //     console.log('c: '+cid);
    // },[cid]);

    const fetchClient = async (cid) => {
        const result = await httpGetOne('/api/clients', cid);
        setClient(result.data);
        const datos = [];

        result.data.pays.map(p => {
            const dato = {
                id: p.id,
                date: p.payDate,
                move: 'Su pago, ' + p.payMethod,
                amount: p.amount,
                type: 'p'
            }

            datos.push(dato);
        });

        result.data.buys.map(async b => {
            // const detalle = await httpGet('/api/items'+b.items[0]);
            // console.log("XXXXXXX: "+detalle)
            // console.log("YYYYYYY: "+JSON.stringify(b))
            const appear = b.saleName ? b.saleName : b.saleNumber;
            //const detail = await httpGet('/api/saledetails/byNumber/'+b.saleNumber);
            //console.log(detail.data);
            const dato = {
                id: b.id,
                date: b.date,
                move: (appear) ? 'Compra, ' + appear : 'Adeudo Sistema Anterior',
                amount: b.total,
                type: 'c'
            }
            datos.push(dato);
        });

        const sortedData = sortBy(datos, { prop: "date" });
        // console.log(sortedData);
        // const insortedData = [];
        // sortedData.map(x => {insortedData.unshift(x)});
        // console.log(insortedData);

        // sortedData.reverse(datos,{prop: "date"});
        const finalBalance = [];
        let suma = 0;

        sortedData.map(e => {
            e.type === 'c' ? suma += e.amount : suma -= e.amount;
            e.subTotal = suma;
            finalBalance.push(e);
        });
        // if (results > finalBalance.length){

        setBalance(finalBalance.reverse());
        // }
        // else {            
        //     console.log(results +'<'+finalBalance.length)
        // const slicedBalance = [];
        // const saldoAnterior = {
        //     id: finalBalance[0].id, 
        //     date: '-', 
        //     move:'Saldo anterior', 
        //     amount: 0,
        //     subTotal: finalBalance[finalBalance.length-results-1].subTotal}
        // slicedBalance.push(saldoAnterior)
        // finalBalance.slice(-results).map(x => slicedBalance.push(x));
        // setBalance(slicedBalance)
        // }
        // setBalance(finalBalance.slice(finalBalance.length-results,finalBalance.length));
    }

    function getPrize(value) {
        if (value > 90) return '../img/gold.png';
        if (value > 81) return '../img/silver.png';
        if (value > 75) return '../img/bronze.png';

        return ''
    }

    return (
        <div>
            {/* {JSON.stringify(balance)} */}
            <div className="center">
                {/* <b>Adeudo Total: {client.totalAmount?`$${client.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`:''}</b> */}

            </div>
            <div className="center">
                <h5><b>Estado de Cuenta</b></h5>
                {
                    client && client.rating > 75 &&
                    <div style={{ position: 'absolute', top: '50px', left: '15px', height: '15vh' }}>
                        <img src={getPrize(client.rating)} alt="Prize" style={{ height: '15vh' }}/>
                    </div>
                }
                <a href={client ? client.picUrl ? client.picUrl : '' : ''} target="_blank">
                    <img src={client ? client.picUrl ? client.picUrl : '../img/nopic.png' : ''} alt="Profile_Photo" style={{ height: '15vh' }}></img>
                </a>
                <h5 style={{ marginTop: '0' }}>{client ? client.name : ''}</h5>
                <h5 style={{ fontSize: '1.2rem' }}>Calificación:<span>{client ? client.rating : ''}</span></h5>
                {/* <div style={{width:'50%',marginRight:'auto',marginLeft:'auto', backgroundColor:'#c8c8c8'}}> */}
                <div style={{ width: '50%', marginRight: 'auto', marginLeft: 'auto' }}>
                    <ul class="thermometer">
                        <li class={client ? client.rating < 26 ? 'rating_a active' : 'rating_a' : ''}></li>
                        <li class={client ? client.rating < 51 && client.rating > 25 ? 'rating_b active' : 'rating_b' : ''}></li>
                        <li class={client ? client.rating < 76 && client.rating > 50 ? 'rating_c active' : 'rating_c' : ''}></li>
                        <li class={client ? client.rating > 75 ? 'rating_d active' : 'rating_d' : ''}></li>
                    </ul>
                </div>
                <h5>{client ? `$ ${client.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : ''}</h5>
                {client && client.nextPayDate &&
                    <h5 style={{ fontSize: '1.2rem' }}>Próxima fecha de pago: <span>{client ? moment(client.nextPayDate).utc().format('DD/MM/YYYY') : ''}</span></h5>
                }
                {client && parseInt(client.totalPoints) > 0 &&
                    <h5 style={{ fontSize: '1.2rem' }}>{client ? `Puntos GR: ${parseInt(client.totalPoints) * 10}` : ''}</h5>
                }
            </div>
            <div style={{ height: window.innerHeight / 2.5, overflowY: 'scroll', backgroundColor: 'white' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Movimiento</th>
                            <th>Monto</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {balance.map(i => (
                            <tr className="Table" key={i.id}>
                                <td>{moment(i.date).format('DD/MM/YYYY')}</td>
                                <td>{i.move}</td>
                                <td>{i.type === 'c' ? '+' : '-'}${i.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                <td>{ }${i.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}