import React, {useState, useEffect, useContext} from 'react';
import {httpGet, httpDelete, httpPost, httpPostw, httpGetOne, httpPut, httpGetImg} from '../../fetch';
import moment from 'moment';
import { EPERM } from 'constants';

function momentToString(date) {
    return moment(date).format('YYYY-MM-DD');
}

let sortBy = (function () {
    let toString = Object.prototype.toString,
        // default parser function
        parse = function (x) { return x; },
        // gets the item to be sorted
        getItem = function (x) {
          let isObject = x !== null && typeof x === "object";
          let isProp = isObject && this.prop in x;
          return this.parser(isProp ? x[this.prop] : x);
        };
  
    /**
     * Sorts an array of elements.
     *
     * @param {Array} array: the collection to sort
     * @param {Object} cfg: the configuration options
     * @property {String}   cfg.prop: property name (if it is an Array of objects)
     * @property {Boolean}  cfg.desc: determines whether the sort is descending
     * @property {Function} cfg.parser: function to parse the items to expected type
     * @return {Array}
     */
    return function sortby (array, cfg) {
      if (!(array instanceof Array && array.length)) return [];
      if (toString.call(cfg) !== "[object Object]") cfg = {};
      if (typeof cfg.parser !== "function") cfg.parser = parse;
      cfg.desc = !!cfg.desc ? -1 : 1;
      return array.sort(function (a, b) {
        a = getItem.call(cfg, a);
        b = getItem.call(cfg, b);
        return cfg.desc * (a < b ? -1 : +(a > b));
      });
    };
  
  }());

export default function SaleDetail(props) {
    
    let M;
    const [sales, setSales] = useState([]);
    const [sale, setSale] = useState();
    let sid='';
    const prope = useContext(props);
    
    useEffect(()=>{
        M = window.M; //Cargar Materialize desde CDN
        if(props !== ''){
            sid = props.value;
            // props.results ? results = props.results: results=results;
        }
        if(sid !== undefined)
            fetchSale(sid);
    },[props])

    const fetchSale = async (sid) => {
        const result = await httpGetOne('/api/sales',sid);
            setSale(result.data);
            const datos =[];

            result.data.items.map( i =>{
                const dato={
                    id: i.id,
                    quantity: i.quantity
                }
                
            datos.push(dato);
        });
    }

    return(
        <div>
            {/* {JSON.stringify(balance)} */}
            {/* <div className="center"> */}
                {/* <b>Adeudo Total: {client.totalAmount?`$${client.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`:''}</b> */}
            
            {/* </div>
            <div className="center">
                <h5><b>Venta<span>{sale?sale.saleNumber:''}</span></b></h5>
                <br />
                <h5>{sale?`$ ${sale.saleAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`:''}</h5>
                </div>
            <div style={{height:window.innerHeight/2.5,overflowY:'scroll'}}>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Movimiento</th>
                            <th>Monto</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {balance.map(i => (
                            <tr className="Table" key={i.id}>
                                <td>{momentToString(i.date)}</td>
                                <td>{i.move}</td>
                                <td>{i.type==='c'?'+':'-'}${i.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                <td>{}${i.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}
        </div>
    )
}