import React from 'react'

export default function AttributesList({name, onChange, state, list}) {
    function handleChange(name) {
        return function(event) {
            const target = event.target;

            let params = [];
            if(target.checked){
                if(state[name] && state[name].length > 0){
                    params = state[name]
                    params.push(event.target.value)
                }
                else{
                    params = [event.target.value]
                }
            } else{
                params = state[name]
                if(params.length = 1){
                    params = [];
                } else {
                const ind = params.indexOf(event.target.value);
                params = params.splice(ind,1)
                }
            }

            onChange({...state, [name]:params})
        }
    }   

    return (
        <React.Fragment>
            <div className="attrWrapper">{name}:</div>
        <div>
            {
                list.map(a => (
                    <label className="chContainer" key={a}>{a}
                        <input type="checkbox" value={a} onChange={handleChange(name)}></input>
                        <span className="checkmark"></span>
                    </label>
                    
                ))
            }
        </div>
        </React.Fragment>
    )
}