import React, {useState, useEffect} from 'react';
import {httpGet, httpPost} from '../fetch';
import moment from 'moment';
import Select from 'react-select';

export default function Logs({}){
    
    let M;
    const [isLoading, setLoading] = useState(true);
    const [logs, setLogs]= useState([]);
    const [filter, setFilter] = useState([]);
    const [count, setCount] = useState(0);
    const [busqueda, setBusqueda] = useState({value: 0, label: '... Todos los usuarios'});
    const itemsLista = [
        {value:0, label:"Todos los usuarios"},
        {value:1, label:"Cobranza"},
        {value:2, label:"Ventas"},
        {value:3, label:"Publicidad"},
        {value:4, label:"Fernando Juárez"},
        {value:5, label:"Ventas 2"},
        {value:6, label:"Gaby Romo"},
    ];
    
    useEffect(()=>{
        const userType = localStorage.getItem('userType');
        if(userType !== 'Admin'){
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }
        M = window.M; //Cargar Materialize desde CDN
        fetchLogs();
    },[]);

    async function handleBusquedaChange(e){
        if(e.value === 0){
            fetchLogs();
        } else {
            const result = await httpGet(`/api/logs?user=${e.label}`);
            setLogs(result.data);
            setBusqueda({value: e.value, label: e.label});
        }
    }

    const fetchLogs = async () => {
        setLoading(true)
        const result = await httpGet('/api/logs');
        if(result.data){
            setCount(result.data.length);
            setLoading(false)
        }
        setLogs(result.data);
    }

    const fetchFilterLogs = async () =>{
        setLoading(true)
        const result = await httpPost('/api/logs/between',{fromDate:moment(filter.weekinit).startOf('day'), toDate:moment(filter.weekend).endOf('day')});
        setLogs(result.data);
        if(result.data){
            setLoading(false)
        }
    }

    const handleWeekinitChange = (e) => {
        setFilter({...filter, weekinit: e.target.value });
    }

    const handleWeekendChange = (e) => {
        setFilter({...filter, weekend: e.target.value });
    }
    
    return(
    <div>
        
        <div className="container">
            <h4>Historial de Movimientos</h4>
            <br />
            <br />
            <div className="row">
                <div className="col m12">
                    <div className="input-field col m5 s12">
                        <input id="fromDate" type='date' value={filter.weekinit}
                            onChange={handleWeekinitChange}></input>
                        <label htmlFor="fromDate">Inicio</label>
                    </div>
                    <div className="input-field col m5 s12">
                        <input id="toDate" type='date' value={filter.weekend}
                            onChange={handleWeekendChange}></input>
                        <label htmlFor="toDate">Fin</label>
                    </div>
                    <div className="input-field col m2 s12 center">
                        <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchFilterLogs} isDisabled={isLoading}>Actualizar</a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="input-field col m5 s12">
                        <Select id="user"  options={ itemsLista } name="user"  isDisabled={isLoading}
                        value={busqueda} onChange={handleBusquedaChange} className="selecter lvl100 s12"/>
                    </div>
                </div>
            </div>
            {
            isLoading ?
            <h4>Cargando registros ...</h4>
            :
            
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Actividades</th>
                        <th>Codigo</th>
                        <th>Entidad</th>
                        <th>Usuario</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map(i => (
                        <tr>
                            <td>{moment(moment(i.date).utcOffset(-7)).format('DD/MM/YYYY LT')}</td>
                            <td className="capitalize">{i.title}</td>
                            <td className="capitalize">{i.code?i.code:''}</td>
                            <td className="capitalize">{i.entityId?i.entityId:''}</td>
                            <td>{i.user}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            }
            
            {count===1000 && !isLoading?(
                <div className="center"><br />* Se muestran los últimos 1000 registros, utilice el fíltro de búsqueda para mostrar mejores resultados.</div>
            ):''}
        </div>
    </div>
    );
}