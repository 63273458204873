import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { httpGet } from '../../fetch';
import xlsx from 'node-xlsx';

const ConsultaMovimientos = () => {
    let M;

    const [periodo, setPeriodo] = useState({ weekinit: moment.utc(moment().startOf('month')).format('YYYY-MM-DD'), weekend: moment().format('YYYY-MM-DD') });
    const [cards, setCards] = useState([]);

    function handleMedioChange(e) {
        setPeriodo({ ...periodo, medioId: e.value, medio: { value: e.value, label: e.label } });
    }

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        if (userType !== 'Admin') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/'
        }
        M = window.M; //Cargar Materialize desde CDN
        fetchCards();
    }, []);

    const handleWeekinitChange = (e) => {
        setPeriodo({ ...periodo, weekinit: e.target.value });
    }

    const handleWeekendChange = (e) => {
        setPeriodo({ ...periodo, weekend: e.target.value });
    }

    const fetchCards = async () => {
        const result = await httpGet('/api/cards/reporte');
        if (result.data) {
            const cards = result.data.map(c => ({ label: c.display, value: c.number }))
            setCards(cards);
        }
    }

    const saveReportFile = (reportName, byte) => {

        let blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;

        link.click();
    };

    const getReport = async () => {
        const result = await httpGet(`/api/reports/movements?medio=${periodo.medio.value}&init=${periodo.weekinit}&end=${periodo.weekend}`);

        let sheetData = [];
        const colsSheetData = ['Medio de Pago', 'Fecha', 'Movimiento', 'Monto'];
        let maxSignatory = 0;

        if (result.data && result.data.length > 0) {
            result.data.map(pay => {
                sheetData.push([
                    pay.payMethod ? pay.payMethod : '-',
                    pay.payDate ? moment.utc(pay.payDate).format("DD-MM-YYYY") : '-',
                    "Ingreso",
                    pay.amount ? pay.amount : 0,
                ]);
            });

            const options = { '!cols': [{ wch: 18 }, { wch: 15 }, { wch: 10 }, { wch: 15 }] };

            for (let i = 1; i <= maxSignatory; i++) {
                colsSheetData.push('Variante ' + i);
            }

            const dataset = []
            dataset.push(colsSheetData)
            sheetData.map(m => {
                dataset.push(m)
            });

            const buildData = xlsx.build([{ name: `Movimientos`, data: dataset }], options);
            saveReportFile(`Reporte ${periodo.medio && periodo.medio.label} - ${moment().utc().format('YYYYMMDD')}`, buildData);
        } else {
            alert("No se encontraron resultados")
        }
    }

    return (
        <div>
            <div className="container">
                <div className="col m6 s12"></div>
                <h4>Movimientos a Cuentas</h4>
            </div>
            <div className="container row">
                <div className="row">
                    <div className="col m12">
                        <div className="input-field col m6 s12">
                            <input id="fromDate" type='date' value={periodo.weekinit}
                                onChange={handleWeekinitChange}></input>
                            <label htmlFor="fromDate">Inicio</label>
                        </div>
                        <div className="input-field col m6 s12">
                            <input id="toDate" type='date' value={periodo.weekend}
                                onChange={handleWeekendChange}></input>
                            <label htmlFor="toDate">Fin</label>
                        </div>
                    </div>
                </div>

                <div className="input-fields col s6 lvl100">
                    <label for="medio">Medio de Pago</label>
                    <Select id="medio" options={cards} name="medio"
                        value={periodo.medio} onChange={handleMedioChange} className="selecter capitalize s6 lvl100" />
                </div>
                <div className="input-fields col s6 lvl100">
                    <br />
                    <br />
                    <div className="center"><button className="btn light-goldeneye" onClick={getReport}>Descargar</button></div>
                </div>

            </div>
        </div>
    );
};

export default ConsultaMovimientos;