import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { httpPost } from '../fetch';
import Reporte from './dashboard_components/reporte';
import ReporteInventario from './dashboard_components/ReporteInventario';
import ReporteClientes from './dashboard_components/ReporteClientes';
import ReporteGenerico from './dashboard_components/ReporteGenerico';



export default function Buys({ }) {

    let M;
    const [sales, setSales] = useState({ weekinit: moment.utc(moment().subtract(1, 'week')).format('YYYY-MM-DD'), weekend: moment().format('YYYY-MM-DD') });
    const [clients, setClients] = useState({});
    const [salded, setSalded] = useState({});
    const [income, setIncome] = useState({});
    const [points, setPoints] = useState({});
    const [pays, setPays] = useState({});
    const [reportByCard, setReportByCard] = useState({})
    const [reportByUser, setReportByUser] = useState({})
    const [cargado, setCargado] = useState(false)

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        if (userType !== 'Admin') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/'
        }
        M = window.M; //Cargar Materialize desde CDN
        fetchActualData();
        fetchData();
        getReportByCard();
        getReportByUser();
    }, []);

    const fetchActualData = async () => {
        const week = await fetchSales('week');
        const month = await fetchSales('month');
        const daily = await fetchSales('day');
        setSales({
            ...sales,
            weekIncome: week ? week[0] : 0, weekSales: week ? week[1] : 0,
            monthIncome: month ? month[0] : 0, monthSales: month ? month[1] : 0,
            dailyIncome: daily ? daily[0] : 0, dailySales: daily ? daily[1] : 0
        });
    }

    const getReportByCard = async () => {
        const corteDate = new Date();
        const result = await httpPost('/api/pays/reportByCard', { corteDate });
        setReportByCard(result.data);
    }

    const getReportByUser = async () => {
        const corteDate = new Date();
        const result = await httpPost('/api/pays/reportByUser', { corteDate });
        setReportByUser(result.data);

    }

    const fetchSales = async (period) => {
        const initDate = moment().startOf(period);
        const endDate = moment().endOf(period);
        const result = await httpPost('/api/pays/between', { fromDate: initDate, toDate: endDate });
        let totalIncome = 0;
        let totalSales = 0;
        if (result.data.length > 0) {
            result.data.map(item => {
                totalIncome += item.amount;
                totalSales++;
            });
            const totals = [];
            totals.push(totalIncome);
            totals.push(totalSales);
            return totals;
        }
    }
    const fetchData = async () => {
        const dayNumbers = moment(moment(sales.weekend)).
            diff(moment(sales.weekinit), 'days');
        const newData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consulta = moment(sales.weekinit).add(i, 'days');
            const result = await httpPost('/api/sales/between', { fromDate: moment.utc(consulta), toDate: moment.utc(consulta).endOf('day') });
            let ventaDelDia = 0;
            if (result.data.length > 0)
                result.data.map(item => {
                    ventaDelDia += item.saleAmount;
                });
            newData.push({ name: consulta.format('DD/MM'), Venta: ventaDelDia });
        }
        setIncome({ ...income, data: newData });

        fetchClientData();
        fetchPays();
        fetchSaldedData();
        fetchPointsData();
    }

    const fetchPointsData = async () => {
        const dayNumbers = moment(moment(sales.weekend)).
            diff(moment(sales.weekinit), 'days');
        const newPointsData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consulta = moment(sales.weekinit).add(i, 'days');
            const result = await httpPost('/api/points/between/', { fromDate: moment.utc(consulta), toDate: moment.utc(consulta).endOf('day') });
            let registros = 0;
            if (result.data.length > 0)
                result.data.map(item => {
                    registros += item.redemPoints;
                });
            newPointsData.push({ name: consulta.format('DD/MM'), Puntos: registros });
        }
        setPoints({ ...points, clientData: newPointsData });
    }

    const fetchClientData = async () => {
        const dayNumbers = moment(moment(sales.weekend)).
            diff(moment(sales.weekinit), 'days');
        const newClientData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consulta = moment(sales.weekinit).add(i, 'days');
            const result = await httpPost('/api/salded/reactive', { fromDate: moment.utc(consulta), toDate: moment.utc(consulta).endOf('day') });
            let registros = 0;
            if (result.data.length > 0)
                result.data.map(item => {
                    registros++;
                });
            newClientData.push({ name: consulta.format('DD/MM'), Clientes: registros });
        }
        setClients({ ...clients, clientData: newClientData });
    }

    const fetchSaldedData = async () => {
        const dayNumbers = moment(moment(sales.weekend)).
            diff(moment(sales.weekinit), 'days');
        const newClientData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consulta = moment(sales.weekinit).add(i, 'days');
            const result = await httpPost('/api/salded/between', { fromDate: moment.utc(consulta), toDate: moment.utc(consulta).endOf('day') });
            let registros = 0;
            if (result.data.length > 0)
                result.data.map(item => {
                    registros++;
                });
            newClientData.push({ name: consulta.format('DD/MM'), Clientes: registros });
        }
        setSalded({ ...salded, clientData: newClientData });
    }

    const fetchPays = async () => {
        const dayNumbers = moment(moment(sales.weekend)).
            diff(moment(sales.weekinit), 'days');
        const newPayData = [];
        for (let i = 0; i <= dayNumbers; i++) {
            const consulta = moment(sales.weekinit).add(i, 'days');
            const result = await httpPost('/api/pays/between', { fromDate: moment.utc(consulta), toDate: moment.utc(consulta).endOf('day') });
            let pagosDelDia = 0;
            if (result.data.length > 0)
                result.data.map(item => {
                    pagosDelDia += item.amount;
                });
            newPayData.push({ name: consulta.format('DD/MM'), Pagos: pagosDelDia });
        }
        setPays({ ...pays, data: newPayData });
    }

    const handleWeekinitChange = (e) => {
        console.log(e.target.value);
        setSales({ ...sales, weekinit: e.target.value });

    }

    const handleWeekendChange = (e) => {
        console.log(e.target.value);
        setSales({ ...sales, weekend: e.target.value });

    }

    return (
        <div>
            <div className="container">
                
                <div className="col m6 s12">
                    <div>
                        <div style={{ backgroundColor: '#ececec', padding: '10%' }}>
                            <h4>Resumen</h4>
                            <div className="txtGreen"><h4 style={{ color: 'black', marginBottom: '0', marginTop: '0' }}>Hoy:</h4> ${sales.dailyIncome ? sales.dailyIncome.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''} <span className="txtBlack"> (<span className="txtBlue">{sales.dailySales}</span> Pagos)</span></div>
                            <div className="txtGreen"><h4 style={{ color: 'black', marginBottom: '0', marginTop: '0' }}>Semana:</h4> $ {sales.weekIncome ? sales.weekIncome.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''} <span className="txtBlack"> (<span className="txtBlue">{sales.weekSales}</span> Pagos)</span></div>
                            <div className="txtGreen"><h4 style={{ color: 'black', marginBottom: '0', marginTop: '0' }}>Mes:</h4> $ {sales.monthIncome ? sales.monthIncome.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''} <span className="txtBlack"> (<span className="txtBlue">{sales.monthSales}</span> Pagos)</span></div>
                        </div>

                        <Reporte report={reportByCard} setReport={setReportByCard} title="Reporte por tarjeta"></Reporte>
                        <Reporte report={reportByUser} setReport={setReportByUser} title="Reporte por usuario"></Reporte>

                        <br />

                        <h4>Reporte</h4>
                        {sales.weekinit ? (
                            <div className="row">
                                <div className="col m12">
                                    <div className="input-field col m6 s12">
                                        <input id="fromDate" type='date' value={sales.weekinit}
                                            onChange={handleWeekinitChange}></input>
                                        <label htmlFor="fromDate">Inicio</label>
                                    </div>
                                    <div className="input-field col m6 s12">
                                        <input id="toDate" type='date' value={sales.weekend}
                                            onChange={handleWeekendChange}></input>
                                        <label htmlFor="toDate">Fin</label>
                                    </div>
                                </div>
                            </div>) : ''}
                        <div className="center"><button className="btn light-goldeneye" onClick={fetchData}>Actualizar</button></div>
                    </div>

                    <h4>Ingresos</h4>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={pays.data}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: "currency", currency: "USD" }).format(value)} />
                                <Legend />
                                <Bar dataKey="Pagos" stackId="c" fill="#6e7dbd" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <br />
                    <br />
                    <h4>Ventas</h4>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={income.data}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: "currency", currency: "USD" }).format(value)} />
                                <Legend />
                                <Bar dataKey="Venta" stackId="a" fill="#6e7dbd" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <br />
                    <br />
                    <h4>Registros</h4>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={clients.clientData}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Clientes" stackId="b" fill="#6e7dbd" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <br />
                    <br />
                    <h4>Cuentas Finiquitadas</h4>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={salded.clientData}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Clientes" stackId="b" fill="#6e7dbd" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <br />
                    <br />
                    <h4>Puntos Redimidos</h4>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={points.clientData}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Puntos" stackId="b" fill="#6e7dbd" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col m6 s12">
                    <ReporteGenerico titulo='Reporte de ingresos anualizados' href='/anualizados'></ReporteGenerico>
                </div>
                <div className="col m6 s12">
                    <ReporteGenerico titulo='Reporte de ventas anualizadas' href='/anualizadosVenta'></ReporteGenerico>
                </div>
                <div className="col m6 s12">
                    <ReporteGenerico titulo='Reporte de movimientos a cuentas' href='/movimientos'></ReporteGenerico>
                </div>
                <div className="col m6 s12">
                    <ReporteInventario></ReporteInventario>
                </div>
                <div className="col m6 s12">
                    <ReporteClientes></ReporteClientes>
                </div>
                {/* {JSON.stringify(sales)} */}
            </div>
        </div>
    );
}