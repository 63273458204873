import React, { useState, useEffect } from 'react';
import { httpGet, httpDelete, httpPost, httpPostw, httpGetOne, httpPut, httpGetImg } from '../fetch';
import moment from 'moment';
import Balance from '../modda_modules/Balance';
import Paginator from 'react-hooks-paginator';

function momentToString(date) {
    return moment(date).format('YYYY-MM-DD');
}

export default function Buys({ }) {

    function admin() {
        if (localStorage.userType === 'Admin') return true;
        else return false;
    }

    function pub() {
        if (localStorage.userType === 'Publicidad') return true;
        else return false;
    }

    let M;
    const [buy, setBuy] = useState({});
    const [buys, setBuys] = useState([]);
    const [filter, setFilter] = useState([]);
    const [client, setClient] = useState([]);
    const pageLimit = 5;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        const userType = localStorage.getItem('userType');

        if (userType !== 'Admin' && userType !== 'Publicidad' && userType !== 'Ventas') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchTodayBuys();
    }, []);

    useEffect(() => {
        // const userType = localStorage.getItem('userType');
        // if(userType !== 'Admin'&& userType !== 'Publicidad' && userType !== 'Cobranza'){
        //     window.alert("Algo salió mal, no deberías estar aquí...");
        //     window.location.href = '/preventas'
        // }
        setCurrentData(buys.slice(offset, offset + pageLimit));
    }, [offset, buys]);

    const fetchBuys = async () => {
        const result = await httpGet('/api/sales');
        setBuys(result.data);
    }

    const handleDelete = async (id) => {
        const userEmail = localStorage.getItem('user');
        await httpDelete(`/api/sales/delete/${id}`, id, userEmail)
        fetchTodayBuys()
    }

    const fetchTodayBuys = async () => {
        const result = await httpGet('/api/sales/today');
        setBuys(result.data);
    }
    const fetchFilterBuys = async () => {
        const result = await httpPost('/api/sales/between', { fromDate: moment(filter.weekinit).startOf('day'), toDate: moment(filter.weekend).endOf('day') });
        setBuys(result.data);
    }

    const handleWeekinitChange = (e) => {
        console.log(e.target.value);
        setFilter({ ...filter, weekinit: e.target.value });
    }

    const handleWeekendChange = (e) => {
        console.log(e.target.value);
        setFilter({ ...filter, weekend: e.target.value });
    }

    const balanceClient = async (id) => {
        setClient({ id: id });
    }

    return (
        <div>
            <div className="container">
                <h4>Ventas</h4>
                <a href="../venta" className="btn light-goldeneye">Agregar +</a>
                <span>&nbsp;&nbsp;&nbsp;</span>
                {!pub() ? (<a className="btn waves light-goldeneye" style={{ marginLeft: '5px' }} onClick={fetchBuys}>Todas</a>) : ''}
                {!pub() ? (<a className="btn waves light-goldeneye" style={{ marginLeft: '5px' }} onClick={fetchTodayBuys}>Hoy</a>) : ''}
                <br /><br />
                {!pub() ? (<div className="row">
                    <div className="col m12">
                        <div className="input-field col m5 s12">
                            <input id="fromDate" type='date' value={filter.weekinit}
                                onChange={handleWeekinitChange}></input>
                            <label htmlFor="fromDate">Inicio</label>
                        </div>
                        <div className="input-field col m5 s12">
                            <input id="toDate" type='date' value={filter.weekend}
                                onChange={handleWeekendChange}></input>
                            <label htmlFor="toDate">Fin</label>
                        </div>
                        <div className="input-field col m2 s12 center">
                            <a className="btn waves light-goldeneye" style={{ marginLeft: '5px' }} onClick={fetchFilterBuys}>Actualizar</a>
                        </div>
                    </div>
                </div>) : ''}
                {!pub() ? (
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th>Tipo Pago</th>
                                <th>Cliente</th>
                                <th className="pc-only-table-cell">Número</th>
                                {admin() && <th>Eliminar</th> }
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map(i => (
                                <tr key={i.id}>
                                    <td>
                                        <a className="modal-trigger" href="#modal2" target="_blank;" onClick={e => balanceClient(i.client._id)}>
                                            <img className="tumbnail" src={i.client.picUrl ? i.client.picUrl : '../img/nopic.png'}></img>
                                        </a>
                                    </td>
                                    <td>{moment(i.date).utcOffset(-7).format('DD/MM/YYYY')}</td>
                                    <td>${i.saleAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    <td className="capitalize">{i.payment === "credito" ? 'Crédito' : i.payment}</td>
                                    <td>{i.client.name}</td>
                                    <td className="pc-only-table-cell">{i.saleNumber}</td>
                                    {admin() && 
                                        <td>
                                            <button className="waves-effect btn light-goldeneye modal-trigger"
                                                onClick={(e) => { if (window.confirm('¿Estás seguro que deseas eliminar esta venta?')) handleDelete(i.id) }}
                                                href="#" style={{ marginRight: '5px' }}>Eliminar</button>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>

                ) : ''}
                {buys && buys.length > 5 && !pub() ?
                    <Paginator
                        totalRecords={buys.length}
                        pageLimit={pageLimit}
                        pageNeighbours={2}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageLinkClass="light-goldeneye"
                    /> : ''}
            </div>

            <div id="modal2" className="modal modal2 modal-fixed-footer">
                <div className="modal-content gr_pattern">
                    <Balance value={client.id}></Balance>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect btn light-goldeneye">Cerrar</button>
                </div>
            </div>
        </div>
    );
}