import React, {useState, useEffect} from 'react';
import {httpGet, httpPost, httpDelete, httpPut} from '../fetch';
import moment from 'moment';
import { func } from 'prop-types';

export default function Gastos({}){

    function admin(){
        if(localStorage.userType==='Admin') return true;
        else return false;
    }
    
    let M;
    const [expenses, setExpenses]= useState([]);
    const [expense, setExpense]= useState([]);
    const [filter, setFilter] = useState([]);
    const [count, setCount] = useState(0);
    
    useEffect(()=>{
        const userType = localStorage.getItem('userType');
        if(userType !== 'Admin'){
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchExpenses();
    },[]);

    const fetchExpenses = async () => {
        const result = await httpGet('/api/expenses');
        if(result.data)
            setCount(result.data.length);
        setExpenses(result.data);
    }

    const fetchFilterExpenses = async () =>{
        const result = await httpPost('/api/expenses/between',{fromDate:moment(filter.weekinit).startOf('day'), toDate:moment(filter.weekend).endOf('day')});
        setExpenses(result.data);
    }

    const handleWeekinitChange = (e) => {
        console.log(e.target.value);
        setFilter({...filter, weekinit: e.target.value });
    }

    const handleWeekendChange = (e) => {
        console.log(e.target.value);
        setFilter({...filter, weekend: e.target.value });
    }

    /* Agregar Gastos */

    function handleExpenseChange(e){
        setExpense({...expense, title: e.target.value});
    }

    function handleAmountChange(e){
        setExpense({...expense, amount: e.target.value});
    }
    function handleDateChange(e){
        setExpense({...expense, date: e.target.value});
    }

    async function saveExpense(){
        console.log("Guardando gasto...");
        
        if(expense.id){
            const result = await httpPut('/api/expenses/'+expense.id,expense, localStorage.getItem('user'));
            console.log(result.data);
        } else {
            const result = await httpPost('/api/expenses',expense, localStorage.getItem('user'));
            console.log(result.data);
        }

        //Cerrar el Modal Manualmente
        const M = window.M; 
        var elem = document.getElementById('modal1');
        var instance = M.Modal.getInstance(elem);
        instance.close();

        fetchExpenses();
        setExpense({date: '', title: '', amount: 0});
    }

    async function deleteExpense(i){
        const result = await httpDelete('/api/expenses/desactivate', i._id, localStorage.getItem('user'));
        console.log(result.data);

        fetchExpenses();
    }

    async function editExpense(id){
        const result = await httpGet('/api/expenses/'+id);
        console.log(result.data);
        setExpense(result.data);

        //Ajuste Materialize
        const M = window.M; 
        M.updateTextFields();
    }

    
    return(
    <div>
        <div className="container">
            <h4>Historial de Gastos</h4>
            <a href="#modal1" className="btn light-goldeneye modal-trigger">Agregar +</a>
            <br />
            <br />
            <div className="row">
                <div className="col m12">
                    <div className="input-field col m5 s12">
                        <input id="fromDate" type='date' value={filter.weekinit}
                            onChange={handleWeekinitChange}></input>
                        <label htmlFor="fromDate">Inicio</label>
                    </div>
                    <div className="input-field col m5 s12">
                        <input id="toDate" type='date' value={filter.weekend}
                            onChange={handleWeekendChange}></input>
                        <label htmlFor="toDate">Fin</label>
                    </div>
                    <div className="input-field col m2 s12 center">
                        <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchFilterExpenses}>Actualizar</a>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Descripción</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    {expenses.map(i => (
                        <tr key={i._id}>
                            <td>{moment(moment(i.date).add(7,'hours')).format('DD/MM/YYYY')}</td>
                            <td className="capitalize">{i.title}</td>
                            <td>${i.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            <td>
                                <button className="btn light-goldeneye modal-trigger"
                                        value={i._id}
                                        href="#modal1"
                                        onClick={e =>{editExpense(i._id)}}
                                        style={{margin: '4px'}}>
                                    <i className="material-icons">edit</i>
                                </button>
                                <button className="btn light-goldeneye"  
                                        onClick={(e)=>{
                                            if (window.confirm('¿Estás seguro que deseas eliminar este registro?')) 
                                                deleteExpense(i)
                                            }}
                                        style={{margin: '4px'}}>
                                    <i className="material-icons">delete</i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {count==1000?(
                <div className="center"><br />* Se muestran los últimos 1000 registros, utilice el fíltro de búsqueda para mostrar mejores resultados.</div>
            ):''}
        </div>

        <div id="modal1" className="modal modal2">
            <div className="modal-content">
                 <div className="modal-header">
                    <h4>Nuevo Gasto</h4>
                    <div style={{textAlign:'right', position:'absolute', top:'5px', right:'5px'}}>
                        <button className="modal-close btn-floating light-goldeneye">X</button>
                    </div>
                </div>
                <br />
            
                <div className="input-field col s12">
                    <input id="toDate" type='date' value={moment(moment(expense.date).add(7,'hours')).format('YYYY-MM-DD')}
                        onChange={handleDateChange}></input>
                    <label htmlFor="toDate">Fecha de gasto</label>
                </div>
                <br />
                <div className="input-field col s12">
                    <input id="title" type="text" name="title" value={expense.title} 
                        onChange={handleExpenseChange}></input>
                    <label htmlFor="title">Gasto<span style={{color:'red'}}>&nbsp;*</span></label>
                </div>

                <div className="input-field col s12">
                    <i className="material-icons prefix">attach_money</i>
                    <input id="amount" type="number" step="0.01" name="amount" value={expense.amount} 
                        onChange={handleAmountChange}></input>
                    <label htmlFor="amount">Cantidad Pagada<span style={{color:'red'}}>&nbsp;*</span></label>
                </div>

            </div>
            
            <div className="modal-footer" style={{position:'absolute',bottom:'0'}}>
                <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                <button className="waves-effect btn light-goldeneye" onClick={e =>
                    window.confirm("¿Desea continuar con el registro?") &&
                    saveExpense(e)
                } style={{float:'right'}}>Aceptar</button>
            </div>
        </div>
    </div>
    );
}