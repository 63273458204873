import React, {useState, useEffect} from 'react';
import {httpGet, httpDelete, httpPost, httpPostw, httpGetOne, httpPut, httpGetImg} from '../fetch';
import moment from 'moment';
import Select from 'react-select';
import Balance from './Balance';

function momentToString(date) {
    return moment(date).format('YYYY-MM-DD');
}

const clientLista = [];

export default function Notifications({}){

    function admin(){
        if(localStorage.userType==='Admin') return true;
        else return false;
    }

    function cob(){
        if(localStorage.userType==='Cobranza') return true;
        else return false;
    }

    
    let M;
    const [metodosPago, setMetodosPago] = useState([])
    const [notification, setNotification]= useState({});
    const [notifications, setNotifications]= useState([]);
    const [client, setClient] = useState({});
    const [pay, setPay]= useState({});
    const [notificacionesLista, setNotificacionesLista] = useState({value:0, label:"... Buscar"});
    // const [client, setClient] = useState({label: 'Seleccionar Cliente', value:'0'});
    const busqueda = {value: 0, label: '... Buscar Notificación'};
    const [count, setCount] = useState(0);
    const [cliente, setCliente] = useState("");
    
    useEffect(()=>{
        const userType = localStorage.getItem('userType');
        if(userType !== 'Admin' && userType !== 'Cobranza'){
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchNotifications();
        fetchPayMethods();
    },[]);

    async function fetchPayMethods() {
        const result = await httpGet('/api/cards');
        let cards = [];
        result.data.map(c => {
            if (c.number !== 'ajuste') {
                cards.push({ value: c.number, label: c.display });
            }
        })
        setMetodosPago(cards)
    }

    const fetchNotifications = async () => {
        const result = await httpGet('/api/notifications');
        window.M.updateTextFields();
        console.log("DATA::::")
        console.log(result.data)
        setNotifications(result.data);
        const b = [];
        let contador = 0;

        if (result.data){
            result.data.map(c =>{
                contador ++;
                b.push({value:c.id, label: c.client.name});
            });
            setNotificacionesLista(b);
        }
        setCount(contador);
    }

    const fetchTodayNotifications = async () => {
        const result = await httpGet('/api/notifications/today');
        console.log(result.data)
        setNotifications(result.data);
    }

    const editNotification = async (i) => {
        const result = await httpGetOne('/api/notifications', i._id);
        const notif = result.data;
        setNotification(notif);
        
    }

    const addNewPay = async (i) => {
        const result = await httpGetOne('/api/clients', i.client.id);
        const client = result.data;
        console.log(i);
        setPay({...pay, amount: client.payMount, client: client});
    }

    const deleteNotification = async (item) => {
        console.log(item._id)
        // e.preventDefault();
        const result = await httpDelete('/api/notifications/delete', item._id, localStorage.getItem('user'));
        console.log(result.data)
        fetchNotifications();
    }

    const handleDateChange = (e) =>{
        console.log(notification.newDate);
        setNotification({...notification, newDate: e.target.value});
    }

    const handleJustChange = (e) =>{
        console.log(notification.justif);
        setNotification({...notification, justif: e.target.value});
    }

    const reprogramate = async () => {
        const inputNota = notification.justif;
        const data = {
            ...notification, 
            nota: `Reprogramación de pago del ${moment().format('DD/MM/YYYY')}: ${notification.justif}. - $USER`
        };
        const res = await httpPost('/api/pays/reprogramate', data, localStorage.getItem('user'));
        console.log("reprogramate :"+res);
        fetchNotifications();
    }

    function handlePayMethodChange(e){
        setPay({...pay, payMethod: e.value, payMethodLabel:{label: e.label, value: e.value}});
        console.log('Pay Method selected: '+ JSON.stringify(e.value));
        //selectClient(e.value);
    }

    function handleAmountChange(e){
        setPay({...pay, amount: e.target.value});
    }

    function handleAmountConfirmacionChange(e){
        setPay({...pay, amountConfirmacion: e.target.value});
    }

     async function closeSale(){

        const completePay = {};
        if(!pay.payMethod){
            alert("Seleccione el método de pago antes de continuar.");
            return;
        }
        if(!pay.amount || pay.amount <= 0) {
            alert("Introducir el monto del pago antes de continuar.");
            return;
        }
        if(pay.amount != pay.amountConfirmacion){
            alert("El monto del pago y su confirmación no coinciden");
            return;
        }

        console.log(pay);
        if(pay.amount){
            if(pay.amount > 0){
                completePay.amount=pay.amount;
                completePay.status = 'pagado';
                completePay.payDate = new Date();
                completePay.active = true;
                //TODO: Agregar el usuario que recibe el pago.
                completePay.verified = true;
                completePay.payMethod= pay.payMethod;
                completePay.client= pay.client.id;
                
                const result = await httpPost('/api/pays/verified', completePay, localStorage.getItem('user'));
                console.log(result);
                fetchNotifications();
                setCliente(pay.client.id)
            }
        }

        //Cerrar el Modal Manualmente
        const M = window.M; 
        var elem = document.getElementById('modal2');
        var instance = M.Modal.getInstance(elem);
        instance.close();

        var elem2 = document.getElementById('modal3');
        var instance2 = M.Modal.getInstance(elem2);
        instance2.open();
     }

     const handleBusquedaChange = async(e) =>{
        const result = await httpGetOne('/api/notifications',e.value);
        console.log(result.data);
        setNotifications([result.data]);
     }

    return(
    <div>
        <div className="container">
            <h4>Notificaciones</h4>
            <div className="row">
                <div className="col m6 s11">
                    <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchNotifications}>Todas</a>
                    <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchTodayNotifications}>Hoy</a>
                </div>
                <div className="col m6 s11">
                    <Select id="search"  options={ notificacionesLista } name="search" 
                            value={busqueda} onChange={handleBusquedaChange} className="selecter lvl100 s12"/>
                </div>
            </div>
            <div className="center" style={{zIndex:0}}>
                    <h4 style={{color:'black',marginBottom:'0',marginTop:'0'}}>Notificaciones por Atender: <span className="txtGreen">{count}</span> </h4>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Título</th>
                        <th className="pc-only-table-cell">Descripción</th>
                        <th>Cliente</th>
                        <th>Parcialidad</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {notifications.map(i => (
                        <tr className="Table" key={i._id}>
                            <td>{moment(i.date).utc().format('DD/MM/YYYY')}</td>
                            <td>{i.title}</td>
                            <td className="pc-only-table-cell">{i.description}</td>
                            <td>{i.client.name}</td>
                            <td>{i.client.payMount?`$ ${i.client.payMount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`:''}</td>
                            <td>
                                {/* <button className="btn light-goldeneye modal-trigger"
                                        value={i._id}
                                        href="#modal1"
                                        onClick={e =>{editNotification(i)}}
                                        style={{margin: '4px'}}>
                                    <i className="material-icons">edit</i>
                                </button> */}
                                <button className="btn light-goldeneye"  
                                        onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar esta notificación? Se re-agendará la fecha de pago.')) deleteNotification(i) } }
                                        style={{margin: '4px'}}>
                                    <i className="material-icons">delete</i>
                                </button>
                                <button className="btn light-goldeneye modal-trigger"
                                        value={i.client}
                                        onClick={e => addNewPay(i)}
                                        href="#modal2"
                                        style={{margin: '4px'}}>
                                    <i className="material-icons">attach_money</i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        
        </div>
        <div id="modal1" className="modal">
            <div className="modal-content">
                <h4>Reprogramar Fecha de Pago</h4>
                <br />
                {/* {JSON.stringify(notification)} */}
                <div className="input-field col s12">
                    <input id="newDate" type="date" name="newDate" value={notification.newDate} 
                        onChange={handleDateChange}></input>
                    <label htmlFor="newDate">Nueva fecha de pago.</label>
                </div>
                <div className="input-field col s12">
                    <input id="justif" type="text" name="justif" value={notification.justif} 
                        onChange={handleJustChange}></input>
                    <label htmlFor="justif">Justificación</label>
                    <span class="helper-text" data-error="wrong" data-success="right">Esta reprogramación no afectará la calificación del cliente.</span>
                </div>
            </div>
            <div className="modal-footer" style={{position:'absolute',bottom:'0'}}>
                <button className="modal-close waves-effect btn light-goldeneye" onClick={e =>
                    window.confirm("¿Desea reprogramar el pago?") &&
                    reprogramate(e)
                }>Aceptar</button>
            </div>
        </div>


        <div id="modal2" className="modal modal2">
            <div className="modal-content">
                 <div className="modal-header">
                    <h4>Nuevo Pago</h4>
                    <div style={{textAlign:'right', position:'absolute', top:'5px', right:'5px'}}>
                        <button className="modal-close btn-floating light-goldeneye">X</button>
                    </div>
                </div>
                <br />
                {/* <Select id="clientId" placeholder="Seleccionar Cliente" options={ clientLista } name="clientId" 
                            value={client} onChange={handleChange} className="selecter m6 s12"/>
                <br /> */}
                <Select id="payMethod" placeholder="Seleccionar Método de pago" options={ metodosPago } name="payMethod" 
                                        value={pay.payMethodLabel} onChange={handlePayMethodChange} className="selecter m6 s12"/>
                <br />

                <label htmlFor="amount">Cantidad Pagada <span style={{color:'red'}}>&nbsp;*</span></label>
                <div className="input-field col s12">
                    <i className="material-icons prefix">attach_money</i>
                    <input id="amount" type="number" step="0.01" name="amount" value={pay.amount} 
                        onChange={handleAmountChange}></input>
                </div>

                <label for="payAmountConfirmacion">Vuelva a intoducir la cantidad. <span style={{color:'red'}}>&nbsp;*</span></label>
                <div className="input-field col s12">
                    <i className="material-icons prefix">attach_money</i>
                    <input className="active" id="payAmountConfirmacion" name="payAmountConfirmacion" type="number" step="0.01" name="payAmountConfirmacion" 
                        value={pay.amountConfirmacion} 
                        onChange={handleAmountConfirmacionChange}>
                    </input>
                </div>
            </div>
            <div className="modal-footer" style={{position:'absolute',bottom:'0'}}>
            <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                <button className="waves-effect btn light-goldeneye" onClick={e =>
                    window.confirm("¿Desea continuar con el pago?") &&
                    closeSale(e)
                } style={{float:'right'}}>Aceptar</button>
            </div>
        </div>


        <div id="modal3" className="modal modal2 modal-fixed-footer">
            <div className="modal-content gr_pattern">
                {cliente && cliente !== "" &&
                    <Balance value={cliente}></Balance>
                }
            </div>
            <div className="modal-footer">
                <button className="modal-close waves-effect btn light-goldeneye">Cerrar</button>
            </div>
        </div>

    </div>
    );
}