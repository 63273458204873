import React,  {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios'
import PrivateRoute from './PrivateRoute';

//NAVIGATION ITEMS
import Login from './modda_modules/Login';
import Clients from './modda_modules/Clients';
import Sales from './modda_modules/Sales';
import CloseSales from './modda_modules/CloseSales';
import Stock from './modda_modules/Stock';
import Pays from './modda_modules/Pays';
import Balance from './modda_modules/Balance';
import Buys from './modda_modules/Buys';
import Notification from './modda_modules/Notifications';
import Dashboard from './modda_modules/Dashboard';
import Presales from './modda_modules/Presales';
import Logs from './modda_modules/Logs';
import Gastos from './modda_modules/Gastos';
import crimg from "./modda_modules/components/CropImage";
import InvetoryConfig from "./modda_modules/InventoryConfig";
import CardsConfig from "./modda_modules/Cards";
import Anualizados from "./modda_modules/dashboard_components/ConsultaAnual";
import AnualizadosVenta from "./modda_modules/dashboard_components/ConsultaAnualVenta";
import Movimientos from "./modda_modules/dashboard_components/ConsultaMovimientos";
import Promociones from "./modda_modules/Promociones";

import SaleDetail from './modda_modules/components/SaleDetail';

export default function AppRouter(props) {
  let M;

  useEffect(() => {
      M = window.M; //Cargar Materialize desde CDN
      var elems = document.querySelectorAll('.sidenav');
      var ddown = document.querySelector(".dropdown-trigger");
      M.Sidenav.init(elems, []);
      M.Dropdown.init(ddown, []);

      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if(error && error.response && error.response.data === 'TokenExpiredError') {
          console.log('token expirado');
          localStorage.removeItem('token');
          window.location.href = '/login'
        }
        return Promise.reject(error);
      });

  }, [])

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem('userType');
    window.location.href = '/login'
  }

  function isSignedUp() {
    const token = localStorage.getItem('token');
    if(token) {
        return true;
    }
    return false;
  }

  function toLogs(){
    window.location.href = '/historial'
  }

  function toGastos(){
    window.location.href = '/gastos'
  }

  function toConfig(){
    window.location.href = '/configuracion'
  }

  function toCards(){
    window.location.href = '/tarjetas'
  }

  function toPromos(){
    window.location.href = '/promociones'
  }

  function toInventario(){
    window.location.href = '/inventario'
  }

  return (
    <Router>
      <div className={!isSignedUp() ? 'backinblack' : null}>
      <ul id="dropdown1" className="dropdown-content bigddown">
        <li className="dataOnly"><span>Usuario: {localStorage.getItem('user')}</span></li>
        <li className="dataOnly"><span>{localStorage.getItem('email')}</span></li>
        <li className="divider"></li>
        {localStorage.getItem('userType')==='Almacen'?(<li><a href="#!" onClick={toInventario}>Inventario</a></li>):''}
        {localStorage.getItem('userType')==='Admin'?(<li><a href="#!" onClick={toLogs}>Historial</a></li>):''}
        {localStorage.getItem('userType')==='Admin'?(<li><a href="#!" onClick={toPromos}>Promociones</a></li>):''}
        {localStorage.getItem('userType')==='Admin'?(<li><a href="#!" onClick={toGastos}>Gastos</a></li>):''}
        {localStorage.getItem('user')==='Developer'?(<li><a href="#!" onClick={toConfig}>Configuracion</a></li>):''}
        {localStorage.getItem('user')==='Developer'?(<li><a href="#!" onClick={toCards}>Tarjetas</a></li>):''}
        <li><a href="#!" onClick={logout}>Cerrar Sesión</a></li>
      </ul>
      {isSignedUp()?(<nav className="dark-goldeneye">
            <div className="container">
                <a className="brand-logo pc-only" href="/"><img src="../img/grcollectionsm.png" alt="GR Collection" 
                      style={{marginTop:'3%'}}></img></a>
                <a className="brand-logo movil-only" href="/"><img src="../img/grmainmob.png" alt="GR Collection" 
                      style={{marginTop:'10%', maxHeight:'6vh'}}></img></a>
                  
                <ul id="nav-mobile" className="right hide-on-med-and-down">
                  {localStorage.getItem('userType')==='Admin'?(<li><Link to="/tablero">Resumen</Link></li>):''}
                  {localStorage.getItem('userType')==='Admin'
                 ||localStorage.getItem('userType')==='Publicidad'
                 ||localStorage.getItem('userType')==='Ventas'
                 ||localStorage.getItem('userType')==='Almacen'
                 ||localStorage.getItem('userType')==='Cobranza'?(<li><Link to="/clientes/">Clientes</Link></li>):''}
                  {localStorage.getItem('userType')==='Admin'
                 ||localStorage.getItem('userType')==='Ventas'
                 ||localStorage.getItem('userType')==='Publicidad'?(<li><Link to="/ventas/">Ventas</Link></li>):''}
                  {localStorage.getItem('userType')==='Ventas'
                 ||localStorage.getItem('userType')==='Admin'
                 ||localStorage.getItem('userType')==='Cobranza'?(<li><Link to="/pagos/">Pagos</Link></li>):''}
                  {localStorage.getItem('userType')==='Admin' 
                 || localStorage.getItem('userType')==='Publicidad'
                 || localStorage.getItem('userType')==='Ventas'
                 || localStorage.getItem('userType')==='Almacen'
                 || localStorage.getItem('userType')==='Cobranza'?(<li><Link to="/inventario/">Inventario</Link></li>):''}
                  {localStorage.getItem('userType')==='Admin'
                 ||localStorage.getItem('userType')==='Cobranza'?(<li><Link to="/notificaciones/">Notificaciones</Link></li>):''}
                  <li><a className="dropdown-trigger" href="#!" data-target="dropdown1"><i className="material-icons userinfo">view_comfy</i></a></li>
                </ul>
                  
            </div>
            <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
        </nav>):''}
        {isSignedUp()?(
        <ul id="slide-out" className="sidenav">
          <li><div className="user-view gr-user">
            <div className="background gr-movil">
            </div>
              <a href="#name"><span className="white-text name">{localStorage.getItem('user')}</span></a>
              <a href="#email"><span className="white-text email">{localStorage.getItem('email')}</span></a>
            </div>
          </li>
          {localStorage.getItem('userType')==='Admin'
         ||localStorage.getItem('userType')==='Publicidad'
         ||localStorage.getItem('userType')==='Ventas'
         ||localStorage.getItem('userType')==='Almacen'
         ||localStorage.getItem('userType')==='Cobranza'?(<li><a className="waves-effect" href="/clientes"><i className="material-icons">face</i>Clientes</a></li>):''}
          {localStorage.getItem('userType')==='Ventas'
         ||localStorage.getItem('userType')==='Admin'
         ||localStorage.getItem('userType')==='Cobranza'?(<li><a className="waves-effect" href="/pagos"><i className="material-icons">monetization_on</i>Pagos</a></li>):''}
          {localStorage.getItem('userType')==='Admin'
         ||localStorage.getItem('userType')==='Ventas'
         ||localStorage.getItem('userType')==='Publicidad'?(<li><a className="waves-effect" href="/ventas"><i className="material-icons">shopping_cart</i>Ventas</a></li>):''}
          {localStorage.getItem('userType')==='Admin'
         ||localStorage.getItem('userType')==='Publicidad'
         ||localStorage.getItem('userType')==='Ventas'
         ||localStorage.getItem('userType')==='Almacen'
         ||localStorage.getItem('userType')==='Cobranza'?(<li><a className="waves-effect" href="/inventario"><i className="material-icons">assignment</i>Inventario</a></li>):''}
         {localStorage.getItem('userType')==='Admin'?(<li><a className="waves-effect" href="/promociones"><i className="material-icons">record_voice_over</i>Promociones</a></li>):''}
          {localStorage.getItem('userType')==='Admin'
         ||localStorage.getItem('userType')==='Cobranza'?(<li><a className="waves-effect" href="/notificaciones"><i className="material-icons">verified_user</i>Notificaciones</a></li>):''}
          {localStorage.getItem('userType')==='Admin'?(<li><a className="waves-effect" href="/tablero"><i className="material-icons">equalizer</i>Resumen</a></li>):''}
          {localStorage.getItem('userType')==='Admin'?(<li><a className="waves-effect" href="/historial"><i className="material-icons">history</i>Historial</a></li>):''}
          <br />
          <br />
              <div className="center">
                <button className="btn light-goldeneye" onClick={logout} style={{marginTop:'-8vh'}}>Cerrar Sesión</button>
              </div>
        </ul>
        
        ):''}
        
        <br/>
        <br/>
        
        <Route path="/login" exact component={Login} />
        <PrivateRoute exact path="/" component={Login} />   
        <PrivateRoute exact path="/clientes" component={Clients} />   
        <PrivateRoute exact path="/venta" component={Sales} />   
        <PrivateRoute exact path="/closeSales/:id" component={CloseSales} />   
        <PrivateRoute exact path="/inventario" component={Stock} />   
        <PrivateRoute exact path="/notificaciones" component={Notification} />   
        <PrivateRoute exact path="/pagos" component={Pays} />   
        <PrivateRoute exact path="/ventas" component={Buys} />   
        <PrivateRoute exact path="/tablero" component={Dashboard} /> 
        <PrivateRoute exact path="/historial" component={Logs} />
        <PrivateRoute exact path="/gastos" component={Gastos} />
        <PrivateRoute exact path="/configuracion" component={InvetoryConfig} />
        <PrivateRoute exact path="/tarjetas" component={CardsConfig} />
        <PrivateRoute exact path="/anualizados" component={Anualizados} />
        <PrivateRoute exact path="/anualizadosVenta" component={AnualizadosVenta} />
        <PrivateRoute exact path="/movimientos" component={Movimientos} />
        <PrivateRoute exact path="/promociones" component={Promociones} />
      </div>
    </Router>
  );
}