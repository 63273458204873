import React from 'react';

const ReporteGenerico = ({titulo, href}) => {
    return (
        <div className="ribbon">
            <h4>{titulo}</h4>
            <div className="left">
                <a className="btn light-goldeneye" href={href}>Consultar</a>
            </div>
        </div>
    );
};

export default ReporteGenerico;