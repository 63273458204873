import React, { useEffect, useMemo, useState } from 'react';
import { httpGet, httpGetOne } from '../fetch';
import usePost from './hooks/usePost';
import Select from 'react-select';
import Balance from './Balance';
import moment from 'moment';

const paymentInitialState = {
    payment: { label: '¿Tipo de Pago?', value: 0 },
    newPayday: { label: '¿Cambiar día de pago?', value: 0 },
    payMethod: { label: 'Seleccione Método de pago', value: '0' },
    usePoints: false,
}

export default function Sales() {
    const PAY_DAYS = [
        { value: 'lunes', label: 'Lunes' },
        { value: 'martes', label: 'Martes' },
        { value: 'miercoles', label: 'Miércoles' },
        { value: 'jueves', label: 'Jueves' },
        { value: 'viernes', label: 'Viernes' },
        { value: 'sabado', label: 'Sábado' },
        { value: 'domingo', label: 'Domingo' },
        { value: 'decenal', label: 'Decenal' },
        { value: 'quincenal', label: 'Quincenal' }
    ];
    const PAY_PERIODS = [
        { value: 'semanal', label: 'Semanal' },
        { value: 'decenal', label: 'Decenal' },
        { value: 'catorcenal', label: 'Catorcenal' },
        { value: 'quincenal', label: 'Quincenal' },
        { value: 'mensual', label: 'Mensual' }
    ];
    const [clientSelect, setClientSelect] = useState({ value: 0, label: 'Seleccionar Cliente' });
    const [itemSelect, setItemSelect] = useState({ value: 0, label: 'Seleccionar artículo' });
    const [variantSelect, setVariantSelect] = useState({ value: 0, label: 'Seleccionar variedad' });
    const [payDaySelect, setPayDaySelect] = useState({ value: 0, label: '... Seleccione el día de pago' });
    const [periodSelect, setPeriodSelect] = useState({ value: 0, label: '... Seleccione el periodo de pago' });

    const [saleDTO, setSaleDTO] = useState({ items: [], client: {}, clientId: '' });
    const [metodosPago, setMetodosPago] = useState([])
    const [saleItem, setSaleItem] = useState({});
    const [total, setTotal] = useState({ total: 0 })
    const [payment, setPayment] = useState(paymentInitialState);
    const [clientLista, setClientLista] = useState([]);
    const [itemsLista, setItemLista] = useState([]);
    const [variantes, setVariantes] = useState([]);
    const [withDiscount, setWithDiscount] = useState(false);
    const [creditoDisponible, setCreditoDisponible] = useState(true);
    const [clientId, setClientId] = useState('');

    const [working, save] = usePost("/api/sales/credit");

    useEffect(() => {
        fetchPayMethods();
        fetchClients();
        fetchItems();
        let M;
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems);
        fetchPayMethods();

    }, []) //<--- en este arreglo se debe hacer referencia a los objetos que se deben refrescar al cambiar el estado de la app.

    useEffect(() => {
        // TODO: Verificar el cambio de estado con esta variable 
        if (payment.usePoints) {
            setTotal({ ...total, total: total.total - (saleDTO.client.totalPoints) })
        } else {
            setTotal({ ...total, total: total.total + (saleDTO.client.totalPoints) })
        }
    }, [payment.usePoints])

    async function fetchPayMethods() {
        const result = await httpGet('/api/cards');
        let cards = [];
        if (result && result.data) {
            result.data.map(c => {
                if (c.number !== 'ajuste') {
                    cards.push({ value: c.number, label: c.display });
                }
            })
        }
        setMetodosPago(cards)
    }

    function handleChange(e) {
        setClientSelect(e)
        selectClient(e.value);
    }

    function handlePaymentChange(e) {
        setPayDaySelect({ value: saleDTO.client.payDay, label: saleDTO.client.payDay });
        setPeriodSelect({ value: saleDTO.client.period, label: saleDTO.client.period });

        if (e.target.value === 'contado') {
            if (!withDiscount) {
                setTotal({ ...total, total: total.total * 0.85 });
                setPayment({ ...payment, payment: { value: e.target.value }, anticipo: total.total * 0.85 });
                setWithDiscount(true);
            }
        }
        else {
            if (!withDiscount)
                setPayment({ ...payment, payment: { value: e.target.value } });
            else {
                setTotal({ ...total, total: total.total * 100 / 85 });
                setPayment({ ...payment, payment: { value: e.target.value }, anticipo: 0 });
                setWithDiscount(false);
            }
        }

        window.M.updateTextFields();
        //nextPayday();

    }

    function handlePaymentPeriodChange(e) {
        setPayment({ ...payment, period: { value: e.value, label: e.label } });
        setPeriodSelect({ value: e.value, label: e.label });
    }

    function handleItemChange(e) {
        setItemSelect(e)
        setVariantSelect({ value: 0, label: 'Seleccionar Variedad' });
        selectItem(e.value);
    }

    function handleStockChange(e) {
        setVariantSelect(e)
    }

    function handleAnticipoChange(e) {
        setPayment({ ...payment, anticipo: e.target.value })
    }

    function handleAnticipoConfirmacionChange(e) {
        setPayment({ ...payment, anticipoConfirmacion: e.target.value })
    }

    function handleAmountChange(e) {
        setSaleDTO({ ...saleDTO, payMount: e.target.value });
        //setPayment({...payment, payMount: e.target.value})
    }

    function handlePaymentMethodChange(e) {
        setPayment({ ...payment, payMethod: { value: e.value, label: e.label } })
    }

    function handleItemSaleChange(e) {
        return function (e) {
            const { name, value } = e.target;
            setItemSelect({ ...itemSelect, [name]: value })
        }
    }

    function handleNextPayDate(e) {
        setSaleDTO({ ...saleDTO, nextPayDate: e.target.value });
    }

    function handlePayDayChange(e) {
        setSaleDTO({ ...saleDTO, payDay: e.value });
        setPayDaySelect({ value: e.value, label: e.value })
    }

    async function selectClient(cId) {
        const result = await httpGetOne('/api/clients/', cId);
        const client = result.data;
        setSaleDTO({ ...saleDTO, client, payMount: client.payMount, payDay: client.payDay });
        const avaibleCredit = client.creditLimit && client.creditLimit > 0 ? client.creditLimit - client.totalAmount : -1;
        if (client.rating < 75 || (avaibleCredit <= 0 && client.creditLimit && client.creditLimit > 0)) {
            setCreditoDisponible(false);
        } else {
            setCreditoDisponible(true);
        }
    }

    async function selectItem(cId) {
        const a = [];
        const result = await httpGetOne('/api/items/', cId);
        const item = result.data;
        item.stock.forEach(s => {
            const newStock = { label: s.subItem + ' - ' + s.quantity + ' disponible(s)', value: s.id };
            a.push(newStock);
        });
        setVariantes(a);
        setSaleItem({ item, picUrl: item.picUrl });
    }

    const fetchClients = async () => {
        const result = await httpGet('/api/clients');
        window.M.updateTextFields()
        const a = []
        result.data.forEach(c => {
            const newVal = { label: c.name, value: c.id }
            a.push(newVal);
        })
        setClientLista(a);
    }


    const fetchItems = async () => {
        const result = await httpGet('/api/items');
        const a = [];
        result.data.forEach(c => {
            let thisStock = 0;
            if (c.stock.length > 0) {
                c.stock.forEach(s => {
                    thisStock += s.quantity;
                })
            }
            if (thisStock > 0) {
                const newVal = { label: '[' + c.internalCode + '] ' + c.name + ' - ' + c.description, value: c.id }
                a.push(newVal);
            }
        });
        setItemLista(a);
    }

    const addItem = async (e) => {
        e.preventDefault();
        if (itemSelect.quantity > 0) {
            const itemId = itemSelect.value;
            const result = await httpGetOne('/api/items/', itemId);

            const a = result.data;
            let errorStock = false;
            a.stock.forEach(s => {
                if (s.id === variantSelect.value) {
                    if (s.quantity < itemSelect.quantity) {
                        alert("No hay inventario suficiente.");
                        errorStock = true;
                    }
                }
            })

            if (errorStock) return;

            const detail = { item: a, subItem: variantSelect.value, quantity: itemSelect.quantity, promotion: a.promotion }

            setSaleDTO({ ...saleDTO, items: [...saleDTO.items, detail] });

            setSaleItem({});
            setVariantSelect({ label: 'Seleccionar variedad', value: '0' });
            setItemSelect({ label: 'Seleccionar artículo', value: '0', quantity: '' });

        }
    }

    const calTot = () => {
        // Calcular el monto de la venta:
        let tot = 0;
        saleDTO.items.forEach(i => {
            tot += (i.item.price * i.quantity);
        });
        setTotal({ total: tot })
    }



    const deleteItem = async (e) => {
        const array = saleDTO.items.slice();;
        const getf = array.find(ei => ei.item._id === e.item._id);
        const index = array.indexOf(getf);
        if (index > -1) {
            array.splice(index, 1);
        }
        setSaleDTO({ ...saleDTO, items: array })
    }

    const toPay = async (e) => {
        if (!saleDTO.saleNumber) {
            setSaleDTO({ ...saleDTO, saleNumber: moment().format('YYMMDDHHMMss') });
        }
    }

    const validateCredit = (e) => {
        e.preventDefault();
        //Verificar el límite de c´redito y los montos
        if (saleDTO.client.creditLimit >= 0) {
            const avaibleCredit = saleDTO.client.creditLimit - saleDTO.client.totalAmount;
            const creditUse = total.total - (payment.anticipo && payment.anticipo > 0 ? payment.anticipo : 0);
            if (creditUse > avaibleCredit) {
                alert("El crédito disponible no es suficiente para cubrir la venta.");
                alert("Solicite más anticipo.");
                return;
            }
        }

        if (!periodSelect.value) {
            alert("Verificar el Periodo de Pago antes de continuar.");
            return;
        }
        if (moment(saleDTO.nextPayDate).isBefore(moment())) {
            alert("Verificar la próxima fecha de pago antes de continuar.");
            return;
        }
        if (!moment(saleDTO.nextPayDate).isValid()) {
            alert("Verifica el formato de la próxima fecha de pago antes de continuar.");
            return;
        }
        if (!saleDTO.payMount || saleDTO.payMount <= 0) {
            alert("Verificar el monto de las parcialidades antes de continuar.");
            return;
        }
        if (payment.anticipo && payment.anticipo > 0) {
            if (payment.anticipo !== payment.anticipoConfirmacion) {
                alert("El monto del anticipo y su confirmación no coinciden.");
                return;
            }
            if (payment.payMethod.value === '0') {
                alert("Seleccione el método de pago para el anticipo, antes de continuar.")
                return;
            }
        }

        //Cerrar el Modal Manualmente
        const M = window.M;
        var elem = document.getElementById('modal2');
        var instance = M.Modal.getInstance(elem);
        instance.close();

    }

    const closeContado = async () => {
        if (!payment.anticipo || payment.anticipo < 1) {
            alert("¿Revise el monto antes de continuar?");
            return;
        }
        if (payment.anticipo != payment.anticipoConfirmacion) {
            alert("La confirmación del monto no coincide. ");
            return;
        }
        if (!payment.payMethod || payment.payMethod.value == '0') {
            alert("Especifique el método de pago, antes de continuar.");
            return;
        }
        //Cerrar el Modal Manualmente
        const M = window.M;
        var elem = document.getElementById('modal3');
        var instance = M.Modal.getInstance(elem);
        instance.close();
    }

    const closeSale = async (e) => {

        const anticipo = {};
        if (payment.anticipo) {
            if (payment.anticipo > 0) {
                anticipo.amount = payment.anticipo;
                anticipo.status = 'pagado';
                anticipo.payDate = new Date();
                anticipo.active = true;
                //TODO: Agregar el usuario que recibe el pago.
                anticipo.verified = true;
                anticipo.payMethod = payment.payMethod.value;
                anticipo.client = saleDTO.client.id;
            }
        }

        let completeSale= {
            date: new Date(),
            status: 'cerrada',
            client: saleDTO.client._id,
            saleAmount: total.total,
            saleNumber: saleDTO.saleNumber,
            items: saleDTO.items,
            total: total.total,
            payment: payment.payment.value,
            anticipo: anticipo,
            usePoints: payment.usePoints
        }
        if (payment.payment.value !== 'contado') {
            completeSale = {
                ...completeSale,
                period: periodSelect.value,
                nextPayDate: saleDTO.nextPayDate,
                payDay: saleDTO.payDay,
                payMount: saleDTO.payMount,
            };
        }

        if (!working) {
            // Se cambio el hook de post para evitar post dobles
            const res = await save({ completeSale })
            console.log("result", JSON.stringify(res.data));

            // Open client details
            setClientId(saleDTO.client.id)

            setSaleDTO({ items: [], client: {}, clientId: '' })
            setClientSelect({ label: 'Seleccionar Cliente', value: '0' });
            setVariantSelect({ label: 'Seleccionar variedad', value: '0' });
            setItemSelect({ label: 'Seleccionar artículo', value: '0' });
            setPayment(paymentInitialState);
            fetchItems();

            const M = window.M;
            var elem2 = document.getElementById('modal23');
            var instance2 = M.Modal.getInstance(elem2);
            instance2.open();
        }
    }

    useMemo(calTot, [saleDTO]);

    function calcDiscount(item) {
        let discount = 0;
        if (item.promotion && item.promotion.discount) {
            if (item.promotion.discountType === 'percent') {
                discount = item.item.price * (item.promotion.discount / 100)
            } else {
                discount = item.promotion.discount
            }
        }
        return discount;
    }

    function calcPoints(item) {
        let points = 0;
        if (item.promotion && item.promotion.points) {
            if (item.promotion.pointsType === 'percent') {
                points = item.item.price * (item.promotion.points / 100)
            } else {
                points = item.promotion.points
            }
        }
        return Math.floor(points);
    }

    return (
        <div>
            <div className="container">
                <h4>Punto de Venta</h4>

                {/* {JSON.stringify(saleDTO)} */}
            </div>
            {/* {sale.client ? (<span>Cliente:{sale.client.name}, cId:{sale.client.id}</span>):(<span></span>)} */}
            <br />
            <div className="container">
                <div className="row">
                    <div className="col m6 s12 grupe">
                        <Select id="clientId" placeholder="Seleccionar Cliente" options={clientLista} name="clientId"
                            value={clientSelect} onChange={handleChange} className="selecter m6 s12" />
                        <br />
                        {/* <input type='number' name="quantity" value={item.quantity} 
                            onChange={handleItemSaleChange("quantity")} placeholder="Cantidad" style={{width:"40%;"}}></input> */}

                    </div>
                    <div className="col m6 s12 grupe center">
                        <h5>{saleDTO.client ? saleDTO.client.name : ''}</h5>
                        {saleDTO.client && saleDTO.client.picUrl && <img className="perfile" src={saleDTO.client ? saleDTO.client.picUrl : ''} alt="client pic" />}
                        {saleDTO.client && saleDTO.client.creditLimit && saleDTO.client.creditLimit > 0 ?
                            <div>
                                <p>{(`Límite de crédito: $ ${saleDTO.client.creditLimit}`)}</p>
                                <p>{(`Crédito disponible: $ ${saleDTO.client.creditLimit - saleDTO.client.totalAmount}`)}</p>
                                <p className={saleDTO.client.rating < 75 ? "red" : ""}>{(`Calificación: ${saleDTO.client.rating}`)}</p>
                                {!creditoDisponible ?
                                    <p className='red'>Nota: No se podrá realizar una venta a crédito</p>
                                    : ''}
                            </div>
                            : null}
                        {saleDTO.client && saleDTO.client.totalPoints ?
                            <div>
                                <p>{(`Saldo en puntos: ${saleDTO.client.totalPoints}`)}</p>
                            </div>
                            : null}
                    </div>
                </div>
                <div className="row">
                    <h4>Artículos</h4>
                </div>
                <div className="row">
                    <div className="col m6 s12 grupe">
                        <Select id="itemId" placeholder="Seleccionar Artículo" options={itemsLista} name="itemId"
                            value={itemSelect} onChange={handleItemChange} className="selecter m6 s12" />
                        <br />

                        <Select id="stock" placeholder="Seleccionar Variante" options={variantes} name="stock"
                            value={variantSelect} onChange={handleStockChange} className="selecter m6 s12" />
                        <br />
                        <div className="input-field col s12">
                            <input id="quantity" type="number" name="quantity" value={itemSelect.quantity}
                                onChange={handleItemSaleChange("quantity")}></input>
                            <label htmlFor="quantity">Cantidad</label>
                        </div>
                    </div>
                    <div className="col m6 s12 grupe center">
                        {saleItem && saleItem.picUrl && <img className="perfile" src={saleItem ? saleItem.picUrl : ''} alt="item pic" />}
                    </div>
                </div>
                <br />
                <div className="center">
                    <a href="#table" className="btn light-goldeneye" onClick={addItem} style={{ marginLeft: '10px' }}>Agregar</a>&nbsp;
                    <br /><br />
                </div>
            </div><br /><br />
            <div className="container">
                {saleDTO.items ? (
                    <table id="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Artículo</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {saleDTO.items.map((i) => (
                                <tr key={i.id} className="Table">
                                    <td></td>
                                    <td><a href={i.item ? i.item.picUrl : ''} target="_blank;">{i.item ? i.item.name : ''}</a></td>
                                    <td>{i.quantity}</td>
                                    <td>${i.item ? i.item.price : ''}</td>
                                    <td>${i.item ? i.item.price * i.quantity : ''}</td>
                                    <td>
                                        <button className="btn light-goldeneye"
                                            value={i}
                                            onClick={() => { if (window.confirm('¿Estás seguro que deseas quitar este producto de la venta?')) deleteItem(i, i.id) }}
                                            style={{ margin: '4px', }}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>) : <span></span>}
            </div>
            <div className="center">
                <br />

                {total.total ? <button className="btn light-goldeneye modal-trigger clientes-btn" href="#modal1" style={{ height: 'auto' }}
                    onClick={toPay}><span>Total: $ {total.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;<br /></span>Proceder al Pago</button> : <span></span>}
                <br className="movil-only" />
                <br className="movil-only" />
                <br /><br /><br /><br /><br />
            </div>
            <div id="modal1" className="modal modal2">

                <div className="modal-content">
                    <h4>Nueva Venta - <span>{saleDTO.saleNumber}</span></h4>
                    <div className="row">
                        <div className="col s12 informative">
                            <label>Revise los datos y ajuste la configuración de pagos, antes de concretar la venta.</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 informative">
                            <label><b>Cliente: </b>{saleDTO.client ? saleDTO.client.name : 'Debe seleccionar un cliente, antes de continuar.'}</label><br />
                            <label><b>Dirección: </b>{saleDTO.client ? saleDTO.client.address : 'Debe seleccionar un cliente, antes de continuar.'}</label><br />
                            <label><b>Teléfono: </b>{saleDTO.client ? saleDTO.client.phone : 'Debe seleccionar un cliente, antes de continuar.'}</label><br />
                            <label><b>Celular: </b>{saleDTO.client ? saleDTO.client.cellPhone : 'Debe seleccionar un cliente, antes de continuar.'}</label><br />
                            <label><b>Día de pago: </b>{saleDTO.client ? saleDTO.client.payDay : 'Debe seleccionar un cliente, antes de continuar.'}</label><br />
                            <label><b>Saldo en puntos disponible: </b>{saleDTO.client && saleDTO.client.totalPoints ? saleDTO.client.totalPoints : '0'}</label><br />
                        </div>
                    </div>
                    {saleDTO.client && saleDTO.client.totalPoints > 100 ?
                        <div className="row">
                            <label className="chContainer">Pagar con puntos
                                <input type="checkbox" value={payment.usePoints} onChange={() => {
                                    setPayment({ ...payment, usePoints: !payment.usePoints })
                                }}></input>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col s12 informative">
                            <label><b>Total a pagar: </b><span>$ {total.total ? total.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</span></label><br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12"></div>
                        <h4>Configuración del pago </h4>
                        <div className="col m6 s12 grupe">
                            {/* <div className="nmms">
                                <Select id="payment" placeholder="Seleccionar Método de Pago" options={ payforms } name="payment" 
                                    value={payment.payment} onChange={handlePaymentChange} className="selecter m6 s12" />
                            </div> */}
                            <br />
                            <br />
                            <div className="center">
                                <button className="waves-effect btn light-goldeneye modal-trigger"
                                    onClick={handlePaymentChange}
                                    href="#modal2" style={{ marginRight: '5px' }} value="credito" disabled={!creditoDisponible}>Crédito</button>
                                <button className="waves-effect btn light-goldeneye modal-trigger"
                                    onClick={handlePaymentChange}
                                    href="#modal3" style={{ marginRight: '5px' }} value="contado">Contado</button>
                            </div>
                            <br />
                            {/* {JSON.stringify(payment)} */}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                    <button className="modal-close waves-effect btn light-goldeneye" onClick={e =>
                        window.confirm("¿Desea cerrar la venta con los datos proporcionados?") &&
                        closeSale(e)
                    } style={{ float: 'right' }}>Aceptar</button>
                </div>
            </div> {/* Fin Modal1 */}

            <div id="modal2" className="modal modal2 modal-fixed-footer">
                <div className="modal-content">
                    <h4>Crédito <span>{saleDTO.saleNumber}</span></h4>
                    <br />
                    <br />
                    {
                        saleDTO.items.some(a => a.promotion && a.promotion.discount
                            && moment().isBetween(moment(a.promotion.startDate).utc(), moment(a.promotion.endDate).utc().endOf('day')))
                        && <h4>Promociones aplicables</h4>
                    }

                    {
                        saleDTO.items.map(a => {
                            let promoson = [];
                            if (calcDiscount(a) > 0) {
                                if (moment().isBetween(moment(a.promotion.startDate).utc(), moment(a.promotion.endDate).utc().endOf('day'))) {
                                    promoson.push(<div><span>{a.item.name} Descuento {a.promotion.name}: $ {calcDiscount(a) * a.quantity}</span><br></br></div>)
                                }
                            }
                            if (calcPoints(a) > 0) {
                                if (moment().isBetween(moment(a.promotion.startDate).utc(), moment(a.promotion.endDate).utc().endOf('day'))) {
                                    promoson.push(<div><span>{a.item.name} Puntos {a.promotion.name}: {calcPoints(a) * a.quantity}</span><br></br></div>)
                                }
                            }
                            return (promoson.map(p => p))
                        })
                    }

                    <br />
                    <br />
                    <br />

                    {/* <p><span>Próximo día de pago: {moment(sale.client.nextPayDate).format("DD/MM/YYYY")}</span></p> */}
                    <div className="input-fields col s12">
                        <label htmlFor="payDay">Periodo de pago</label>
                        <Select id="period" placeholder="Periodo de Pago *" options={PAY_PERIODS} name="period"
                            value={periodSelect} onChange={handlePaymentPeriodChange} className="selecter m6 s12" />
                    </div>
                    <br />
                    <div className="input-field col s12">
                        <input id="nextPayDate" type="date" min={moment().add(1, 'day').format('YYYY-MM-DD')} name="nextPayDate" value={saleDTO.nextPayDate}
                            onChange={handleNextPayDate}></input>
                        <label htmlFor="nextPayDate">Próximo Día de Pago <span style={{ color: 'red' }}>&nbsp;*</span></label>
                    </div>

                    <div className="input-fields col s12">
                        <label htmlFor="payDay">Día de pago</label>
                        <Select id="payDay" options={PAY_DAYS} name="payDay"
                            value={payDaySelect} onChange={handlePayDayChange}
                            className="selecter capitalize s12" />
                    </div>

                    <label htmlFor="payMount">Parcialidades <span style={{ color: 'red' }}>&nbsp;*</span></label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="payMount" name="payMount" type="number" step="0.01"
                            value={saleDTO.payMount}
                            onChange={handleAmountChange} style={{ width: '90%' }}></input>
                    </div>

                    <label htmlFor="anticipo">Anticipo</label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input id="anticipo" type="number" step="0.01" name="anticipo" value={payment.anticipo}
                            onChange={handleAnticipoChange} style={{ width: '90%' }}></input>
                    </div>

                    <label htmlFor="anticipoConfirmacion">Vuelva a intoducir la Cantidad</label>
                    <div className="input-fields col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input id="anticipoConfirmacion" type="number" step="0.01" name="anticipoConfirmacion" value={payment.anticipoConfirmacion}
                            onChange={handleAnticipoConfirmacionChange} style={{ width: '90%' }}></input>
                    </div>

                    <Select id="payMethod" placeholder="Seleccionar Método de pago" options={metodosPago} name="payMethod"
                        value={payment.payMethod} onChange={handlePaymentMethodChange} className="selecter m6 s12" style={{ zIndex: '999' }} />

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                    <button className="waves-effect btn light-goldeneye" style={{ float: 'right' }} onClick={validateCredit}>Aceptar</button>
                </div>
            </div> {/* Fin Modal2*/}

            <div id="modal3" className="modal modal2 modal-fixed-footer">
                <div className="modal-content">
                    <h4>Contado <span>{saleDTO.saleNumber}</span></h4>
                    {/* {JSON.stringify(payment)} */}
                    <br />
                    <span>Se aplica 15% de descuento sobre el total de la venta en pagos de contado.</span>
                    <br />
                    <label htmlFor="anticipo">Total a Pagar<span style={{ color: 'red' }}>&nbsp;*</span></label>
                    <div className="input-field col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="anticipo" name="anticipo" type="number" step="0.01"
                            value={payment.anticipo}
                            onChange={handleAnticipoChange}>
                        </input>
                    </div>

                    <label htmlFor="anticipoConfirmacion">Vuelva a intoducir la cantidad.<span style={{ color: 'red' }}>&nbsp;*</span></label>
                    <div className="input-field col s12">
                        <i className="material-icons prefix">attach_money</i>
                        <input className="active" id="anticipoConfirmacion" name="anticipoConfirmacion" type="number" step="0.01"
                            value={payment.anticipoConfirmacion}
                            onChange={handleAnticipoConfirmacionChange}>
                        </input>
                    </div>

                    <Select id="payMethod" placeholder="Seleccionar Método de pago" options={metodosPago} name="payMethod"
                        value={payment.payMethod} onChange={handlePaymentMethodChange} className="selecter m6 s12" />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                    <button className="waves-effect btn light-goldeneye" onClick={closeContado} style={{ float: 'right' }}>Aceptar</button>
                </div>
            </div> {/* Fin Modal3*/}


            <div id="modal23" className="modal modal2 modal-fixed-footer">
                <div className="modal-content gr_pattern">
                    {clientId && clientId !== "" &&
                        <Balance value={clientId}></Balance>
                    }
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect btn light-goldeneye">Cerrar</button>
                </div>
            </div> {/* Fin Modal3*/}
        </div>
    )
}