import React, {useState, useEffect} from 'react';
import {httpGet, httpPost, httpGetOne, httpDelete} from '../fetch';
import moment from 'moment';
import Select from 'react-select';
import Balance from './Balance';

export default function Pays({}){
    
    let M;
    const [metodosPago, setMetodosPago] = useState([]);
    const [pay, setPay]= useState({});
    const [pays, setPays]= useState([]);
    const [client, setClient] = useState({label: 'Seleccionar Cliente', value:'0'});
    const [clientLista, setClientLista] = useState([]);
    const [filter, setFilter] = useState([]);
    const [cliente, setCliente] = useState("");

    function admin() {
        if (localStorage.userType === 'Admin') return true;
        else return false;
    }

    useEffect(()=>{
        const userType = localStorage.getItem('userType');
        if(userType !== 'Admin'&& userType !== 'Ventas' && userType !== 'Cobranza'){
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/preventas'
        }
        

        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchTodayPays();
        fetchClients();
        fetchPayMethods();
    },[]);

    async function handleDelete(id){
        const userEmail = localStorage.getItem('user');
        await httpDelete(`/api/pays/delete/${id}`, id, userEmail)
        fetchTodayPays()
    }

    async function fetchPayMethods() {
        const userType = localStorage.getItem('userType');
        const result = await httpGet('/api/cards');
        let cards = [];
        result.data.map(c => {
            if(c.number === 'ajuste'){
                if(userType === 'Admin'){
                    cards.push({ value: c.number, label: c.display });
                }
            }else{
                cards.push({ value: c.number, label: c.display });
            }
        })
        setMetodosPago(cards)
    }

    const fetchClients = async () => {
        const result = await httpGet('/api/clients');
        // console.log(result.data)
        window.M.updateTextFields();
        const a = [];    
        result.data.map(c =>{
            const newVal = {label: c.name, value: c.id}
            a.push(newVal);
        })
        setClientLista(a);
    }

    const fetchPays = async () => {
        const result = await httpGet('/api/pays');
        setPays(result.data);
    }

    const fetchFilterPays = async () =>{
        const result = await httpPost('/api/pays/between',{fromDate:moment(filter.weekinit).startOf('day'), toDate:moment(filter.weekend).endOf('day')});
        setPays(result.data);
    }
    
    const fetchTodayPays = async () => {
        const result = await httpGet('/api/pays/today');
        setPays(result.data);
    }

    async function handleChange(e){
        //Revisar que el cliente no tenga un pago para hoy o notificar.
        pays.map(p =>{
            if (p.client.id === e.value && moment(p.payDate).isAfter(moment().startOf('day'))){
                alert("El cliente seleccionado ya tiene un pago registrado para hoy.");
            }
        });
        setClient( e )
        selectClient(e.value);
     }

     const handleWeekinitChange = (e) => {
        setFilter({...filter, weekinit: e.target.value });
    }

    const handleWeekendChange = (e) => {
        setFilter({...filter, weekend: e.target.value });
    }

    function handlePayMethodChange(e){
        setPay({...pay, payMethod: e.value, payMethodLabel:{label: e.label, value: e.value}});
     }

    function handleAmountChange(e){
        setPay({...pay, amount: e.target.value});
    }

    function handleAmountConfirmacionChange(e){
        setPay({...pay, amountConfirmacion: e.target.value});
    }

     async function selectClient(cId){
        const result = await httpGetOne('/api/clients/',cId);
        const client = result.data;
        setPay({...pay, client});
     }

     async function closeSale(){
        if(!pay.client){
            alert("Debe seleccionar un cliente, antes de continuar.");
            return;
        }
        if(!pay.amount || pay.amount < 1){
            alert("Revise la cantidad pagada antes de continuar.");
            return;
        }
        if(pay.amount != pay.amountConfirmacion){
            alert("El monto y la confirmación no coinciden.");
            return;
        }
        if(!pay.payMethod || pay.payMethod.value == '0'){
            alert("Especifique el método de pago, antes de continuar.");
            return;
        }


        const completePay = {};
        console.log(pay);
        if(pay.amount){
            if(pay.amount > 0){
                completePay.amount=pay.amount;
                completePay.status = 'pagado';
                completePay.payDate = new Date();
                completePay.active = true;
                //TODO: Agregar el usuario que recibe el pago.
                completePay.verified = true;
                completePay.payMethod= pay.payMethod;
                completePay.client= pay.client.id;
                
                const result = await httpPost('/api/pays/verified', completePay, localStorage.getItem('user'));
                console.log(result);
                fetchTodayPays();
                setCliente(pay.client.id)
            }
        }

        //Cerrar el Modal Manualmente
        const M = window.M; 
        var elem = document.getElementById('modal1');
        var instance = M.Modal.getInstance(elem);
        instance.close();

        var elem2 = document.getElementById('modal2');
        var instance2 = M.Modal.getInstance(elem2);
        instance2.open();
     }

    return(
    <div>
        <div className="container">
            <h4>Pagos</h4>
            <a href="#modal1" className="btn light-goldeneye modal-trigger">Agregar +</a>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchTodayPays}>Hoy</a>
            <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchPays}>Todos</a>
            <br /><br />
            <div className="row">
                <div className="col m12">
                    <div className="input-field col m5 s12">
                        <input id="fromDate" type='date' value={filter.weekinit}
                            onChange={handleWeekinitChange}></input>
                        <label htmlFor="fromDate">Inicio</label>
                    </div>
                    <div className="input-field col m5 s12">
                        <input id="toDate" type='date' value={filter.weekend}
                            onChange={handleWeekendChange}></input>
                        <label htmlFor="toDate">Fin</label>
                    </div>
                    <div className="input-field col m2 s12 center">
                        <a className="btn waves light-goldeneye" style={{marginLeft:'5px'}} onClick={fetchFilterPays}>Actualizar</a>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Monto</th>
                        <th>Medio Pago</th>
                        <th>Cliente</th>
                        <th className="pc-only-table-cell">Usuario</th>
                        <th>Verificación</th>
                        { admin() && <th>Eliminar</th> }
                    </tr>
                </thead>
                <tbody>
                    {pays.map(i => (
                        <tr className="Table" key={i._id}>
                            {/* <td>{moment(i.payDate).format('DD/MM/YYYY')}</td> */}
                            <td>{moment(i.payDate).utcOffset(-7).format('DD/MM/YYYY')}</td>
                            <td>${i.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            <td className="capitalize">{i.payMethod}</td>
                            <td>{i.client.name}</td>
                            <td className="pc-only-table-cell">{i.user?i.user.displayname:''}</td>
                            <td>{i.verified?'Verificado':'Sin verificar'}</td>
                            {admin() && 
                                <td>
                                    <button className="waves-effect btn light-goldeneye modal-trigger"
                                        onClick={(e) => { if (window.confirm('¿Estás seguro que deseas eliminar este pago?')) handleDelete(i.id) }}
                                        href="#" style={{ marginRight: '5px' }}>Eliminar</button>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        
        </div>


        <div id="modal1" className="modal modal2">
            <div className="modal-content">
                 <div className="modal-header">
                    <h4>Nuevo Pago</h4>
                    <div style={{textAlign:'right', position:'absolute', top:'5px', right:'5px'}}>
                        <button className="modal-close btn-floating light-goldeneye">X</button>
                    </div>
                </div>
                <br />
                <Select id="clientId" placeholder="Seleccionar Cliente" options={ clientLista } name="clientId" 
                            value={client} onChange={handleChange} className="selecter lvl110 m6 s12" />
                <br />
                <Select id="payMethod" placeholder="Seleccionar Método de pago" options={ metodosPago } name="payMethod" 
                                        value={pay.payMethodLabel} onChange={handlePayMethodChange} className="selecter lvl100 m6 s12"/>
                <br />
                <div className="input-field col s12">
                    <i className="material-icons prefix">attach_money</i>
                    <input id="amount" type="number" step="0.01" name="amount" value={pay.amount} 
                        onChange={handleAmountChange}></input>
                    <label htmlFor="amount">Cantidad Pagada<span style={{color:'red'}}>&nbsp;*</span></label>
                </div>

                <div className="input-field col s12">
                    <i className="material-icons prefix">attach_money</i>
                    <input className="active" id="payAmountConfirmacion" name="payAmountConfirmacion" type="number" step="0.01"
                        value={pay.amountConfirmacion} 
                        onChange={handleAmountConfirmacionChange}>
                    </input>
                    <label for="payAmountConfirmacion">Vuelva a intoducir la cantidad.<span style={{color:'red'}}>&nbsp;*</span></label>
                </div>
            </div>
            <div className="modal-footer" style={{position:'absolute',bottom:'0'}}>
            <button className="waves-effect btn light-goldeneye modal-close" style={{float:'left'}}>Cerrar</button>
                <button className="waves-effect btn light-goldeneye" onClick={e =>
                    window.confirm("¿Desea continuar con el pago?") &&
                    closeSale(e)
                } style={{float:'right'}}>Aceptar</button>
            </div>
        </div>

        <div id="modal2" className="modal modal2 modal-fixed-footer">
            <div className="modal-content gr_pattern">
                {cliente && cliente !== "" &&
                    <Balance value={cliente}></Balance>
                }
            </div>
            <div className="modal-footer">
                <button className="modal-close waves-effect btn light-goldeneye">Cerrar</button>
            </div>
        </div>
    </div>
    );
}