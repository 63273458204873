import React, { useState } from 'react';
import {httpPost} from '../fetch';

export default function Login(props) {
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    })
    const [working, setWorking] = useState(false)
    const [error, setError] = useState('')

    async function login(e) {
        console.log("try login ")
        e.preventDefault();
        try {
            setWorking(true)
            const response = await httpPost('/api/auth/login', {...loginData});
            // console.log(response.data)
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', response.data.user);
            localStorage.setItem('userType', response.data.userType);
            localStorage.setItem('email', response.data.email);
            if(response.data.userType === 'Ventas'){
                window.location.href = '/preventas';
            }
            else if(response.data.userType === 'Admin'){
                window.location.href = '/tablero';
            }
            else if(response.data.userType === 'Almacen'){
                window.location.href = '/inventario';
            }
            else{
                window.location.href = '/clientes';
            }
            

        } catch(e) {
            setWorking(false)
            setError(e.message);
            alert('El usuario y/o la contraseña son incorrectos');
        }        
    }

    return(
        <div className="container">
            <div className="center">
                <div className="card" style={{maxHeight:'50vh'}}>
                    <div className="row">
                        <div className="col m5 s12 center cave" style={{padding:'10px', height:'50vh'}}>
                            <div className="wrapper white-text">
                                <h3>&nbsp;</h3>
                            </div>
                        </div>
                        <div className="col m7 s12 wtext">
                            <br />
                            <br />
                            <h4 className="left ">Ingresa las credenciales:</h4>
                            <form onSubmit={login}>
                                <div className="input-field col s12">
                                    <input id="user_name" type="text" required
                                        value={loginData.username}
                                        onChange={e => setLoginData({...loginData, username: e.target.value})} disabled={working}></input>
                                    <label htmlFor="user_name">Usuario</label>
                                </div>
                                <div className="input-field col s12">
                                    <input id="passw" type="password" required
                                        onChange={e => setLoginData({...loginData, password: e.target.value})} disabled={working}></input>
                                    <label htmlFor="passw">Password</label>
                                </div>
                                <div className="input-field col s12">
                                    <button className="btn light-goldeneye" type="submit" disabled={working}>Log in</button>
                                    {working?(<div>
                                    <div className="progress">
                                        <div className="indeterminate"></div>
                                    </div>
                                    <div className="center">Cargando, por favor espere...</div>
    </div>):(<div><br /><br /></div>)}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}