import moment from 'moment';
import React, { useState } from 'react';
import Select from 'react-select';

const PromocionForm = ({ savePromotion, promotion, setPromotion }) => {

    const discountTypeList = [{ label: 'Monto ($)', value: 'amount' }, { label: 'Porcentaje (%) del valor de la prenda', value: 'percent' }]
    const pointsTypeList = [{ label: 'Cantidad (Puntos)', value: 'amount' }, { label: 'Porcentaje (%) del valor de la prenda', value: 'percent' }]

    return (
        <div id="modal1" className="modal modal2">
            <div className="modal-content" style={{minHeight: "700px"}}>
                <div className="modal-header">
                    <h4>Nueva Promoción</h4>
                    <div style={{ textAlign: 'right', position: 'absolute', top: '5px', right: '5px' }}>
                        <button className="modal-close btn-floating light-goldeneye">X</button>
                    </div>
                </div>
                <div className="input-field col s12">
                    <input id="name" type="text" name="name" value={promotion.name}
                        onChange={(e) => setPromotion({ ...promotion, name: e.target.value })}></input>
                    <label htmlFor="name">Nombre de la Promoción<span style={{ color: 'red' }}>&nbsp;*</span></label>
                </div>

                <div className="input-field col s12">
                    <label htmlFor="isGlobal" className="chContainer chContainer_xl"> Esta promoción aplica para todos los productos
                        <input
                            className="checkboxs"
                            id="isGlobal"
                            name="isGlobal"
                            type="checkbox"
                            value="isGlobal"
                            checked={promotion.isGlobal}
                            onChange={(e) => setPromotion({ ...promotion, isGlobal: e.target.checked })} />
                        <spanx className="checkmark light-gray"></spanx>
                    </label>
                </div>


                <Select id="discountType" placeholder="Seleccionar tipo de descuento" options={discountTypeList} name="discountType"
                    value={promotion.discountTypeSelect}
                    onChange={(e) => setPromotion({ ...promotion, discountType: e.value, discountTypeSelect: { label: e.label, value: e.value } })}
                    className="selecter lvl110 m6 s12" />
                <div className="input-field col s12">
                    {promotion.discountType === 'amount' ? <i className="material-icons prefix">attach_money</i> : ''}
                    <input id="discount" type="number" step="0.01" name="discount" value={promotion.discount}
                        onChange={(e) => setPromotion({ ...promotion, discount: e.target.value })}></input>
                    <label htmlFor="discount">Descuento<span style={{ color: 'red' }}>&nbsp;*</span></label>
                </div>
                <Select id="pointsType" placeholder="Seleccionar tipo de puntos" options={pointsTypeList} name="pointsType"
                    value={promotion.pointsTypeSelect}
                    onChange={(e) => setPromotion({ ...promotion, pointsType: e.value, pointsTypeSelect: { label: e.label, value: e.value } })}
                    className="selecter lvl100 m6 s12" />
                <div className="input-field col s12">
                    <input id="points" type="number" step="0.01" name="points" value={promotion.points}
                        onChange={(e) => setPromotion({ ...promotion, points: e.target.value })}></input>
                    <label htmlFor="points">Puntos<span style={{ color: 'red' }}>&nbsp;*</span></label>
                </div>
                <div className="input-fields col s12">
                    <label for="startDate">Fecha de Inicio de la promoción</label>
                    <input id="startDate" type='date' name="startDate"
                        value={moment(promotion.startDate).format('YYYY-MM-DD')}
                        onChange={(e) => { setPromotion({ ...promotion, startDate: e.target.value }) }}></input>
                </div>
                <div className="input-fields col s12">
                    <label for="endDate">Fecha de Finalización de la promoción</label>
                    <input id="endDate" type='date' name="endDate"
                        value={moment(promotion.endDate).format('YYYY-MM-DD')}
                        onChange={(e) => { setPromotion({ ...promotion, endDate: e.target.value }) }}></input>
                </div>
            </div>


            <div className="modal-footer">
                <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }} onClick={()=>setPromotion({})}>Cerrar</button>
                <button className="waves-effect btn light-goldeneye"
                    onClick={e =>
                        window.confirm("¿Desea continuar con el registro?") &&
                        savePromotion(e)
                    } style={{ float: 'right' }}>Aceptar</button>
            </div>
        </div>
    );
};

export default PromocionForm;