import React, { useState, useEffect } from 'react';
import { httpGet, httpPost, httpPut, httpGetOne } from '../fetch';
import AttributesList from './components/AttributesList';

export default function InventoryConfig({ }) {

    let M;
    const [types, setTypes] = useState([]);
    const [type, setType] = useState({});
    const [catalog, setCatalog] = useState([]);
    const [attributes, setAttributes] = useState({});
    const [newAttribute, setNewAttribute] = useState({});

    useEffect(() => {
        const userType = localStorage.getItem('user');
        console.log(localStorage)
        if (userType !== 'Developer') {
            window.alert("Algo salió mal, no deberías estar aquí...");
            window.location.href = '/clientes'
        }
        var elems = document.querySelectorAll('.modal');
        M = window.M; //Cargar Materialize desde CDN
        M.Modal.init(elems, []);

        fetchTypes();
    }, []);

    const fetchTypes = async () => {
        const result = await httpGet('/api/inventoryTypes');
        setTypes(result.data);
    }

    function delAttr(e) {
        const a = JSON.parse(JSON.stringify(attributes));
        delete a[e];
        setAttributes(a);
    }

    async function saveInventoryType() {
        console.log("Guardando tipo de inventario...");
        console.log(type)
        let completeType = {};
        completeType = { type: type.name, catalog, attributes, id: type.id }

        console.log(completeType);

        if (type.id) {
            const result = await httpPut('/api/inventoryTypes/' + completeType.id, completeType, localStorage.getItem('user'));
            console.log(result.data);
        } else {
            const result = await httpPost('/api/inventoryTypes', completeType, localStorage.getItem('user'));
            console.log(result.data);
        }

        //Cerrar el Modal Manualmente
        const M = window.M;
        var elem = document.getElementById('modal1');
        var instance = M.Modal.getInstance(elem);
        instance.close();

        setType({ name: '' });
        setCatalog({});
        setAttributes({});
        fetchTypes();
    }

    async function editType(id) {
        const result = await httpGetOne('/api/inventoryTypes', id);

        let catalogContainer = {};

        result.data.catalog.map(c => {
            catalogContainer = { ...catalogContainer, [c]: true };
        });

        setCatalog(catalogContainer);
        setType({ name: result.data.type, id });
        setAttributes(result.data.attributes);

        //Ajuste Materialize
        const M = window.M;
        M.updateTextFields();
    }

    function handleTypeChange(event) {
        const target = event.target;
        const name = target.name;

        setType({
            ...type,
            [name]: target.value
        });

        console.log(type)
    }

    function handleCatalogChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setCatalog({
            ...catalog,
            [name]: value
        });

        console.log(catalog)
    }

    function handleNewAttrChange(event) {
        const target = event.target;
        const name = target.name;

        setNewAttribute({
            ...newAttribute,
            [name]: target.value
        });

        console.log(newAttribute)
    }

    function addNewAttributes() {
        let a = newAttribute.attrList.split(',').map(function (item) {
            return item.trim();
        });
        setAttributes({
            ...attributes,
            [newAttribute.attrName]: a

        })
        setNewAttribute({
            attrName: '',
            attrList: ''
        })
    }

    return (
        <div>
            <div className="container">
                <h4>Configración del Inventario</h4>
                <a href="#modal1" className="btn light-goldeneye modal-trigger">Agregar Tipo +</a>
                <br />
                <br />
                <table>
                    <thead>
                        <tr>
                            <th>Tipo de Prenda</th>
                            <th>Catálogo</th>
                            <th>Atributos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {types.map(t => (
                            <tr key={t._id}>
                                <td className="capitalize">{t.type}</td>
                                <td className="capitalize">{t.catalog.toString()}</td>
                                <td className="capitalize">{Object.keys(t.attributes).toString()}</td>
                                <td>
                                    <button className="btn light-goldeneye modal-trigger"
                                        value={t._id}
                                        href="#modal1"
                                        onClick={e => { editType(t._id) }}
                                        style={{ margin: '4px' }}>
                                        <i className="material-icons">edit</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div id="modal1" className="modal modal2">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4>Tipo de Prenda</h4>
                        <div style={{ textAlign: 'right', position: 'absolute', top: '5px', right: '5px' }}>
                            <button className="modal-close btn-floating light-goldeneye">X</button>
                        </div>
                    </div>
                    <br />

                    <div className="input-fields col s6">
                        <label htmlFor="lista">Nombre *</label>
                        <input type="text" name="name" onChange={handleTypeChange} value={type.name} />
                    </div>

                    <div className="input-fields col s12">
                        <label htmlFor="birth">Catálogo<span style={{ color: 'red' }}>&nbsp;*</span></label>
                        <br />
                        <label htmlFor="catalog" className="chContainer"> Hombre
                            <input
                                className="checkboxs"
                                id="catalog"
                                name="hombre"
                                type="checkbox"
                                value="hombre"
                                checked={catalog.hombre}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="mujer" className="chContainer"> Mujer
                            <input
                                className="checkboxs"
                                id="mujer"
                                name="mujer"
                                type="checkbox"
                                value="mujer"
                                checked={catalog.mujer}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="joyeria" className="chContainer"> Joyería
                            <input
                                className="checkboxs"
                                id="joyeria"
                                name="joyeria"
                                type="checkbox"
                                value="joyeria"
                                checked={catalog.joyeria}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="perfumeria" className="chContainer"> Perfumería
                            <input
                                className="checkboxs"
                                id="perfumeria"
                                name="perfumeria"
                                type="checkbox"
                                value="perfumeria"
                                checked={catalog.perfumeria}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="zapateria" className="chContainer"> Zapatería
                            <input
                                className="checkboxs"
                                id="zapateria"
                                name="zapateria"
                                type="checkbox"
                                value="zapateria"
                                checked={catalog.zapateria}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="bolsos" className="chContainer"> Bolsos
                            <input
                                className="checkboxs"
                                id="bolsos"
                                name="bolsos"
                                type="checkbox"
                                value="bolsos"
                                checked={catalog.bolsos}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="cinturones" className="chContainer"> Cinturones
                            <input
                                className="checkboxs"
                                id="cinturones"
                                name="cinturones"
                                type="checkbox"
                                value="cinturones"
                                checked={catalog.cinturones}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                        <label htmlFor="lentes" className="chContainer"> Lentes
                            <input
                                className="checkboxs"
                                id="lentes"
                                name="lentes"
                                type="checkbox"
                                value="lentes"
                                checked={catalog.lentes}
                                onChange={handleCatalogChange} />
                            <spanx className="checkmark light-gray"></spanx>
                        </label>

                    </div>
                    <br />
                    <div className="input-fields col s6">
                        <label htmlFor="lista">Nuevo Atributo *</label>
                        <input type="text" name="attrName" onChange={handleNewAttrChange} value={newAttribute.attrName} />
                    </div>

                    <div className="input-fields col s6">
                        <label htmlFor="lista">Agregar atritutos separados por comas: *</label>
                        <input type="text" name="attrList" onChange={handleNewAttrChange} value={newAttribute.attrList} />
                    </div>
                    <br />
                    <button className="waves-effect btn light-goldeneye" onClick={addNewAttributes}>Agregar</button>
                    <br />
                    <div className="input-fields col s12">
                        <label htmlFor="birth">Atributos<span style={{ color: 'red' }}>&nbsp;*</span></label>
                        {
                            Object.keys(attributes).map(a => (
                                <div key={a}>
                                    <button
                                        className="waves-effect btn light-goldeneye"
                                        onClick={() => delAttr(a)}
                                        style={{ float: 'left', marginRight: '10px' }}
                                    >Borrar
                                    </button>
                                    <AttributesList key={a} name={a} list={attributes[a]}></AttributesList>
                                </div>
                            ))
                        }
                    </div>
                    <br />
                </div>

                <div className="modal-footer" style={{ bottom: '0' }}>
                    <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                    <button className="waves-effect btn light-goldeneye" onClick={e =>
                        window.confirm("¿Desea continuar con el registro?") &&
                        saveInventoryType(e)
                    } style={{ float: 'right' }}>Guardar</button>
                </div>
            </div>
        </div>
    );
}