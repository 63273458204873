import moment from 'moment';
import xlsx from 'node-xlsx';
import React, { useState } from 'react';
import { httpGet } from '../../fetch';
import './ReporteInventario.css';

const ReporteInventario = () => {
    const [stock, setStock] = useState([]);

    const fetchData = async () => {
        const result = await httpGet('/api/reports/stock');
        setStock(result.data);
    }

    useState(() => {
        fetchData();
    }, [])

    const saveReportFile = (reportName, byte) => {

        let blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;

        link.click();
    };

    const getStock = (data) => {
        let pieces = 0;
        let totalCost = 0;
        let totalPrice = 0;

        data.stock.map(a => {
            pieces += a.quantity;
            totalPrice += a.quantity * data.price;
            totalCost += a.quantity * data.cost;
        });

        return [pieces, totalCost, totalPrice]
    }

    const getReport = () => {
        let sheetData = {};
        const colsSheetData = ['Costo', 'Modelo', 'Descripción', 'Catálogo', 'Inventario Total', 'Costo Total', 'Precio', 'Venta Total'];
        let maxSignatory = 0;

        const getTallasCols = (tallas) => {
            const row = [];
            tallas.map(t => {
                const cols = []
                cols.push(`${t.subItem}: ${t.quantity}`);
                row.push(cols)
            })

            if (tallas.length > maxSignatory) maxSignatory = tallas.length

            return row.flat()
        }

        stock.map(item => {
            const data = getStock(item);
            if (data[0] > 0){
                const sheetname = item.style.replace('.','_').replace(' ','');
                
                if (!(sheetname in sheetData)){
                    sheetData[sheetname] = [];
                }

                sheetData[sheetname].push([
                    item.cost ? item.cost : 0,
                    item.internalCode ? item.internalCode : item.name,
                    item.description ? item.description : item.name,
                    item.catalogo ? item.catalogo : "Sin catálogo",
                    data[0],
                    data[1],
                    item.price ? item.price : 0,
                    data[2]
                ].concat(getTallasCols(item.stock)))
            }
        });

        const options = { '!cols': [{ wch: 13 }, { wch: 15 }, { wch: 14 }, { wch: 15 }, { wch: 6 },  { wch: 6 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }] };

        for (let i = 1; i <= maxSignatory; i++) {
            colsSheetData.push('Variante ' + i);
        }
        
        const dataset = {}
        Object.keys(sheetData).map(st =>{
            dataset[st] = [];
            dataset[st].push(colsSheetData)
            sheetData[st].map(m => {
                dataset[st].push(m)
            })
        })

        const xlsSheets = [];
        Object.keys(sheetData).map(st =>{
            xlsSheets.push({ name: st, data: dataset[st]})
        })

        const buildData = xlsx.build(xlsSheets);
        saveReportFile(`Reporte - ${moment().utc().format('YYYYMMDD')}`, buildData);
    }

    return (
        <div className="ribbon">
            <h4>Generar reporte de inventarios</h4>
            <div className="left">
                <button className="btn light-goldeneye" onClick={() => getReport()}>Descargar</button>
            </div>
        </div>
    );
};

export default ReporteInventario;