import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Paginator from 'react-hooks-paginator';
import Select from 'react-select';
import { httpDelete, httpGet, httpGetImg, httpGetOne, httpPost, httpPostw, httpPut } from '../fetch';
import AttributesBox from './components/AttributesBox';
import AttributesList from './components/AttributesList';
import StockCatalog from './StockCatalog';


export default function Stock() {

    let M;
    const [item, setItem] = useState(
        {
            _id: '', picUrl: '', internalCode: '', dealerCode: '', name: '', description: '', cost: '', price: '', promotion: '',
            dealer: '', active: true, style: { type: { value: 0, label: '... Seleccionar Estilo' } }, status: 'activo', stock: [], catalog: { value: 0, label: 'Seleccionar estilo' }
        });
    const [variant, setVariant] = useState({ quantity: 0, subItem: '' });
    const [stockItem, setStockItem] = useState([]);
    const [file, setFile] = useState({ file: null });

    const [items, setItems] = useState([]);
    const [itemsLista, setItemsLista] = useState({ value: 0, label: "... Buscar" });
    const busqueda = { value: 0, label: '... Buscar Artículo' };
    const [edit, setEdit] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [internalCodes, setInternalCodes] = useState([]);
    const [error, setError] = useState({ active: false, msg: '' });
    const [count, setCount] = useState({ stock: 0, items: 0 });

    const [state, setState] = useState({});

    const [inventoryTypes, setInventoryTypes] = useState({ value: 0, label: "... Estilo" });
    const [catalogo, setCatalogo] = useState({ value: 0, label: '... Seleccionar Catálogo ' });

    const [stateAdv, setStateAdv] = useState({});
    const [exp, setExp] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
    const [userType, setUserType] = useState('');
    const catalogOptions = [{ value: 1, label: "Dama" }, { value: 2, label: "Caballero" }, { value: 3, label: "Joyería" }, { value: 4, label: "Perfumería" }, { value: 5, label: "Zapatería" }, { value: 6, label: "Bolsos" }, { value: 7, label: "Cinturones" }, { value: 8, label: "Lentes" }];
    const [seccion, setSeccion] = useState({ value: 0, label: '... Categoría' });

    const pageLimit = 10;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [promos, setPromos] = useState([]);

    const joyeriaTags = {
        "Piedra": ["Natural", "Sintética", "Diamante"],
        "Material": ["Oro", "Oro Rosa", "Oro Blanco", "Plata", "Platino", "Acero Inoxidable"],
        "Talla": ["Unitalla"],
        "Tipo": ["Anillo", "Esclava", "Collar", "Cadena", "Dije", "Aretes", "Arracadas"]
    }

    const tallasDama = ["XS",
        "S",
        "M",
        "L",
        "1XL",
        "2XL",
        "3XL",
        "4XL",
        "5XL",
        "0",
        "1",
        "3",
        "5",
        "7",
        "9",
        "11",
        "13",
        "14",
        "15",
        "16",
        "18",
        "20",
        "XL",
        "XXL",
        "XXXL",
        "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "9", "10", "11", "12.5",];

    const tallasCaballero = ["XS",
        "S",
        "M",
        "L",
        "XL",
        "2XL",
        "3XL",
        "4XL",
        "5XL",
        "24", "26", "28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48",
        "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "9", "10", "11", "12.5",];

    const defaultTags = {
        // "Cuello": [
        //     "Redondo",
        //     "V",
        //     "Normal",
        //     "C/capucha",
        //     "S/capucha",
        //     "simple",
        //     "C/ Solapa",
        //     "Botones"
        // ],
        // "Manga": [
        //     "Manga Larga",
        //     "Manga 3/4",
        //     "Manga Corta",
        //     "Sin Mangas"
        // ],
        // "Variante": [
        //     "Impresa",
        //     "Lisa",
        //     "Patrón",
        //     "Ranglan",
        //     "Bordada", "Peep Toes",
        //     "Botas Negras",
        //     "Ballerinas",
        //     "Sandalias con Plataforma",
        //     "Tenis de Lona",
        //     "Zapatilla Negra Simple",
        //     "Sandalias Metalicas",
        //     "Botines",
        //     "Sandalias Planas",
        //     "Tacones Sexies",
        //     "Colores Lisos", "con Patron", "Vaquero",
        //     "c/ Gorro", "Deportivo", "Zipper",
        //     "De etiqueta o ceremonia", "Bombín", "Chistera", "Homburg", "Regionales o folclóricos", "Cordobés", "Mejicano",
        //     "Fedora", "Indiana", "Trilby", "Pork pie", "Canotier", "Cowboy", "Australiano",
        //     "Gavroche", "Vasca", "Pico de pato Duckbill", "Inglesa", "Hatteras", "Irlandesa", "Deportivo", "Urbano", "Elegante",
        //     "Militar", "Béisbol", "v/ Plana", "v/ Curva"

        // ],
        // "Corte": [
        //     "Slim Fit",
        //     "Regular Fit",
        //     "Dry Fit",
        //     "Piel",
        //     "Sintetico",
        //     "Tela"
        // ],
        "Talla": [
            "Unitalla",
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL",
            "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "9", "10", "11", "12.5"
        ],
        "Calzado": ["Unitalla", "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5"],
        // "BolsoCierre": ["Botón", "Cremallera"],
        // "BolsoVariante": ["Con Correa", "Sin Correa", "Monedero"],
        // "BolsoTalla": ["S", "M", "L", "XL"],
        "Unidad": ['Disponibles'],
        "Piezas": ['Disponibles']
    }
    const [allSearchTags, setAllSearchTags] = useState({
        // "Cuello": [
        //     "Redondo",
        //     "V",
        //     "Normal",
        //     "C/capucha",
        //     "S/capucha",
        //     "simple",
        //     "C/ Solapa",
        //     "Botones"
        // ],
        // "Manga": [
        //     "Manga Larga",
        //     "Manga 3/4",
        //     "Manga Corta",
        //     "Sin Mangas"
        // ],
        // "Variante": [
        //     "Impresa",
        //     "Lisa",
        //     "Patrón",
        //     "Ranglan",
        //     "Bordada", "Peep Toes",
        //     "Botas Negras",
        //     "Ballerinas",
        //     "Sandalias con Plataforma",
        //     "Tenis de Lona",
        //     "Zapatilla Negra Simple",
        //     "Sandalias Metalicas",
        //     "Botines",
        //     "Sandalias Planas",
        //     "Tacones Sexies",
        //     "Colores Lisos", "con Patron", "Vaquero",
        //     "c/ Gorro", "Deportivo", "Zipper",
        //     "De etiqueta o ceremonia", "Bombín", "Chistera", "Homburg", "Regionales o folclóricos", "Cordobés", "Mejicano",
        //     "Fedora", "Indiana", "Trilby", "Pork pie", "Canotier", "Cowboy", "Australiano",
        //     "Gavroche", "Vasca", "Pico de pato Duckbill", "Inglesa", "Hatteras", "Irlandesa", "Deportivo", "Urbano", "Elegante",
        //     "Militar", "Béisbol", "v/ Plana", "v/ Curva"

        // ],
        // "Corte": [
        //     "Slim Fit",
        //     "Regular Fit",
        //     "Dry Fit",
        //     "Piel",
        //     "Sintetico",
        //     "Tela"
        // ],
        "Talla": [
            "Unitalla",
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL",
            "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "9", "10", "11", "12.5"
        ],
        "Unidad": ['Disponibles'],
        "Piezas": ['Disponibles']
    });

    useEffect(() => {
        seccion.value === 0 ? fetchItems() : fetchItems(seccion.label)

        if (seccion.value === 1) {
            fetchInventoryTypes('Dama');
            const a = defaultTags;
            a.Talla = [...tallasDama];
            // delete a.Unidad;
            // delete a.Calzado;
            // delete a.BolsoCierre;
            // delete a.BolsoVariante;
            // delete a.BolsoTalla;
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 2) {
            fetchInventoryTypes('Caballero');
            const a = defaultTags;
            a.Talla = [...tallasCaballero];
            delete a.Unidad;
            delete a.Calzado;
            delete a.BolsoCierre;
            delete a.BolsoVariante;
            delete a.BolsoTalla;
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 3) {
            fetchInventoryTypes('Joyeria')
            const a = { Piezas: ["Disponibles"] };
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 4) {
            fetchInventoryTypes('Perfumeria');
            const a = { Piezas: ["Disponibles"] };
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 5) {
            fetchInventoryTypes('Zapateria');
            const a = { Talla: defaultTags.Calzado };
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 6) {
            fetchInventoryTypes('Bolsos');
            const a = { Piezas: ["Disponibles"] };
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 7) {
            fetchInventoryTypes('Cinturones');
            const a = { Piezas: ["Disponibles"] };
            setAllSearchTags(a);
            return;
        }
        else if (seccion.value === 6) {
            fetchInventoryTypes('Lentes');
            const a = { Piezas: ["Disponibles"] };
            setAllSearchTags(a);
            return;
        }

    }, [seccion])

    useEffect(() => {
        setUserType(localStorage.getItem('userType'));
        if (userType !== '') {
            if (userType !== 'Admin' && userType !== 'Publicidad' && userType !== 'Cobranza' && userType !== 'Almacen') {
                window.alert("Algo salió mal, no deberías estar aquí...");
                window.location.href = '/clientes'
            }
        }
        var modal1 = document.querySelectorAll('#modal1');
        var modal2 = document.querySelectorAll('#modal2');
        var tooltips = document.querySelectorAll('.tooltipped');

        M = window.M; //Cargar Materialize desde CDN
        M.Tooltip.init(tooltips, []);
        M.Modal.init(modal1, {
            onCloseEnd: () => {
                setEdit(false);
                setVariant({});
                setStockItem([]);
                setCatalogo({ value: 0, label: '... Seleccionar Catálogo ' });
                setItem({
                    _id: '', picUrl: '', internalCode: '', dealerCode: '', name: '', description: '', cost: '', price: '', promotion: '',
                    dealer: '', active: true, style: { type: { value: 0, label: '... Seleccionar Estilo' } }, status: 'activo', stock: [], catalog: { value: 0, label: 'Seleccionar estilo' }
                })
            }
        });
        M.Modal.init(modal2, {
            onCloseEnd: () => {
                setEdit(false);
                setVariant({});
                setStockItem([]);
                setCatalogo({ value: 0, label: '... Seleccionar Catálogo ' });
                setItem({
                    _id: '', picUrl: '', internalCode: '', dealerCode: '', name: '', description: '', cost: '', price: '', promotion: '',
                    dealer: '', active: true, style: { type: { value: 0, label: '... Seleccionar Estilo' } }, status: 'activo', stock: [], catalog: { value: 0, label: 'Seleccionar estilo' }
                })
            }
        });
        // fetchItems();
        fetchInventoryTypes('undefined');
        fetchPromos();
    }, []) //<--- en este arreglo se debe hacer referencia a los objetos que se deben refrescar al cambiar el estado de la app.

    async function fetchPromos() {
        const res = await httpGet('/api/promotions');
        setPromos(res.data);
        if (res.data) {
            M = window.M; //Cargar Materialize desde CDN
            let select = document.querySelectorAll('select');
            M.FormSelect.init(select, []);
        }
    }

    useEffect(() => {
        setCurrentData(items.slice(offset, offset + pageLimit));
    }, [offset, items]);

    async function fetchInventoryTypes(type) {
        let result = {}
        if (type === 'Dama')
            result = await httpGet('/api/inventoryTypes/dama');
        else if (type === 'Caballero')
            result = await httpGet('/api/inventoryTypes/caballero');
        else if (type === 'Joyería')
            result = await httpGet('/api/inventoryTypes/joyeria');
        else if (type === 'Unisex')
            result = await httpGet('/api/inventoryTypes/unisex');
        else if (type === 'Perfumería')
            result = await httpGet('/api/inventoryTypes/perfumeria');
        else if (type === 'Zapatería')
            result = await httpGet('/api/inventoryTypes/zapateria');
        else if (type === 'Bolsos')
            result = await httpGet('/api/inventoryTypes/bolsos');
        else if (type === 'Cinturones')
            result = await httpGet('/api/inventoryTypes/cinturones');
        else if (type === 'Lentes')
            result = await httpGet('/api/inventoryTypes/lentes');
        else
            result = await httpGet('/api/inventoryTypes');
        const b = [];

        if (result.data) {
            result.data.map(m => {
                b.push({ value: m._id, label: m.type });
            });
            setInventoryTypes(b);
        }
    }

    async function onFormSubmit(e) {
        setUploading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage', file.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const data = await httpPostw("/api/uploads/upload", formData, config)
            .then((response) => {
                const filename = response.data.data.filename;
                setItem({ ...item, picUrl: httpGetImg(filename), thumbUrl: httpGetImg(`thumbs/${filename}`) });
                alert("Archivo cargado correctamente, " + filename);
                setUploading(false);
            }).catch((error) => {
                alert("Ocurrió un problema al subir imágen.");
                setUploading(false);
            });
    }
    function onFileChange(e) {
        setFile({ file: e.target.files[0] });
    }

    function handleChange(e) {
        return function (e) {
            const { name, value } = e.target
            setItem({ ...item, [name]: value })
        }
    }
    async function handleInternalCodeChange(e) {
        const code = e.target.value;
        // const icode = await httpGet('/api/items/byCode'+code);
        if (internalCodes.includes(code) && code !== '') {
            setError({ active: true, msg: ' Código repetido' });
        } else {
            setError({ active: false });
        }
        setItem({ ...item, internalCode: code });
    }

    function handleStockChange(e) {
        return function (e) {
            const { name, value } = e.target
            setVariant({ ...variant, [name]: value })
        }
    }

    const fetchItems = async (type) => {
        if (loading) return;

        setLoading(true);

        const result = await httpGet(`/api/items/?type=${type}`);
        setItems(result.data);
        window.M.updateTextFields();
        let counter = 0;
        let stockCounter = 0;
        const b = [];
        const internalCodes = [];
        if (result.data) {
            result.data.map(m => {
                counter++;
                b.push({ value: m.id, label: m.internalCode ? m.internalCode + ' - ' + m.name : m.name });
                internalCodes.push(m.internalCode);
                if (m.stock) {
                    m.stock.map(s => {
                        stockCounter += s.quantity;
                    });
                }
            });
            setItemsLista(b);
            setInternalCodes(internalCodes);
            setCount({ items: counter, stock: stockCounter });
            setLoading(false);
        }
    }

    function operacion() {
        return new Promise((reject, resolve) => {
            let data = []
            items.map(i => {
                if (i.picUrl && i.picUrl.length > 0)
                    data.push(i.picUrl.split('/')[i.picUrl.split('/').length - 1])
            })
            return data;
        })
    }

    const functionWithPromise = i => { //a function that returns a promise
        return Promise.resolve(i.picUrl.split('/')[i.picUrl.split('/').length - 1])
    }

    const anAsyncFunction = async item => {
        return functionWithPromise(item)
    }

    const getData = async () => {
        return Promise.all(items.map(item => item.picUrl ? anAsyncFunction(item) : ''))
    }

    const downloadAll = async (e) => {
        e.preventDefault();

        const data = { files: await getData() }

        const a = await httpPost('/api/download/', data, localStorage.getItem('user'));
        const x = 'https://api.grcollection.com.mx/storage/' + a.data.file;
        return window.location.href = x;
    }

    const addTask = async (e) => {
        e.preventDefault();

        // los searchTags no se capturan, a menos de que no existan ya campurados, para evtar que se borren
        if (!item.searchTags || item.searchTags === undefined || Object.keys(item.searchTags).length === 0)
            item.searchTags = state;

        if (edit) {
            const result = await httpPut('/api/items/' + item._id, item, localStorage.getItem('user'));
            setItem({
                _id: '', picUrl: '', internalCode: '', dealerCode: '', name: '', description: '', cost: '', price: '', promotion: '',
                dealer: '', active: true, style: '', status: 'activo', stock: []
            });
            setState({});
            setVariant({ subItem: '', quantity: 0 });
            setEdit(false);
        } else {
            if (error.active) {
                alert(error.msg + ', el artículo no se guardará.');
            } else {
                const result = await httpPost('/api/items', item, localStorage.getItem('user'));
                // console.log(result.data)
                setItem({
                    _id: '', picUrl: '', internalCode: '', dealerCode: '', name: '', description: '', cost: '', price: '', promotion: '',
                    dealer: '', active: true, style: 'ropa dama', status: 'activo', stock: []
                });
                setVariant({ subItem: '', quantity: 0 });
            }
        }
        fetchItems();
        //Cerrar Modal
        const M = window.M;
        let elem = document.querySelector("#modal1");
        let instance = M.Modal.getInstance(elem);
        instance.close();
    }

    const deleteTask = async (item) => {
        // console.log(item._id)
        // e.preventDefault();
        const result = await httpDelete('/api/items/delete', item._id, localStorage.getItem('user'));
        // console.log(result.data)
        // setItem({_id: '', picUrl:'', internalCode:'',dealerCode:'', name:'', description:'', cost:'', price:'',
        // dealer:'', active:true, style:'ropa', status:'activo'});
        fetchItems();
    }

    const editItemStock = async (subItem) => {
        const getf = item.stock.find(e => e.subItem === subItem);
        // console.log(getf)
        // console.log(item.stock.indexOf(getf))
        setVariant(getf)
        //setVariant(stockItem[idx]);
        window.M.updateTextFields();
    }

    async function handleBusquedaChange(e) {
        // setBusqueda({value:e.value, label: e.label});
        const result = await httpGetOne('/api/items', e.value);
        // console.log(result.data);
        setItems([result.data]);
    }

    async function handleCatalogoChange(e) {
        setItem({ ...item, catalogo: e.label })
        // console.log(e.value)
        // console.log('value')
        setCatalogo({ value: e.value, label: e.label })
        //
        fetchInventoryTypes(e.label);
    }

    async function handleEstiloChange(e) {
        // setBusqueda({value:e.value, label: e.label});
        const result = await httpGetOne('/api/inventoryTypes', e.value);
        setItem({ ...item, style: result.data.type, inventoryType: result.data, searchTags: undefined });
        // console.log(item);
    }

    function clearSearchTags() {
        setItem({ ...item, searchTags: undefined });
    }

    const deleteItemStock = async (subItem) => {
        let array = item.stock.slice();
        // console.log(array)
        const getf = array.find(e => e.subItem === subItem);
        var index = array.indexOf(getf);
        if (index > -1) {
            array.splice(index, 1);
        }
        //setStockItem(array);
        setItem({ ...item, stock: array })
    }

    const editTask = async (e) => {
        const result = await httpGetOne('/api/items/', e);
        const { _id, picUrl, internalCode, dealerCode, name, description, price, cost, catalogo,
            dealer, active, style, status, stock, inventoryType, searchTags, promotion: prom } = result.data;
        setItem({
            _id, picUrl, internalCode, dealerCode, name, description, price, cost, searchTags, catalogo, promotion: prom ? prom._id : undefined,
            dealer, active, style: { type: { id: 0, label: inventoryType ? inventoryType.type : 'Seleccionar Estilo' } }, status, stock, inventoryType
        });

        setCatalogo({ value: 0, label: catalogo });

        //setStockItem(stock);
        setEdit(true);
        window.M.updateTextFields();

        M = window.M; //Cargar Materialize desde CDN
        let select = document.querySelectorAll('select');
        M.FormSelect.init(select, []);
    }

    const addStock = () => { //TODO: la tabla no se afecta despues de cambiar el estado.
        // item.stock.push(stock);
        //const a = stockItem.slice();
        //a.push(variant)
        // console.log(JSON.stringify(item))

        // look for existent variant
        const getf = item.stock.find(e => e.subItem === variant.subItem);
        // console.log(getf)
        if (item.stock.indexOf(getf) === -1) {
            // setItem({...item, stock: [...item.stock, variant]});
        } else {
            let array = item.stock.slice();;
            const getf = array.find(e => e.subItem === variant.subItem);
            var index = array.indexOf(getf);
            if (index > -1) {
                array.splice(index, 1);
            }
            array = [...array, variant]
            setItem({ ...item, stock: array });
        }
        // console.log(item.stock.indexOf(getf))
        setVariant({ subItem: '', quantity: 0 });

        //setStockItem([...stockItem, variant]);
    }

    function beautify(name) {
        switch (name) {
            case 'perfumeria':
                return 'Perfumería'
                break;
            case 'ropa dama':
                return 'Ropa Dama'
                break;
            case 'joyeria':
                return 'Joyería'
                break;
            case 'ropa caballero':
                return 'Ropa Caballero'
                break;
            case 'accesorios dama':
                return 'Accesorios Dama'
                break;
            case 'accesorios caballero':
                return 'Accesorios Caballero'
                break;
            default:
                return name;
        }
    }

    function advGo(e) {
        const match = [];

        if (Object.keys(stateAdv).length > 0) {
            items.map(i => {
                // Items que pueden coincidir por sus searchTags
                let preMatch = [];
                for (const valor in stateAdv) {
                    for (const va in i.searchTags) {
                        if (stateAdv[valor].length > 0) {
                            if (valor === va) {
                                stateAdv[valor].map(x => {
                                    //x es un valor en el tag val
                                    const y = i.searchTags[va].find(element => element === x ? true : false)
                                    if (y) {
                                        preMatch.push(i)
                                    }
                                })
                            }
                        }
                    }
                }
                var current = {};
                var cnt = 0;
                preMatch.sort();
                for (var i = 0; i < preMatch.length; i++) {
                    if (preMatch[i]._id != current._id) {
                        if (cnt > 0) {
                            // console.log(current._id + ' comes --> ' + cnt + ' times<br>');
                            if (cnt === Object.keys(stateAdv).length) {
                                match.push(current)
                            }
                        }
                        current = preMatch[i];
                        cnt = 1;
                    } else {
                        cnt++;
                    }
                }
                if (cnt > 0) {
                    // console.log(current._id + ' comes --> ' + cnt + ' times');
                    if (cnt === Object.keys(stateAdv).length) {
                        match.push(current)
                    }
                }

            });

            const withStock = [];
            match.map(i => {
                let toAdd = true;
                i.stock.map(s => {
                    let stock = false;
                    if (s.quantity > 0) {
                        stock = true;
                    }
                    if (stock && toAdd) {
                        withStock.push(i);
                        toAdd = false
                    }
                })
            })

            // Considerar las tallas seleccionadas 
            const filteredStock = [];
            if (stateAdv.Talla && stateAdv.Talla.length > 0) {
                stateAdv.Talla.map(s => {
                    withStock.map(i => {
                        let added = false;
                        i.stock.map(o => {
                            if (o.subItem.toUpperCase() === s.toUpperCase()) {
                                if (o.quantity > 0) {
                                    if (!added) {
                                        filteredStock.push(i);
                                        added = true;
                                    }
                                }
                            }
                        })
                    })
                })
                setItems(filteredStock)

            } else {
                setItems(withStock)
            }
        }
    }

    function limpiar() {
        setSeccion({ value: 0, label: "... Categoría" });
        setStateAdv({});
        fetchItems();
        setExp([false, false, false, false, false, false, false, false, false, false, false]);
    }

    //Regresa la suma de las cantidades de las existencias
    const sum = (stock => {
        let res = 0;
        if (stock) {
            stock.forEach(v => {
                res = res + v.quantity;
            });
        }
        return res;
    });

    function calcularPrecio(precio, promoId) {
        if (!promoId || promoId === '') {
            return precio
        } else {
            const promo = promos.find(p => p._id === promoId)
            if (!promo) return precio

            let result = 0;
            if (promo.discountType === 'amount') {
                result = promo.discount;
            } else {
                result = precio - (precio * promo.discount / 100);
            }
            return result
        }
    }

    function calcularPuntos(precio, promoId) {
        if (!promoId || promoId === '') {
            return 0
        } else {
            const promo = promos.find(p => p._id === promoId)
            if (!promo) return 0

            let result = 0;
            if (promo.pointsType === 'amount') {
                result = promo.points;
            } else {
                result = Math.round(precio * promo.points / 100);
            }
            return result
        }
    }

    return (
        <div>
            <div className="container">
                <h4>Inventario</h4>
                <div className="row">
                    <div className="col m6 s11">
                        {userType && (userType === 'Admin' || userType === 'Almacen') ?
                            <button data-target="modal1" className="btn modal-trigger light-goldeneye"
                                data-position="bottom">Agregar +</button>
                            : ''}
                    </div>
                    <br className="movil-only" />
                    <br className="movil-only" />
                    <br className="movil-only" />
                    <div className="col m6 s11">
                        <Select id="search" options={itemsLista} name="search"
                            value={busqueda} onChange={handleBusquedaChange} className="selecter lvl100 s12" />
                    </div>
                </div>

                <div className="center">
                    <h4 style={{ color: 'black', marginBottom: '0', marginTop: '0' }}>Artículos Activos: <span className="txtGreen">{count.items}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        Stock General: <span className="txtGreen">{count.stock}</span> </h4>
                </div>

                {/* Buscador avanzado */}
                <div>
                    <div>
                        <div hidden={!loading}> Cargando ...</div>
                        <ul>
                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 1, label: 'Dama' }) : setSeccion({ value: 0 }) }}>Dama</a></li>

                            <StockCatalog val={1} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 2, label: 'Caballero' }) : setSeccion({ value: 0 }) }}>Caballero</a></li>

                            <StockCatalog val={2} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 3, label: 'Joyería' }) : setSeccion({ value: 0 }) }}>Joyería</a></li>

                            <StockCatalog val={3} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 4, label: 'Perfumería' }) : setSeccion({ value: 0 }) }}>Perfumería</a></li>

                            <StockCatalog val={4} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 5, label: 'Zapatería' }) : setSeccion({ value: 0 }) }}>Zapatería</a></li>

                            <StockCatalog val={5} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 6, label: 'Bolsos' }) : setSeccion({ value: 0 }) }}>Bolsos</a></li>

                            <StockCatalog val={6} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 7, label: 'Cinturones' }) : setSeccion({ value: 0 }) }}>Cinturones</a></li>

                            <StockCatalog val={7} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />

                            <li><a href="#" onClick={loading ? () => { } : () => { seccion.value === 0 ? setSeccion({ value: 8, label: 'Lentes' }) : setSeccion({ value: 0 }) }}>Lentes</a></li>

                            <StockCatalog val={8} seccion={seccion} exp={exp} allSearchTags={allSearchTags} stateAdv={stateAdv} setStateAdv={setStateAdv} />
                        </ul>

                        <br />

                        <button className="btn light-goldeneye" onClick={advGo}>Buscar</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="btn light-goldeneye" onClick={limpiar}>Limpiar</button>
                    </div>
                </div>
                {/* Fin Buscador avanzado */}
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Artículo</th>
                            {/* <th className="pc-only-table-cell">Descripción</th> */}
                            <th className="pc-only-table-cell">Estilo</th>
                            <th>Precio</th>
                            <th>Existencias</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map(i => (
                            <tr className="Table"
                                key={i._id}>
                                <td><a href={i.picUrl} target="_blank;"><img className="tumbnail" src={i.thumbUrl || "../img/box.png"}></img></a></td>
                                <td>{i.name}</td>
                                <td className="pc-only-table-cell">{beautify(i.style)}</td>
                                <td>${i.price ? i.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td>{sum(i.stock)}</td>
                                <td>
                                    {userType === 'Admin' || userType === 'Almacen' ?
                                        <button className="btn light-goldeneye modal-trigger"
                                            value={i._id}
                                            href="#modal1"
                                            onClick={e => { editTask(i._id) }}
                                            style={{ margin: '4px', }}>
                                            <i className="material-icons">edit</i>
                                        </button>
                                        : ''}
                                    <button className="btn light-goldeneye modal-trigger"
                                        value={i._id}
                                        href="#modal2"
                                        onClick={e => { editTask(i._id) }}
                                        style={{ margin: '4px' }}>
                                        <i className="material-icons">add_box</i>
                                    </button>
                                    {userType === 'Admin' ?
                                        <button className="btn light-goldeneye"
                                            onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar este producto?')) deleteTask(i) }}
                                            style={{ margin: '4px' }}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                        : ''}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {items && items.length > 10 ?
                    <Paginator
                        totalRecords={items.length}
                        pageLimit={pageLimit}
                        pageNeighbours={2}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageLinkClass="light-goldeneye"
                    /> : ''}

                <br />
                <button className="btn light-goldeneye" onClick={downloadAll} style={{ float: 'right' }}>Descargar Imágenes</button>
                <br />
                <br />
                <br />

            </div>
            <div id="modal1" className="modal modal2">
                {/* <form onSubmit={addTask}> */}
                <div className="modal-content">
                    <div className="modal-header">
                        {edit ? (
                            <h4>Editar Producto</h4>
                        ) : (
                            <h4>Nuevo Producto</h4>
                        )}
                        <div style={{ textAlign: 'right', position: 'absolute', top: '5px', right: '5px' }}>
                            <button className="modal-close btn-floating light-goldeneye">X</button>
                        </div>
                    </div>
                    <div className="row">
                        {/* Datos del articulo */}
                        <div className="center">
                            <div>
                                <div >
                                    <img style={{ width: '100px' }} src={item.picUrl}></img>
                                </div>
                                <form onSubmit={e => onFormSubmit(e)}>
                                    <h4>Imágen del producto</h4>
                                    <input id="myImage" className="select-file"
                                        type="file" name="myImage" onChange={onFileChange}
                                        accept=".png, .jpg, .jpeg" />
                                    <label className="light-goldeneye btn" htmlFor="myImage">Seleccionar Archivo</label>
                                    <br className="movil-only" />
                                    <br className="movil-only" />
                                    <button className="btn light-goldeneye" type="submit" disabled={uploading}>Subir Archivo</button>
                                </form>
                            </div>
                            {uploading ? (<div>
                                <div className="progress">
                                    <div className="indeterminate"></div>
                                </div>
                                <div className="center">Cargando, por favor espere...</div>
                            </div>) : ''}
                            <br /><br />
                        </div>

                        <form id="addStockForm" onSubmit={addTask}>
                            <div className='col s12 m12'>
                                <div className="input-field col s12">
                                    <input id="name" type="text" name="name" value={item.name}
                                        onChange={handleChange("name")} required></input>
                                    <label className="Active" htmlFor="name">Nombre del Artículo<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                </div>
                                {/* <div className="input-field col s12">
                                            <input id="description" type='text' name="description" value={item.description} 
                                            onChange={handleChange("description")} required></input>
                                            <label htmlFor="description">Descripción<span style={{color:'red'}}>&nbsp;*</span></label>
                                        </div> */}
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">attach_money</i>
                                    <input id="cost" type='number' name="cost" value={item.cost} step="0.01"
                                        onChange={handleChange("cost")}></input>
                                    <label htmlFor="cost">Costo</label>
                                </div>
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">attach_money</i>
                                    <input id="price" type='number' name="price" value={item.price} step="0.01"
                                        onChange={handleChange("price")} required></input>
                                    <label htmlFor="price">Precio de Venta<span style={{ color: 'red' }}>&nbsp;*</span></label>
                                </div>

                                <div className="input-field col s12">
                                    <select onChange={handleChange("promotion")} name='promotion' value={item.promotion}>
                                        <option value="">Sin promoción</option>
                                        {
                                            promos.map(p => {
                                                return (<option value={p._id}>{p.name} (Vigencia al {moment(p.endDate).utc().format("DD/MM/YY")})</option>)
                                            })
                                        }
                                    </select>
                                    <label>Promoción Aplicable</label>
                                </div>

                                <div hidden={!item.promotion}>
                                    <div className="input-field col s12">
                                        <i className="material-icons prefix">attach_money</i>
                                        <input type='number' value={calcularPrecio(item.price, item.promotion)} step="0.01" disabled></input>
                                        <label htmlFor="price">Precio con descuento:<span style={{ color: 'red' }}></span></label>
                                    </div>
                                    <div className="input-field col s12">
                                        <input type='number' value={calcularPuntos(item.price, item.promotion)} step="0.01" disabled></input>
                                        <label htmlFor="price">Puntos a monedero:<span style={{ color: 'red' }}></span></label>
                                    </div>
                                </div>

                                <div className="input-field col s12">
                                    <input id="dealer" type='text' name="dealer" value={item.dealer}
                                        onChange={handleChange("dealer")}></input>
                                    <label htmlFor="dealer">Proveedor</label>
                                </div>

                                <div className="input-field col s12" style={{ zIndex: 999 }}>
                                    <Select id="catalogo" options={catalogOptions} name="catalogos"
                                        value={catalogo} onChange={handleCatalogoChange} className="selecter lvl100 s12" />
                                </div>


                                <div className="input-field col s12">
                                    {/* <select id="Style" name="style" value={item.style} onChange={handleChangex()}>
                                                {inventoryTypes?inventoryTypes.map((e, i) =>{
                                                    return(<option key={i} value={e._id}>{e.type}</option>);
                                                }):''}
                                            </select> */}
                                    <Select id="estilo" isDisabled={catalogo.value === 0} options={inventoryTypes} name="estilos"
                                        value={item.style.type} onChange={handleEstiloChange} className="selecter lvl100 s12" />
                                    {/* <label htmlFor="style">Estilo<span style={{color:'red'}}>&nbsp;*</span></label> */}
                                </div>


                                <div className="input-fields col s12">
                                    <label htmlFor="birth">Atributos<span style={{ color: 'red' }}>&nbsp;*</span></label>

                                    {
                                        item.inventoryType ?
                                            item.searchTags ?
                                                <div>
                                                    {
                                                        Object.keys(item.searchTags).map(a => (
                                                            <div key={a}>
                                                                <AttributesList state={state} key={a} name={a} list={item.searchTags[a]}></AttributesList>
                                                            </div>
                                                        ))
                                                    }
                                                    <button className="btn light-goldeneye" onClick={clearSearchTags} style={{ float: 'right' }}>Borrar Atributos</button>
                                                </div>
                                                :

                                                Object.keys(item.inventoryType.attributes).map(a => (
                                                    <div key={a}>
                                                        <AttributesBox state={state} key={a} name={a} list={item.inventoryType.attributes[a]} onChange={setState}></AttributesBox>
                                                    </div>
                                                ))

                                            : ''
                                    }
                                </div>



                                <div className="input-field col s12">
                                    <input id="internalCode" type='text' name="internalCode" value={item.internalCode}
                                        onChange={handleInternalCodeChange}></input>
                                    <label htmlFor="internalCode">Código Interno<span style={{ color: 'red' }}>{error.active ? error.msg : ''}</span></label>
                                </div>
                                <div className="input-field col s12">
                                    <input id="externalCode" type='text' name="dealerCode" value={item.dealerCode}
                                        onChange={handleChange("dealerCode")}></input>
                                    <label htmlFor="externalCode">Código Externo</label>
                                </div>
                            </div>
                            <a className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</a>
                            {edit ? (
                                <button type="submit" className="btn light-goldeneye" style={{ float: 'right' }}>Guardar</button>
                            ) : (
                                <button type="submit" className="btn light-goldeneye" style={{ float: 'right' }}>Agregar</button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <div id="modal2" className="modal modal-fixed-footer" style={{ width: '90%' }}>
                <div className="modal-content">
                    {/* Existencias */}
                    <div className="col s12">
                        <h4>Existencias</h4>
                        {userType === 'Admin' || userType === 'Almacen'?
                            <React.Fragment>
                                <div className="col s6">
                                    <input type='text' name="subItem" value={variant.subItem}
                                        onChange={handleStockChange("subItem")} placeholder="Variante (Talla, Número o Cantidad)"></input>
                                </div>
                                <div className="col s6">
                                    <input type='number' name="quantity" value={variant.quantity}
                                        onChange={handleStockChange("quantity")} placeholder="Cantidad"></input>
                                </div>
                                <button className="btn light-goldeneye" onClick={addStock}>Añadir</button>
                            </React.Fragment>
                            : ''}
                        <div className="row">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Variante</th>
                                        <th>Cantidad</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.stock.map((i, idx) => (
                                        <tr className="Table" key={idx}>
                                            <td>{i.subItem}</td>
                                            <td>{i.quantity}</td>
                                            <td>
                                                {userType === 'Admin' || userType === 'Almacen'?
                                                    <button className="btn light-goldeneye"
                                                        value={i.idx}
                                                        onClick={e => { editItemStock(i.subItem) }}
                                                        style={{ margin: '4px', }}>
                                                        <i className="material-icons">edit</i>
                                                    </button>
                                                    : ''}
                                            </td>
                                            <td>
                                                {userType === 'Admin' ?
                                                    <button className="btn light-goldeneye"
                                                        value={i.idx}
                                                        onClick={e => { if (window.confirm('¿Estás seguro que deseas eliminar estas existencias?')) deleteItemStock(i.subItem) }}
                                                        style={{ margin: '4px', }}>
                                                        <i className="material-icons">delete</i>
                                                    </button>
                                                    : ''}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="waves-effect btn light-goldeneye modal-close" style={{ float: 'left' }}>Cerrar</button>
                    {/* <input type="submit" onClick={addTask} className="modal-close waves-effect waves-green btn" 
                            value="Guardar" /> */}
                    {userType === 'Admin' || userType === 'Almacen'?
                        <button className="btn light-goldeneye modal-close" onClick={addTask} style={{ float: 'right' }}>Guardar</button>
                        : ''}
                </div>
            </div>
        </div>
    )
}